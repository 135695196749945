/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react'
import PropTypes from 'prop-types'

// Assets
import ElipisisIcon from 'assets/images/elipisis'
import ArrowLeftIcon from 'assets/images/arrowLeft'

// Styled Elements
import {
  HeaderWrapper,
  HeaderTitle,
  HeaderInner,
  HeaderRightNav,
  HeaderRightNavList,
  HeaderRightNavListItem,
  IconWrapper,
  SubHeaderWrapper,
  SubHeaderContent,
} from './Header.elements'

// Views
import { ContainerFluid, Button } from 'views/components'
import { useHistory } from 'react-router'

function Header(props) {
  // Destructure
  const { subHeader, pageTitle, link } = props

  // Hooks
  const history = useHistory()

  // Functions

  return (
    <HeaderWrapper>
      <ContainerFluid>
        <HeaderInner>
          <HeaderTitle>
            {link ? (
              <Button onClick={() => history.push(link)}>
                <ArrowLeftIcon />
                {pageTitle || ''}
              </Button>
            ) : (
              pageTitle || ''
            )}
          </HeaderTitle>
          <HeaderRightNav>
            <HeaderRightNavList>
              <HeaderRightNavListItem>
                <IconWrapper>
                  <ElipisisIcon />
                </IconWrapper>
              </HeaderRightNavListItem>

              <HeaderRightNavListItem>
                <IconWrapper>
                  <ElipisisIcon />
                </IconWrapper>
              </HeaderRightNavListItem>
            </HeaderRightNavList>
          </HeaderRightNav>
        </HeaderInner>
      </ContainerFluid>
      {subHeader && (
        <SubHeaderWrapper>
          <ContainerFluid>
            <SubHeaderContent>{subHeader}</SubHeaderContent>
          </ContainerFluid>
        </SubHeaderWrapper>
      )}
    </HeaderWrapper>
  )
}

// Default Props
Header.defaultProps = {
  subHeader: '',
  ui: {},
  actions: {},
  pageTitle: '',
}

// Proptypes Validation
Header.propTypes = {
  subHeader: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  ui: PropTypes.shape({
    showHeaderLoader: PropTypes.bool,
    isProfileUpdated: PropTypes.bool,
    isNotifUpdated: PropTypes.bool,
  }),
  actions: PropTypes.shape({
    openAppSidebarDrawer: PropTypes.func,
    setIsProfileUpdated: PropTypes.func,
    setIsNotifUpdated: PropTypes.func,
  }),
  pageTitle: PropTypes.string,
  link: PropTypes.string,
}

export default Header

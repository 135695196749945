import styled from 'styled-components'

export const SubHeaderWrapper = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.ash};
  border-bottom: 2px solid ${({ theme }) => theme.colors.lightGray};
  border-radius: 8px 8px 0 0;
  padding: 15px 10px 0 10px;
`

export const SubHeaderNavList = styled.ul`
  padding: 0;
  margin: 0;
  display: flex;
  list-style: none;
`

export const SubHeaderNavListItem = styled.li`
  padding: 0px;
  margin: 0 10px;
`

export const SubHeaderNavListLink = styled.div`
  position: relative;
  display: block;
  padding: 0 0 12px 0;
  margin: 0px;
  font-weight: 400;
  font-size: 14px;

  font-weight: ${({ active }) => active && 700};
  color: ${({ theme, active }) => (active ? theme.colors.blue : theme.darkGray)};
  border-bottom: ${({ theme, active }) => active && `3px solid ${theme.colors.blue}`};
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.blue};
    border-bottom: 3px solid ${({ theme }) => theme.colors.blue};
  }
`

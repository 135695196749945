import React from 'react'
import PropTypes from 'prop-types'

// Styled Elements
import { Wrapper, BarBackdrop, BarLine } from './RiskBar.elements'

function RiskBar(props) {
  // Destructure
  const { width } = props

  return (
    <Wrapper>
      <BarBackdrop />
      <BarLine style={{ width }} />
    </Wrapper>
  )
}

// Default Props
RiskBar.defaultProps = {
  width: '',
}

// Proptypes Validation
RiskBar.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default RiskBar

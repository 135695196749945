/* eslint-disable react/prop-types */
import React from 'react'

// Styled Elements
import {
  SubHeaderWrapper,
  SubHeaderNavList,
  SubHeaderNavListItem,
  SubHeaderNavListLink,
} from './DocumentationSubHeader.elements'

function DocumentationSubHeader(props) {
  const { headers, activeHeader } = props
  return (
    <SubHeaderWrapper>
      <SubHeaderNavList>
        {headers &&
          headers.map(
            (item) =>
              item?.name !== '422' && (
                <SubHeaderNavListItem key={item?.name}>
                  <SubHeaderNavListLink active={activeHeader === item?.name} onClick={item?.action && item.action}>
                    {item?.name}
                  </SubHeaderNavListLink>
                </SubHeaderNavListItem>
              )
          )}
      </SubHeaderNavList>
    </SubHeaderWrapper>
  )
}

export default DocumentationSubHeader

import React from 'react'

export default function ArrowLeftIcon() {
  return (
    <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21 8H1" stroke="#3597F4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8 15L1 8L8 1" stroke="#3597F4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

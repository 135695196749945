// Route Components
import { Home } from 'views/pages'

export default [
  // Home
  {
    exact: true,
    isRouteProtected: false,
    isRouteAdmin: false,
    routing: {
      component: Home,
      path: '/',
    },
  },
]

/* eslint-disable max-len */
export const TransactionData = [
  {
    endpoint: '/api/v2/analytics/chains/{chain}/transactions/{transaction}',
    method: 'post',
    tags: ['v2'],
    summary: 'Screen A Transaction',
    description:
      '\n        This API endpoint facilitates the screening of transaction hashes, enabling the identification of potential irregularities or suspicious activities within the blockchain ecosystem. Users can gain detailed insights into associated entities and flagged risk indicators, contributing to a comprehensive analysis of transaction data for enhanced transparency and security.\n        <br>\n        <br>\n        In order to call this endpoint, you need to specify the transaction hash and chain (blockchains). This API version supports the following blockchains ~bitcoin, doge, ethereum, bitcoin-cash, litecoin, stellar, ripple, XDC. \n    ',
    operationId: 'Screen_a_transaction_api_v2_analytics_chains__chain__transactions__transaction__post',
    parameters: [
      {
        name: 'chain',
        in: 'path',
        required: true,
        schema: {
          title: 'Chain',
          description: 'Blockchain network name.',
          type: 'string',
        },
        description: 'Blockchain network name.',
        examples: 'bitcoin',
      },
      {
        name: 'transaction',
        in: 'path',
        required: true,
        schema: {
          title: 'Transaction',
          description: 'Unique blockchain identifier.',
          type: 'string',
        },
        description: 'Unique blockchain identifier.',
        examples: 'f24f0e170461545b20c464507113e590a007dd10df087628d8b1842976791d5c',
      },
    ],
    responses: {
      200: {
        description: 'Successful Response',
        content: {
          'application/json': {
            schema: {
              $ref: {
                title: 'TX01ResponseCom',
                type: 'object',
                properties: {
                  status: {
                    title: 'Status',
                    type: 'string',
                    default: 'success',
                    examples: 'success',
                  },
                  data: {
                    $ref: {
                      title: 'TransactionDataInPostResponse',
                      required: ['chain'],
                      type: 'object',
                      properties: {
                        block_id: {
                          title: 'Block Id',
                          type: 'string',
                          examples: '0x2921b8e6da469703da73fa109f837088b1dc8d54810ffe4096e867751cea2a33',
                        },
                        hash: {
                          title: 'Hash',
                          type: 'string',
                          examples: '0x96da85f7613be173bf2c02d8b90a28c3052fa69b28c472e23b7bcc3db553fb83',
                        },
                        chain: {
                          title: 'Chain',
                          type: 'string',
                          examples: 'ethereum',
                        },
                        symbol: {
                          title: 'Symbol',
                          type: 'string',
                          examples: 'ETH',
                        },
                        financial_data: {
                          $ref: {
                            title: 'TransactionFinancialData',
                            type: 'object',
                            properties: {
                              in_count: {
                                title: 'In Count',
                                type: 'integer',
                                examples: 1,
                              },
                              in_native: {
                                title: 'In Native',
                                type: 'number',
                                examples: 50.00234,
                              },
                              in_usd: {
                                title: 'In Usd',
                                type: 'number',
                                examples: 5.420253656,
                              },
                              out_count: {
                                title: 'Out Count',
                                type: 'integer',
                                examples: 1,
                              },
                              out_native: {
                                title: 'Out Native',
                                type: 'number',
                                examples: 50.00234,
                              },
                              out_usd: {
                                title: 'Out Usd',
                                type: 'number',
                                examples: 5.420253656,
                              },
                              events: {
                                title: 'Events',
                                type: 'array',
                                items: {
                                  $ref: {
                                    title: 'TransactionEventSchema',
                                    required: ['type', 'amount', 'decimals'],
                                    type: 'object',
                                    properties: {
                                      type: {
                                        title: 'Type',
                                        type: 'string',
                                        examples: 'transfer',
                                      },
                                      destination: {
                                        title: 'Destination',
                                        type: 'string',
                                        examples: '0x59b87fa89986c81baBD1d91ebBF109F8855c7DeF',
                                      },
                                      source: {
                                        title: 'Source',
                                        type: 'string',
                                        examples: '0xBfEAf79E60C23883c92F06f642073f5aD85459F2',
                                      },
                                      amount: {
                                        title: 'Amount',
                                        type: 'number',
                                        examples: 50002340,
                                      },
                                      amount_usd: {
                                        title: 'Amount Usd',
                                        type: 'number',
                                        examples: 5.420253656,
                                      },
                                      decimals: {
                                        title: 'Decimals',
                                        type: 'integer',
                                        examples: 6,
                                      },
                                      denomination: {
                                        title: 'Denomination',
                                        type: 'string',
                                        examples: 'TRX',
                                      },
                                      contract: {
                                        title: 'Contract',
                                        type: 'string',
                                        examples: '0x50327c6c5a14dcade707abad2e27eb517df87ab5',
                                      },
                                      contract_source: {
                                        title: 'Contract Source',
                                        type: 'string',
                                        examples: 'ethereum/contract/0x50327c6c5a14dcade707abad2e27eb517df87ab5/erc-20',
                                      },
                                      token_type: {
                                        title: 'Token Type',
                                        type: 'string',
                                        examples: 'erc-20',
                                      },
                                    },
                                  },
                                },
                                examples: [
                                  {
                                    type: 'fee',
                                    destination: 'Null',
                                    source: '0xBfEAf79E60C23883c92F06f642073f5aD85459F2',
                                    amount: 982226191558312,
                                    amount_usd: 2.31439993064502,
                                    decimals: 18,
                                    denomination: 'ETH',
                                    contract: 'Null',
                                    contract_source: 'Null',
                                    token_type: 'Null',
                                  },
                                  {
                                    type: 'transfer',
                                    destination: '0x59b87fa89986c81baBD1d91ebBF109F8855c7DeF',
                                    source: '0xBfEAf79E60C23883c92F06f642073f5aD85459F2',
                                    amount: 50002340,
                                    amount_usd: 5.420253656,
                                    decimals: 6,
                                    denomination: 'TRX',
                                    contract: '0x50327c6c5a14dcade707abad2e27eb517df87ab5',
                                    contract_source:
                                      'ethereum/contract/0x50327c6c5a14dcade707abad2e27eb517df87ab5/erc-20',
                                    token_type: 'erc-20',
                                  },
                                ],
                              },
                            },
                          },
                        },
                        risk_data: {
                          title: 'Risk Data',
                          type: 'array',
                          items: {
                            $ref: {
                              title: 'TransactionRiskHistorySchema',
                              type: 'object',
                              properties: {
                                in_risk_ratio: {
                                  title: 'In Risk Ratio',
                                  type: 'integer',
                                  examples: 45,
                                },
                                out_risk_ratio: {
                                  title: 'Out Risk Ratio',
                                  type: 'integer',
                                  examples: 39,
                                },
                                risk_ratio: {
                                  title: 'Risk Ratio',
                                  type: 'integer',
                                  examples: 45,
                                },
                                risk_descriptors: {
                                  title: 'Risk Descriptors',
                                  type: 'string',
                                  examples: '',
                                },
                                provider_name: {
                                  title: 'Provider Name',
                                  type: 'string',
                                  examples: 'RDBIG',
                                },
                              },
                            },
                          },
                        },
                        created: {
                          title: 'Created',
                          type: 'string',
                          format: 'date-time',
                          examples: '2024-01-08T15:25:42',
                        },
                        last_modified: {
                          title: 'Last Modified',
                          type: 'string',
                          format: 'date-time',
                          examples: '2024-01-08T15:25:42',
                        },
                      },
                    },
                  },
                  message: {
                    title: 'Message',
                    type: 'string',
                    examples: 'Null',
                  },
                },
              },
            },
          },
        },
      },
      422: {
        description: 'Validation Error',
        content: {
          'application/json': {
            schema: {
              $ref: {
                title: 'HTTPValidationError',
                type: 'object',
                properties: {
                  errors: {
                    title: 'Errors',
                    type: 'array',
                    items: {
                      $ref: {
                        title: 'ValidationError',
                        required: ['loc', 'msg', 'type'],
                        type: 'object',
                        properties: {
                          loc: {
                            title: 'Location',
                            type: 'array',
                            items: {
                              anyOf: [
                                {
                                  type: 'string',
                                },
                                {
                                  type: 'integer',
                                },
                              ],
                            },
                          },
                          msg: {
                            title: 'Message',
                            type: 'string',
                          },
                          type: {
                            title: 'Error Type',
                            type: 'string',
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
  {
    endpoint: '/api/v2/analytics/chains/{chain}/transactions/{transaction}',
    method: 'get',
    tags: ['v2'],
    summary: 'Retrieve A Transaction',
    description: 'Returns a screening result for the requested transaction hash or referral ID.',
    operationId: 'Retrieve_a_transaction_api_v2_analytics_chains__chain__transactions__transaction__get',
    parameters: [
      {
        name: 'chain',
        in: 'path',
        required: true,
        schema: {
          title: 'Chain',
          description: 'Blockchain network name.',
          type: 'string',
        },
        description: 'Blockchain network name.',
        examples: 'bitcoin',
      },
      {
        name: 'transaction',
        in: 'path',
        required: true,
        schema: {
          title: 'Transaction',
          description: 'Unique blockchain identifier.',
          type: 'string',
        },
        description: 'Unique blockchain identifier.',
        examples: 'f24f0e170461545b20c464507113e590a007dd10df087628d8b1842976791d5c',
      },
    ],
    responses: {
      200: {
        description: 'Successful Response',
        content: {
          'application/json': {
            schema: {
              $ref: {
                title: 'TX05ResponseCom',
                type: 'object',
                properties: {
                  status: {
                    title: 'Status',
                    type: 'string',
                    default: 'success',
                    examples: 'success',
                  },
                  data: {
                    $ref: {
                      title: 'TransactionDataInPostResponse',
                      required: ['chain'],
                      type: 'object',
                      properties: {
                        block_id: {
                          title: 'Block Id',
                          type: 'string',
                          examples: '0x2921b8e6da469703da73fa109f837088b1dc8d54810ffe4096e867751cea2a33',
                        },
                        hash: {
                          title: 'Hash',
                          type: 'string',
                          examples: '0x96da85f7613be173bf2c02d8b90a28c3052fa69b28c472e23b7bcc3db553fb83',
                        },
                        chain: {
                          title: 'Chain',
                          type: 'string',
                          examples: 'ethereum',
                        },
                        symbol: {
                          title: 'Symbol',
                          type: 'string',
                          examples: 'ETH',
                        },
                        financial_data: {
                          $ref: {
                            title: 'TransactionFinancialData',
                            type: 'object',
                            properties: {
                              in_count: {
                                title: 'In Count',
                                type: 'integer',
                                examples: 1,
                              },
                              in_native: {
                                title: 'In Native',
                                type: 'number',
                                examples: 50.00234,
                              },
                              in_usd: {
                                title: 'In Usd',
                                type: 'number',
                                examples: 5.420253656,
                              },
                              out_count: {
                                title: 'Out Count',
                                type: 'integer',
                                examples: 1,
                              },
                              out_native: {
                                title: 'Out Native',
                                type: 'number',
                                examples: 50.00234,
                              },
                              out_usd: {
                                title: 'Out Usd',
                                type: 'number',
                                examples: 5.420253656,
                              },
                              events: {
                                title: 'Events',
                                type: 'array',
                                items: {
                                  $ref: {
                                    title: 'TransactionEventSchema',
                                    required: ['type', 'amount', 'decimals'],
                                    type: 'object',
                                    properties: {
                                      type: {
                                        title: 'Type',
                                        type: 'string',
                                        examples: 'transfer',
                                      },
                                      destination: {
                                        title: 'Destination',
                                        type: 'string',
                                        examples: '0x59b87fa89986c81baBD1d91ebBF109F8855c7DeF',
                                      },
                                      source: {
                                        title: 'Source',
                                        type: 'string',
                                        examples: '0xBfEAf79E60C23883c92F06f642073f5aD85459F2',
                                      },
                                      amount: {
                                        title: 'Amount',
                                        type: 'number',
                                        examples: 50002340,
                                      },
                                      amount_usd: {
                                        title: 'Amount Usd',
                                        type: 'number',
                                        examples: 5.420253656,
                                      },
                                      decimals: {
                                        title: 'Decimals',
                                        type: 'integer',
                                        examples: 6,
                                      },
                                      denomination: {
                                        title: 'Denomination',
                                        type: 'string',
                                        examples: 'TRX',
                                      },
                                      contract: {
                                        title: 'Contract',
                                        type: 'string',
                                        examples: '0x50327c6c5a14dcade707abad2e27eb517df87ab5',
                                      },
                                      contract_source: {
                                        title: 'Contract Source',
                                        type: 'string',
                                        examples: 'ethereum/contract/0x50327c6c5a14dcade707abad2e27eb517df87ab5/erc-20',
                                      },
                                      token_type: {
                                        title: 'Token Type',
                                        type: 'string',
                                        examples: 'erc-20',
                                      },
                                    },
                                  },
                                },
                                examples: [
                                  {
                                    type: 'fee',
                                    destination: 'Null',
                                    source: '0xBfEAf79E60C23883c92F06f642073f5aD85459F2',
                                    amount: 982226191558312,
                                    amount_usd: 2.31439993064502,
                                    decimals: 18,
                                    denomination: 'ETH',
                                    contract: 'Null',
                                    contract_source: 'Null',
                                    token_type: 'Null',
                                  },
                                  {
                                    type: 'transfer',
                                    destination: '0x59b87fa89986c81baBD1d91ebBF109F8855c7DeF',
                                    source: '0xBfEAf79E60C23883c92F06f642073f5aD85459F2',
                                    amount: 50002340,
                                    amount_usd: 5.420253656,
                                    decimals: 6,
                                    denomination: 'TRX',
                                    contract: '0x50327c6c5a14dcade707abad2e27eb517df87ab5',
                                    contract_source:
                                      'ethereum/contract/0x50327c6c5a14dcade707abad2e27eb517df87ab5/erc-20',
                                    token_type: 'erc-20',
                                  },
                                ],
                              },
                            },
                          },
                        },
                        risk_data: {
                          title: 'Risk Data',
                          type: 'array',
                          items: {
                            $ref: {
                              title: 'TransactionRiskHistorySchema',
                              type: 'object',
                              properties: {
                                in_risk_ratio: {
                                  title: 'In Risk Ratio',
                                  type: 'integer',
                                  examples: 45,
                                },
                                out_risk_ratio: {
                                  title: 'Out Risk Ratio',
                                  type: 'integer',
                                  examples: 39,
                                },
                                risk_ratio: {
                                  title: 'Risk Ratio',
                                  type: 'integer',
                                  examples: 45,
                                },
                                risk_descriptors: {
                                  title: 'Risk Descriptors',
                                  type: 'string',
                                  examples: '',
                                },
                                provider_name: {
                                  title: 'Provider Name',
                                  type: 'string',
                                  examples: 'RDBIG',
                                },
                              },
                            },
                          },
                        },
                        created: {
                          title: 'Created',
                          type: 'string',
                          format: 'date-time',
                          examples: '2024-01-08T15:25:42',
                        },
                        last_modified: {
                          title: 'Last Modified',
                          type: 'string',
                          format: 'date-time',
                          examples: '2024-01-08T15:25:42',
                        },
                      },
                    },
                  },
                  message: {
                    title: 'Message',
                    type: 'string',
                    examples: 'Null',
                  },
                },
              },
            },
          },
        },
      },
      422: {
        description: 'Validation Error',
        content: {
          'application/json': {
            schema: {
              $ref: {
                title: 'HTTPValidationError',
                type: 'object',
                properties: {
                  errors: {
                    title: 'Errors',
                    type: 'array',
                    items: {
                      $ref: {
                        title: 'ValidationError',
                        required: ['loc', 'msg', 'type'],
                        type: 'object',
                        properties: {
                          loc: {
                            title: 'Location',
                            type: 'array',
                            items: {
                              anyOf: [
                                {
                                  type: 'string',
                                },
                                {
                                  type: 'integer',
                                },
                              ],
                            },
                          },
                          msg: {
                            title: 'Message',
                            type: 'string',
                          },
                          type: {
                            title: 'Error Type',
                            type: 'string',
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
  {
    endpoint: '/api/v2/analytics/chains/{chain}/transactions',
    method: 'get',
    tags: ['v2'],
    summary: 'List All Transactions',
    description:
      'Retrieve the paginated list of transactions screening results based on the requesting business identity. ',
    operationId: 'List_all_transactions_api_v2_analytics_transactions_get',
    parameters: [
      {
        name: 'chain',
        in: 'path',
        required: true,
        schema: {
          title: 'Chain',
          description: 'Blockchain network name.',
          type: 'string',
        },
        description: 'Blockchain network name.',
        examples: ['all', 'bitcoin', 'ethereum'],
      },
      {
        name: 'start_range',
        in: 'query',
        required: false,
        schema: {
          title: 'Start Range',
          description: 'Starting point for specified data range.',
          type: 'string',
          format: 'date',
        },
        description: 'Starting point for specified data range.',
        examples: '2020-11-08',
      },
      {
        name: 'end_range',
        in: 'query',
        required: false,
        schema: {
          title: 'End Range',
          description: 'Ending point for specified data range.',
          type: 'string',
          format: 'date',
        },
        description: 'Ending point for specified data range.',
        examples: '2024-12-09',
      },
      {
        name: 'page',
        in: 'query',
        required: false,
        schema: {
          title: 'Page',
          default: 1,
          minimum: 1,
          type: 'integer',
        },
      },
      {
        name: 'size',
        in: 'query',
        required: false,
        schema: {
          title: 'Size',
          default: 10,
          minimum: 1,
          maximum: 50,
          type: 'integer',
        },
      },
      {
        name: 'sort',
        in: 'query',
        required: false,
        schema: {
          title: 'Sort',
          description: 'Criteria for result sorting.',
          type: 'string',
        },
        description: 'Criteria for result sorting.',
        examples: '-last_modified',
      },
    ],
    responses: {
      200: {
        description: 'Successful Response',
        content: {
          'application/json': {
            schema: {
              $ref: {
                title: 'TX04ResponseCom',
                type: 'object',
                properties: {
                  status: {
                    title: 'Status',
                    type: 'string',
                    default: 'success',
                    examples: 'success',
                  },
                  data: {
                    $ref: {
                      title: 'TransactionSummaryResponse',
                      required: ['items', 'pagination'],
                      type: 'object',
                      properties: {
                        items: {
                          title: 'Items',
                          type: 'array',
                          items: {
                            $ref: {
                              title: 'TransactionsInSummary',
                              required: ['hash', 'chain', 'last_modified'],
                              type: 'object',
                              properties: {
                                risk_ratio: {
                                  title: 'Risk Ratio',
                                  type: 'integer',
                                  examples: 69,
                                },
                                hash: {
                                  title: 'Hash',
                                  type: 'string',
                                  examples: '86ea091dfd587c6bd5111daa395c9ed58b0b70ea71af6c50217baa52beb9cd93',
                                },
                                chain: {
                                  title: 'Chain',
                                  type: 'string',
                                  examples: 'bitcoin',
                                },
                                in_usd: {
                                  title: 'In Usd',
                                  type: 'number',
                                  examples: 3.4587061776,
                                },
                                symbol: {
                                  title: 'Symbol',
                                  type: 'string',
                                  examples: 'BTC',
                                },
                                last_modified: {
                                  title: 'Last Modified',
                                  type: 'string',
                                  format: 'date-time',
                                  examples: '2024-01-10T10:23:34.636355+00:00',
                                },
                              },
                            },
                          },
                          examples: [
                            {
                              risk_ratio: 50,
                              hash: 'f24f0e170461545b20c464507113e590a007dd10df087628d8b1842976791d5c',
                              chain: 'bitcoin',
                              in_usd: 35552.02552238,
                              symbol: 'BTC',
                              last_modified: '2024-02-09T23:33:12.674530+00:00',
                            },
                            {
                              risk_ratio: 47,
                              hash: '0xc7fe329f0ad81a09276a61b3bc559a725971ffa4ca90b80037ef799006ab1797',
                              chain: 'ethereum',
                              in_usd: 168.85432658,
                              symbol: 'ETH',
                              last_modified: '2024-02-06T16:51:42.072321+00:00',
                            },
                            {
                              risk_ratio: 51,
                              hash: '7854c8b197ee8832ca2f21d281b6dc0710d0335e2746b30f63f750e9af716835',
                              chain: 'bitcoin',
                              in_usd: 1486596.63241812,
                              symbol: 'BTC',
                              last_modified: '2024-02-06T16:48:28.379959+00:00',
                            },
                            {
                              hash: '6e3e65f8d9ac10374dbf468d3a5e8ace9bbf40317848d9265124a6360dcdbe9b',
                              chain: 'stellar',
                              in_usd: 0,
                              symbol: 'XLM',
                              last_modified: '2024-02-02T13:46:55.594142+00:00',
                            },
                          ],
                        },
                        pagination: {
                          $ref: {
                            title: 'Pagination',
                            required: ['total', 'size', 'page'],
                            type: 'object',
                            properties: {
                              total: {
                                title: 'Total',
                                type: 'integer',
                                description: 'The total number of records.',
                                examples: 4,
                              },
                              size: {
                                title: 'Size',
                                type: 'integer',
                                description: 'The size or number of records per page.',
                                examples: 10,
                              },
                              page: {
                                title: 'Page',
                                type: 'integer',
                                description: 'The current page number in pagination.',
                                examples: 1,
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                  message: {
                    title: 'Message',
                    type: 'string',
                    examples: 'Null',
                  },
                },
              },
            },
          },
        },
      },
      422: {
        description: 'Validation Error',
        content: {
          'application/json': {
            schema: {
              $ref: {
                title: 'HTTPValidationError',
                type: 'object',
                properties: {
                  errors: {
                    title: 'Errors',
                    type: 'array',
                    items: {
                      $ref: {
                        title: 'ValidationError',
                        required: ['loc', 'msg', 'type'],
                        type: 'object',
                        properties: {
                          loc: {
                            title: 'Location',
                            type: 'array',
                            items: {
                              anyOf: [
                                {
                                  type: 'string',
                                },
                                {
                                  type: 'integer',
                                },
                              ],
                            },
                          },
                          msg: {
                            title: 'Message',
                            type: 'string',
                          },
                          type: {
                            title: 'Error Type',
                            type: 'string',
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
]

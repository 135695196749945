import React from 'react'
import PropTypes from 'prop-types'

// Assets
import { EmptyIcon } from 'assets/images'

// Components
import { Button } from 'views/components'

// Styled Elements
import {
  ButtonWrapper,
  EmptyTablePlaceholderWrapper,
  EmptyTablePlaceholderImage,
  EmptyTablePlaceholderTextWrapper,
  EmptyTablePlaceholderText,
  EmptyTablePlaceholderSubText,
} from './EmptyTablePlaceholder.elements'

function EmptyTablePlaceholder(props) {
  const { setFilter } = props

  return (
    <EmptyTablePlaceholderWrapper>
      <EmptyTablePlaceholderImage>
        <EmptyIcon />
      </EmptyTablePlaceholderImage>
      <EmptyTablePlaceholderTextWrapper>
        <EmptyTablePlaceholderText>No search result</EmptyTablePlaceholderText>
        <EmptyTablePlaceholderSubText>Please, try a different combination of filters</EmptyTablePlaceholderSubText>
        {setFilter && (
          <ButtonWrapper>
            <Button color="primary" onClick={() => setFilter()}>
              Reset Filters
            </Button>
          </ButtonWrapper>
        )}
      </EmptyTablePlaceholderTextWrapper>
    </EmptyTablePlaceholderWrapper>
  )
}

// Default Props
EmptyTablePlaceholder.defaultProps = {
  setFilter: () => {},
}

// Proptypes Validation
EmptyTablePlaceholder.propTypes = {
  setFilter: PropTypes.func,
}

export default EmptyTablePlaceholder

/* eslint-disable react/no-array-index-key */
import React from 'react'
import PropTypes from 'prop-types'

// Styled Elements
import { Wrapper } from './ReqbodyTable.elements'

// Views
import { AccordionTable } from 'views/components'
import { ReqbodyTableItem, ReqbodyTableHeader } from 'views/layouts'

function ReqbodyTable(props) {
  // Destructure
  const { data, requiredList } = props

  if (!data && !requiredList) return

  return (
    <Wrapper>
      <AccordionTable minWidth={300}>
        <ReqbodyTableHeader />
        {data.map((data, i) => (
          <ReqbodyTableItem key={i} data={data} required={requiredList[data.name]} />
        ))}
      </AccordionTable>
    </Wrapper>
  )
}

// Default Props
ReqbodyTable.defaultProps = {
  data: [],
  requiredList: [],
}

// Proptypes Validation
ReqbodyTable.propTypes = {
  data: PropTypes.arrayOf({}),
  requiredList: PropTypes.arrayOf({}),
}

export default ReqbodyTable

/* eslint-disable react/no-array-index-key */
/* eslint-disable quotes */
/* eslint-disable react/jsx-curly-brace-presence */
import React from 'react'
import PropTypes from 'prop-types'

// Styled Elements
import {
  Wrapper,
  Container,
  SubHeaderWrapper,
  SubHeaderNavListItem,
  SubHeaderNavListLink,
  SubHeaderNavList,
  RowContainer,
  Body,
} from './EndpointsCard.elements'

function EndpointsCard(props) {
  const { data } = props
  return (
    <Wrapper>
      <SubHeaderWrapper>
        <SubHeaderNavList>
          <SubHeaderNavListItem>
            <SubHeaderNavListLink>ENDPOINTS</SubHeaderNavListLink>
          </SubHeaderNavListItem>
        </SubHeaderNavList>
      </SubHeaderWrapper>
      <Container>
        {data?.length > 0 &&
          data.map((item, i) => (
            <RowContainer key={i}>
              <Body>{item?.method}</Body>
              <Body>{item?.endpoint}</Body>
            </RowContainer>
          ))}
      </Container>
    </Wrapper>
  )
}

// Default Props
EndpointsCard.defaultProps = {}

// Proptypes Validation
EndpointsCard.propTypes = {
  data: PropTypes.arrayOf(),
}

export default EndpointsCard

/* eslint-disable no-confusing-arrow */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

// Views
import { AccordionTableItem } from 'views/components'
import { ReqbodyTableItemDetails } from 'views/layouts'

// Styled Elements
import {
  TableButtonWrapper,
  TableRowWrapper,
  TableRowContainer,
  TableText,
  TablePanelWrapper,
} from './ReqbodyTableItem.elements'

function ReqbodyTableItem(props) {
  const { data, required } = props
  // States
  const [isExpanded, setIsExpanded] = useState(false)
  const [type, setType] = useState('')

  useEffect(() => {
    if (data?.type) setType(data?.type)
    if (data?.$ref?.type) setType(data?.$ref?.type)
    if (data?.allOf?.[0]?.$ref?.type) setType(data?.allOf?.[0]?.$ref?.type)
  }, [data])
  return (
    <AccordionTableItem
      setIsExpanded={(val) => setIsExpanded(val)}
      expandPanel={isExpanded}
      button={
        <TableButtonWrapper>
          <TableRowWrapper isExpanded={isExpanded}>
            <TableRowContainer style={{ minWidth: '180px' }}>
              <TableText>{data.name}</TableText>
            </TableRowContainer>

            <TableRowContainer>
              <TableText>
                {required && 'True'}
                {!required && data?.requirement && data?.requirement}
              </TableText>
            </TableRowContainer>
          </TableRowWrapper>
          <TableRowWrapper isExpanded={isExpanded}>
            <TableRowContainer>
              <TableText>{type}</TableText>
              {data?.anyOf && (
                <TableText>
                  {data?.anyOf.map((item, i) =>
                    i + 1 < data?.anyOf?.length ? `${item?.format || item?.type}, ` : `${item?.format || item?.type}`
                  )}
                </TableText>
              )}
            </TableRowContainer>
          </TableRowWrapper>
        </TableButtonWrapper>
      }
      panel={
        <TablePanelWrapper>
          <ReqbodyTableItemDetails data={data} />
        </TablePanelWrapper>
      }
    />
  )
}

// Default Props
ReqbodyTableItem.defaultProps = {
  data: {},
  required: false,
}

// Proptypes Validation
ReqbodyTableItem.propTypes = {
  data: PropTypes.shape({
    requirement: PropTypes.string,
    anyOf: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        format: PropTypes.string,
      })
    ),
    allOf: PropTypes.arrayOf(
      PropTypes.shape({
        $ref: PropTypes.shape({
          type: PropTypes.string,
        }),
      })
    ),
    name: PropTypes.string,
    required: PropTypes.bool,
    type: PropTypes.string,
    $ref: PropTypes.shape({ type: PropTypes.string }),
  }),
  required: PropTypes.bool,
}

export default ReqbodyTableItem

import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

// Styled Elements
import {
  SidebarWrapper,
  SidebarBrand,
  SidebarBrandLogo,
  SidebarMenuNav,
  SidebarMenuNavList,
  SidebarMenuNavItem,
  SidebarMenuNavLink,
  SidebarMenuNavText,
  SidebarMenuNavContainer,
} from './Sidebar.elements'

// assets
import OspreeLogoIcon from 'assets/images/ospreeLogo'

function Sidebar(props) {
  // Destructure
  const { links, activeLink, setActiveLink } = props

  return (
    <SidebarWrapper>
      <SidebarMenuNav>
        <SidebarMenuNavList>
          <SidebarBrand>
            <NavLink to="/">
              <SidebarBrandLogo>
                <OspreeLogoIcon />
              </SidebarBrandLogo>
            </NavLink>
          </SidebarBrand>

          {/* <SidebarMenuNavItem>
            <SidebarMenuNavLink active={activeLink === 'directory'} onClick={() => setActiveLink('directory')}>
              <SidebarMenuNavText style={{ fontWeight: 400, fontSize: 14 }}>Directory</SidebarMenuNavText>
            </SidebarMenuNavLink>
          </SidebarMenuNavItem>
          {activeLink === 'directory' && (
            <SidebarMenuNavContainer>
              {links?.length > 0 &&
                links.map((item) => (
                  <SidebarMenuNavItem key={item.summary}>
                    <SidebarMenuNavLink href={`#${item.summary}`}>
                      <SidebarMenuNavText>{item.summary.split(':').join(' | ')}</SidebarMenuNavText>
                    </SidebarMenuNavLink>
                  </SidebarMenuNavItem>
                ))}
            </SidebarMenuNavContainer>
          )} */}

          <SidebarMenuNavItem>
            <SidebarMenuNavLink href="#introduction">
              <SidebarMenuNavText style={{ fontWeight: 400, fontSize: 14 }}>Introduction</SidebarMenuNavText>
            </SidebarMenuNavLink>
          </SidebarMenuNavItem>
          <SidebarMenuNavItem>
            <SidebarMenuNavLink href="#apiKeys">
              <SidebarMenuNavText style={{ fontWeight: 400, fontSize: 14 }}>API Keys and Access</SidebarMenuNavText>
            </SidebarMenuNavLink>
          </SidebarMenuNavItem>
          <SidebarMenuNavItem>
            <SidebarMenuNavLink href="#apiProtocols">
              <SidebarMenuNavText style={{ fontWeight: 400, fontSize: 14 }}>API Protocols</SidebarMenuNavText>
            </SidebarMenuNavLink>
          </SidebarMenuNavItem>
          <SidebarMenuNavItem>
            <SidebarMenuNavLink href="#breakingChanges">
              <SidebarMenuNavText style={{ fontWeight: 400, fontSize: 14 }}>Breaking Changes</SidebarMenuNavText>
            </SidebarMenuNavLink>
          </SidebarMenuNavItem>
          <SidebarMenuNavItem>
            <SidebarMenuNavLink href="#errors">
              <SidebarMenuNavText style={{ fontWeight: 400, fontSize: 14 }}>Errors</SidebarMenuNavText>
            </SidebarMenuNavLink>
          </SidebarMenuNavItem>
          <SidebarMenuNavItem>
            <SidebarMenuNavLink
              href="#address"
              active={activeLink === 'address'}
              onClick={() => setActiveLink('address')}
            >
              <SidebarMenuNavText style={{ fontWeight: 400, fontSize: 14 }}>Addresses</SidebarMenuNavText>
            </SidebarMenuNavLink>
          </SidebarMenuNavItem>
          <SidebarMenuNavContainer>
            {links?.address?.length > 0 &&
              links?.address.map((item) => (
                <SidebarMenuNavItem key={item.summary}>
                  <SidebarMenuNavLink href={`#${item.summary}`}>
                    <SidebarMenuNavText>{item.summary.split(':').join(' | ')}</SidebarMenuNavText>
                  </SidebarMenuNavLink>
                </SidebarMenuNavItem>
              ))}
          </SidebarMenuNavContainer>

          <SidebarMenuNavItem>
            <SidebarMenuNavLink
              href="#transaction"
              active={activeLink === 'transaction'}
              onClick={() => setActiveLink('transaction')}
            >
              <SidebarMenuNavText style={{ fontWeight: 400, fontSize: 14 }}>Transaction</SidebarMenuNavText>
            </SidebarMenuNavLink>
          </SidebarMenuNavItem>
          <SidebarMenuNavContainer>
            {links?.transaction?.length > 0 &&
              links?.transaction.map((item) => (
                <SidebarMenuNavItem key={item.summary}>
                  <SidebarMenuNavLink href={`#${item.summary}`}>
                    <SidebarMenuNavText>{item.summary.split(':').join(' | ')}</SidebarMenuNavText>
                  </SidebarMenuNavLink>
                </SidebarMenuNavItem>
              ))}
          </SidebarMenuNavContainer>

          <SidebarMenuNavItem>
            <SidebarMenuNavLink
              href="#directory"
              active={activeLink === 'directory'}
              onClick={() => setActiveLink('directory')}
            >
              <SidebarMenuNavText style={{ fontWeight: 400, fontSize: 14 }}>Directory</SidebarMenuNavText>
            </SidebarMenuNavLink>
          </SidebarMenuNavItem>
          <SidebarMenuNavContainer>
            {links?.directory?.length > 0 &&
              links?.directory.map((item) => (
                <SidebarMenuNavItem key={item.summary}>
                  <SidebarMenuNavLink href={`#${item.summary}`}>
                    <SidebarMenuNavText>{item.summary.split(':').join(' | ')}</SidebarMenuNavText>
                  </SidebarMenuNavLink>
                </SidebarMenuNavItem>
              ))}
          </SidebarMenuNavContainer>

          <SidebarMenuNavItem>
            <SidebarMenuNavLink
              href="#transfer"
              active={activeLink === 'transfer'}
              onClick={() => setActiveLink('transfer')}
            >
              <SidebarMenuNavText style={{ fontWeight: 400, fontSize: 14 }}>Travel Rule</SidebarMenuNavText>
            </SidebarMenuNavLink>
          </SidebarMenuNavItem>
          <SidebarMenuNavContainer>
            {links?.transfer?.length > 0 &&
              links?.transfer.map((item) => (
                <SidebarMenuNavItem key={item.summary}>
                  <SidebarMenuNavLink href={`#${item.summary}`}>
                    <SidebarMenuNavText>{item.summary.split(':').join(' | ')}</SidebarMenuNavText>
                  </SidebarMenuNavLink>
                </SidebarMenuNavItem>
              ))}
          </SidebarMenuNavContainer>

          {/* <SidebarMenuNavItem>
            <SidebarMenuNavLink active={activeLink === 'user'} onClick={() => setActiveLink('user')}>
              <SidebarMenuNavText>Users</SidebarMenuNavText>
            </SidebarMenuNavLink>
          </SidebarMenuNavItem>
          {activeLink === 'user' && (
            <SidebarMenuNavContainer>
              {links?.length > 0 &&
                links.map((item) => (
                  <SidebarMenuNavItem key={item.summary}>
                    <SidebarMenuNavLink href={`#${item.summary}`}>
                      <SidebarMenuNavText>{item.summary.split(':').join(' | ')}</SidebarMenuNavText>
                    </SidebarMenuNavLink>
                  </SidebarMenuNavItem>
                ))}
            </SidebarMenuNavContainer>
          )} */}
        </SidebarMenuNavList>
      </SidebarMenuNav>
    </SidebarWrapper>
  )
}

// Default Props
Sidebar.defaultProps = {
  setActiveLink: () => {},
  activeLink: '',
}

// Proptypes Validation
Sidebar.propTypes = {
  setActiveLink: PropTypes.func,
  activeLink: PropTypes.string,
  links: PropTypes.arrayOf({}),
}

export default Sidebar

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

// Hooks
// import { useFetchDirectoryDoc, useFetchAddressesDoc, useFetchTransfersDoc, useFetchUsersDoc } from 'core/hooks/api'

// Constants
import { TransfersData } from 'common/constants/transfersData'
import { AddressData } from 'common/constants/addressData'
import { TransactionData } from 'common/constants/transactionData'
import { DirectoryData } from 'common/constants/directoryData'

// Layouts
import { App, Sidebar, Content, PageWrapper, DocumentationSection } from 'views/layouts'

function Home() {
  // Hooks
  // const { getDirectoryDoc, directoryDocData, isDirectoryDocLoading } = useFetchDirectoryDoc()
  // const { getAddressesDoc, addressesDocData, isAddressesDocLoading } = useFetchAddressesDoc()
  // const { getTransfersDoc, transfersDocData, isTransfersDocLoading } = useFetchTransfersDoc()
  // const { getUsersDoc, usersDocData, isUsersDocLoading } = useFetchUsersDoc()

  // States
  // const [directoryDocs, setDirectoryDocs] = useState()
  // const [transfersDocs, setTransfersDocs] = useState()
  // const [addressesDocs, setAddressesDocs] = useState()
  // const [transactionsDocs, setTransactionsDocs] = useState()
  // const [usersDocs, setUsersDocs] = useState()
  const [activeLink, setActiveLink] = useState('address')

  // Constants
  const docs = {
    directory: DirectoryData,
    transfer: TransfersData,
    address: AddressData,
    transaction: TransactionData,
    // user: usersDocs,
  }

  // Functions
  function updateRefs(obj, components) {
    if (typeof obj === 'object') {
      if (Object.prototype.hasOwnProperty.call(obj, '$ref')) {
        if (typeof obj.$ref === 'string' || obj.$ref instanceof String) {
          const strings = obj.$ref.split('/')
          obj.$ref = components[strings[2]][strings[3]]
        }
      }
      const keys = Object.keys(obj)
      keys.forEach((key) => {
        updateRefs(obj[key], components)
      })
    }
  }
  function formatEndpoints(docs) {
    if (typeof docs === 'object') {
      const paths = docs?.paths
      const endpointKeys = Object.keys(paths)
      const newEndpoints = []
      endpointKeys.forEach((eKey) => {
        const methodKeys = Object.keys(paths[eKey])
        methodKeys.forEach((mKey) => {
          if (paths[eKey][mKey]?.tags?.length > 0) {
            if (paths[eKey][mKey]?.tags[0] === 'v2') {
              const newEndpoint = { endpoint: eKey, method: mKey, ...paths[eKey][mKey] }
              updateRefs(newEndpoint, docs.components)
              newEndpoints.push(newEndpoint)
            }
          }
        })
      })
      return newEndpoints
    }
  }

  // UseEffects
  useEffect(() => {
    // setDirectoryDocs(DirectoryData)
    // setAddressesDocs(AddressData)
    // setTransactionsDocs(TransactionData)
  }, [])
  useEffect(() => {
    // getDirectoryDoc()
    // getAddressesDoc()
    // getTransfersDoc()
    // getUsersDoc()
  }, [])
  useEffect(
    () => {
      // if (directoryDocData) {
      //   const endpoint = formatEndpoints(directoryDocData)
      //   console.log('directory', endpoint)
      //   setDirectoryDocs(endpoint)
      // }
      // if (addressesDocData) {
      //   const endpoint = formatEndpoints(addressesDocData)
      //   setAddressesDocs(endpoint)
      // }
      // if (transfersDocData) {
      //   const endpoint = formatEndpoints(transfersDocData)
      //   console.log('transfers', endpoint)
      //   setTransfersDocs(endpoint)
      // }
      // if (usersDocData) {
      //   const endpoint = formatEndpoints(usersDocData)
      //   console.log('users', endpoint)
      //   setUsersDocs(endpoint)
      // }
    },
    [
      // directoryDocData,
      // addressesDocData,
      // transfersDocData,
      // usersDocData
    ]
  )
  return (
    <App>
      {docs[activeLink] && (
        <Sidebar
          activeLink={activeLink}
          setActiveLink={setActiveLink}
          links={{
            address: docs.address.map((item) => ({ summary: item?.summary })),
            transaction: docs.transaction.map((item) => ({ summary: item?.summary })),
            directory: docs.directory.map((item) => ({ summary: item?.summary })),
            transfer: docs.transfer.map((item) => ({ summary: item?.summary })),
          }}
        />
      )}
      <Content>
        <PageWrapper>
          <DocumentationSection data={docs} />
        </PageWrapper>
      </Content>
    </App>
  )
}

// Default Props
Home.defaultProps = {
  actions: {},
  filters: {},
  ui: {},
}

// Proptypes Validation
Home.propTypes = {
  actions: PropTypes.shape({
    setShowHeaderLoader: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    showAlert: PropTypes.func,
  }),
  filters: PropTypes.shape({
    whitelistingFilters: PropTypes.instanceOf(Object),
  }),
  ui: PropTypes.shape({
    isPageTableUpdated: PropTypes.bool,
  }),
}

export default Home

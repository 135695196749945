import React, { useState } from 'react'
import PropTypes from 'prop-types'

// Views
import { AccordionTableItem } from 'views/components'
import { QueryTableItemDetails } from 'views/layouts'

// Styled Elements
import {
  TableButtonWrapper,
  TableRowWrapper,
  TableRowContainer,
  TableText,
  TablePanelWrapper,
} from './QueryTableItem.elements'

function QueryTableItem(props) {
  const { data } = props
  // States
  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <AccordionTableItem
      setIsExpanded={(val) => setIsExpanded(val)}
      expandPanel={isExpanded}
      button={
        <TableButtonWrapper>
          <TableRowWrapper isExpanded={isExpanded}>
            <TableRowContainer style={{ minWidth: '180px' }}>
              <TableText>{data.name}</TableText>
            </TableRowContainer>

            <TableRowContainer>
              <TableText>{(data?.required || data?.$ref?.required) && 'True'}</TableText>
            </TableRowContainer>
          </TableRowWrapper>
          <TableRowWrapper isExpanded={isExpanded}>
            <TableRowContainer>
              <TableText>{data?.schema?.type || data?.schema?.$ref?.type}</TableText>
            </TableRowContainer>
          </TableRowWrapper>
        </TableButtonWrapper>
      }
      panel={
        <TablePanelWrapper>
          <QueryTableItemDetails data={data} />
        </TablePanelWrapper>
      }
    />
  )
}

// Default Props
QueryTableItem.defaultProps = {
  data: {},
}

// Proptypes Validation
QueryTableItem.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    required: PropTypes.bool,
    schema: PropTypes.shape({ type: PropTypes.string, $ref: PropTypes.shape({ type: PropTypes.string }) }),
    $ref: PropTypes.shape({ required: PropTypes.bool }),
  }),
}

export default QueryTableItem

import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'

import PropTypes from 'prop-types'

function AppReactQueryProvider(props) {
  // Destructure
  const { children } = props

  // Variables
  const queryClient = new QueryClient()

  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
}

export default AppReactQueryProvider

// Proptypes Validation
AppReactQueryProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]).isRequired,
}

/* eslint-disable max-len */
export const TransfersData = [
  {
    endpoint: '/api/v2/travelrule/transfers/start',
    method: 'post',
    tags: ['transfers'],
    summary: 'Create Transfer',
    description:
      'The Start a Transfer endpoint initiates the transfer of Travel Rule data between entities. It activates the essential data collection and verification procedures.',
    operationId: 'start_transfer_api_v2_travelrule_transfers_start_post',
    requestBody: {
      content: {
        'application/json': {
          schema: {
            $ref: {
              title: 'TransferStartInput',
              required: [
                'amount',
                'dti',
                'protocol',
                'beneficiary_vasp_id',
                'originator_address',
                'beneficiary_address',
                // 'originator_account_reference_id',
                // 'beneficiary_account_reference_id',
                // 'transaction_hash',
                // 'reference_id',
              ],
              type: 'object',
              properties: {
                amount: {
                  title: 'Amount',
                  description: 'Quantity of currency/token transferred in the transaction.',
                  type: 'number',
                  examples: 1,
                },
                dti: {
                  title: 'Asset Code',
                  description: 'Unique identifier for the assets based on DTIF standards (see https://dtif.org)',
                  type: 'string',
                  examples: '4H95J0R2X',
                },
                protocol: {
                  title: 'Protocol',
                  description: 'Travel Rule protocol',
                  type: 'string',
                  examples: ['trp', 'gtr', 'flow', 'trisa'],
                },
                beneficiary_vasp_id: {
                  title: 'Beneficiary Vasp Id',
                  description: 'ID of the receiving Virtual Asset Service Provider (VASP).',
                  type: 'string',
                  format: 'uuid',
                  examples: '“VASP12345”',
                },
                originator_address: {
                  title: 'Originator Hash Address',
                  description: 'Blockchain address of the sender.',
                  type: 'string',
                  examples: '12higDjoCCNXSA95xZMWUdPvXNmkAduhWv',
                },
                beneficiary_address: {
                  title: 'Beneficiary Hash Address',
                  description: 'Blockchain address of the recipient.',
                  type: 'string',
                  examples: 'bc1qas2rvpejpvncd6z5hcscvw52n4wxw5th2de67v',
                },
                originator_account_reference_id: {
                  title: 'Originator Account ID',
                  description: "Sender's account ID within the originator VASP.",
                  type: 'string',
                  examples: 'account5678',
                },
                beneficiary_account_reference_id: {
                  title: 'Beneficiary Account Id',
                  description: "Recipient's account ID within the originator VASP.",
                  type: 'string',
                  examples: 'account1234',
                },
                transaction_hash: {
                  title: 'Transaction Hash',
                  description: 'Unique ID of the blockchain transaction.',
                  type: 'string',
                  format: 'uuid',
                  examples: '80003fee6bcbb2726b4d6466d690fce6caaa03771aada747eb0651637514b893',
                },
                reference_id: {
                  title: 'Reference Id',
                  description: 'Unique identifier for the transfer.',
                  type: 'string',
                  format: 'uuid',
                  examples: 'transfer7890',
                },
              },
              description: 'POST /travelrule/travelrule/start',
            },
          },
        },
      },
      required: true,
    },
    responses: {
      200: {
        description: 'Successful Response',
        content: {
          'application/json': {
            schema: {
              $ref: {
                title: 'TransferOutput',
                required: [
                  'account_id',
                  'recipient_id',
                  'originator_vasp_id',
                  'recipient_vasp_id',
                  'originator_hash_address',
                  'recipient_hash_address',
                  'amount',
                  'direction_id',
                  'dti',
                  'transfer_status',
                  'transfer_id',
                ],
                type: 'object',
                properties: {
                  status: {
                    title: 'Status',
                    type: 'string',
                    default: 'success',
                    examples: 'success',
                  },
                  data: {
                    $ref: {
                      title: 'AddressSummaryResponse',
                      required: ['items', 'pagination'],
                      type: 'object',
                      examples: {
                        transfer_id: '018f7212-18be-7713-ad7c-89704e18bd5c',
                        amount: 1,
                        amount_usd: 63421.62,
                        dti: '4H95J0R2X',
                        protocol: 'TRP',
                        beneficiary_vasp_id: '062f2fd7-ce79-7bc5-8000-1761ee98a027',
                        originator_address: '12higDjoCCNXSA95xZMWUdPvXNmkAduhWv',
                        beneficiary_address: 'bc1qas2rvpejpvncd6z5hcscvw52n4wxw5th2de67v',
                        originator_account_reference_id: 'account5678',
                        beneficiary_account_reference_id: 'account1234',
                        transaction_hash: '80003fee6bcbb2726b4d6466d690fce6caaa03771aada747eb0651637514b893',
                        transfer_status: 'initiated',
                      },
                    },
                  },
                  message: {
                    title: 'Message',
                    type: 'string',
                    examples: null,
                  },
                },
                description: 'GET /travelrule/transfers',
              },
            },
          },
        },
      },
      404: {
        description: 'Not found',
      },
      422: {
        description: 'Validation Error',
        content: {
          'application/json': {
            schema: {
              $ref: {
                title: 'HTTPValidationError',
                type: 'object',
                properties: {
                  detail: {
                    title: 'Detail',
                    type: 'array',
                    items: {
                      $ref: {
                        title: 'ValidationError',
                        required: ['loc', 'msg', 'type'],
                        type: 'object',
                        properties: {
                          loc: {
                            title: 'Location',
                            type: 'array',
                            items: {
                              anyOf: [
                                {
                                  type: 'string',
                                },
                                {
                                  type: 'integer',
                                },
                              ],
                            },
                          },
                          msg: {
                            title: 'Message',
                            type: 'string',
                          },
                          type: {
                            title: 'Error Type',
                            type: 'string',
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
    security: [
      {
        HTTPBearer: [],
      },
    ],
  },
  {
    endpoint: '/api/v2/travelrule/transfers',
    method: 'get',
    tags: ['v2'],
    summary: 'List Transfers',
    description:
      'The List Transfers endpoint provides a comprehensive overview of all initiated transfers between entities within the system. It enables users to access a high-level view of transfer transactions, including relevant metadata and compliance.',
    operationId: 'List_all_addresses_api_v2_analytics_chains__chain__addresses_get',
    security: [
      {
        HTTPBearer: [],
      },
    ],
    parameters: [
      {
        name: 'dti',
        in: 'query',
        required: false,
        schema: {
          title: 'Chain',
          description: 'Unique identifier for the assets based on DTIF standards (see https://dtif.org)',
          type: 'string',
        },
        description: 'Unique identifier for the assets based on DTIF standards (see https://dtif.org)',
        examples: '4H95J0R2X',
      },
      {
        name: 'page',
        in: 'query',
        required: false,
        schema: {
          title: 'Page',
          default: 1,
          minimum: 1,
          type: 'integer',
          description: 'The current page number in pagination.',
        },
      },
      {
        name: 'size',
        in: 'query',
        required: false,
        schema: {
          title: 'Size',
          default: 10,
          minimum: 1,
          maximum: 50,
          type: 'integer',
          description: 'The size or number of records per page.',
        },
      },
      {
        name: 'sort',
        in: 'query',
        required: false,
        schema: {
          title: 'Sort',
          description: 'Criteria for result sorting.',
          type: 'string',
        },
        description: 'Criteria for result sorting.',
        examples: ['-last_modified', 'last_modified', '-created', 'created'],
        default: '-created',
      },
      {
        name: 'beneficiary_vasp_id',
        in: 'query',
        required: false,
        schema: {
          title: 'beneficiary vasp id',
          description: 'ID of the receiving Virtual Asset Service Provider (VASP).',
          type: 'string',
          examples: 'VASP67890',
        },
      },
      {
        name: 'originator_address',
        in: 'query',
        required: false,
        schema: {
          title: 'originator address',
          description: 'Blockchain address of the sender.',
          type: 'string',
          examples: '12higDjoCCNXSA95xZMWUdPvXNmkAduhWv',
        },
      },
      {
        name: 'beneficiary_address',
        in: 'query',
        required: false,
        schema: {
          title: 'beneficiary address',
          description: 'Blockchain address of the recipient.',
          type: 'string',
          examples: 'bc1qas2rvpejpvncd6z5hcscvw52n4wxw5th2de67v',
        },
      },
      {
        name: 'originator_account_reference_id',
        in: 'query',
        required: false,
        schema: {
          title: 'originator account id',
          description: "Sender's account ID within the originator VASP.",
          type: 'string',
          examples: 'account5678',
        },
      },
      {
        name: 'beneficiary_account_reference_id',
        in: 'query',
        required: false,
        schema: {
          title: 'beneficiary account id',
          description: "Recipient's account ID within the originator VASP.",
          type: 'string',
          examples: 'account1234',
        },
      },
      {
        name: 'direction_id',
        in: 'query',
        required: false,
        schema: {
          title: 'direction id',
          description: 'Identifier indicating the direction of the transfer.',
          type: 'string',
          examples: ['in', 'out'],
        },
      },
      {
        name: 'transfer_status',
        in: 'query',
        required: false,
        schema: {
          title: 'transfer status desc',
          description: 'Identifier indicating the current status of the transfer',
          type: 'string',
          examples: ['initiated', 'pending', 'approved', 'rejected', 'canceled'],
        },
      },
      {
        name: 'beneficiary_account_type',
        in: 'query',
        required: false,
        schema: {
          title: 'beneficiary account type',
          description: "Type of the recipient's account.",
          type: 'string',
          examples: 'entity',
        },
      },
    ],
    responses: {
      200: {
        description: 'Successful Response',
        content: {
          'application/json': {
            schema: {
              $ref: {
                title: 'ADD04ResponseCom',
                type: 'object',
                properties: {
                  status: {
                    title: 'Status',
                    type: 'string',
                    default: 'success',
                    examples: 'success',
                  },
                  data: {
                    $ref: {
                      title: 'AddressSummaryResponse',
                      required: ['items', 'pagination'],
                      type: 'object',
                      properties: {
                        items: {
                          title: 'Items',
                          type: 'array',
                          examples: [
                            {
                              transfer_id: '018f7212-18be-7713-ad7c-89704e18bd5c',
                              amount: 1,
                              amount_usd: 63421.62,
                              asset_code: '4H95J0R2X',
                              protocol: 'flow',
                              beneficiary_vasp_id: '062f2fd7-ce79-7bc5-8000-1761ee98a027',
                              originator_address: '12higDjoCCNXSA95xZMWUdPvXNmkAduhWv',
                              beneficiary_address: 'bc1qas2rvpejpvncd6z5hcscvw52n4wxw5th2de67v',
                              transaction_hash: '80003fee6bcbb2726b4d6466d690fce6caaa03771aada747eb0651637514b893',
                              originator_account_id: 'account5678',
                              beneficiary_account_id: 'account1234',
                              direction_id: 'out',
                              transfer_status: 'initiated',
                              last_modified: '2024-04-07T22:32:31.503643+00:00',
                              created: '2023-03-03T08:17:40.945108+00:00',
                            },
                          ],
                        },
                        pagination: {
                          $ref: {
                            title: 'Pagination',
                            required: ['total', 'size', 'page'],
                            type: 'object',
                            properties: {
                              total: {
                                title: 'Total',
                                type: 'integer',
                                description: 'The total number of records.',
                                examples: 1,
                              },
                              size: {
                                title: 'Size',
                                type: 'integer',
                                description: 'The size or number of records per page.',
                                examples: 1,
                              },
                              page: {
                                title: 'Page',
                                type: 'integer',
                                description: 'The current page number in pagination.',
                                examples: 1,
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                  message: {
                    title: 'Message',
                    type: 'string',
                    examples: null,
                  },
                },
              },
            },
          },
        },
      },
      422: {
        description: 'Validation Error',
        content: {
          'application/json': {
            schema: {
              $ref: {
                title: 'HTTPValidationError',
                type: 'object',
                properties: {
                  errors: {
                    title: 'Errors',
                    type: 'array',
                    items: {
                      $ref: {
                        title: 'ValidationError',
                        required: ['loc', 'msg', 'type'],
                        type: 'object',
                        properties: {
                          loc: {
                            title: 'Location',
                            type: 'array',
                            items: {
                              anyOf: [
                                {
                                  type: 'string',
                                },
                                {
                                  type: 'integer',
                                },
                              ],
                            },
                          },
                          msg: {
                            title: 'Message',
                            type: 'string',
                          },
                          type: {
                            title: 'Error Type',
                            type: 'string',
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
  {
    endpoint: '/api/v2/travelrule/transfers/{transfer_id}',
    method: 'get',
    tags: ['v2'],
    summary: 'Get Transfer Details',
    description:
      'The Get Transfer Details endpoint enables users to access comprehensive information regarding specific travel rule transfers within the system. It offers detailed insights into the transfer process, encompassing originator and beneficiary details, transaction amount, timestamps, and status.',
    operationId: 'List_all_addresses_api_v2_analytics_chains__chain__addresses_get',
    security: [
      {
        HTTPBearer: [],
      },
    ],
    parameters: [
      {
        name: 'transfer_id',
        in: 'path',
        required: false,
        schema: {
          title: 'Chain',
          description: 'The ID of the transfer',
          type: 'string',
        },
        description: 'The ID of the transfer',
        examples: '0663cc41-ef29-7995-8000-2d9b414b3174',
      },
    ],
    responses: {
      200: {
        description: 'Successful Response',
        content: {
          'application/json': {
            schema: {
              $ref: {
                title: 'TR03ResponseCom',
                type: 'object',
                properties: {
                  status: {
                    title: 'Status',
                    type: 'string',
                    default: 'success',
                    examples: 'success',
                  },
                  data: {
                    $ref: {
                      title: 'AddressSummaryResponse',
                      type: 'object',
                      examples: {
                        transfer_data: {
                          transfer_id: '018f85d7-0650-7205-a1d5-011e9587c07c',
                          dti: '4H95J0R2X',
                          amount: 1,
                          amount_usd: 63421.62,
                          originator_address: '14qViLJfdGaP4EeHnDyJbEGQysnCpwk3gd',
                          beneficiary_address: '1F1tAaz5x1HUXrCNLbtMDqcw6o5GNn4xqX',
                          transaction_hash: '80003fee6bcbb2726b4d6466d690fce6caaa03771aada747eb0651637514b893',
                          created: '2024-05-09T12:40:01.138096+00:00',
                          created_by_desc: 'John Robert  Maxwells',
                        },
                        originator_record: {
                          display_name: 'John Sean Smith',
                          reference_id: '234234',
                          account_no: 234234,
                          name_first: 'John ',
                          name_middle: 'Sean',
                          name_last: 'Smith',
                          name_local_first: null,
                          name_local_middle: null,
                          name_local_last: null,
                          date_of_birth: '1988-06-27',
                          place_of_birth: 'Singapore',
                          nationality_code: 'SG',
                          residence_country_code: 'SG',
                          name_legal: null,
                          name_business: null,
                          name_local_legal: null,
                          name_local_business: null,
                          registration_country_code: null,
                          address_line: '75 High Street',
                          address_street_no: '75',
                          address_street_name: 'High Street ',
                          address_building_name: null,
                          address_floor: null,
                          address_room: null,
                          address_department: null,
                          address_subdepartment: null,
                          address_postbox: null,
                          address_city: 'Singapore ',
                          address_city_location: 'Singapore',
                          address_region: 'Singapore',
                          address_district: null,
                          address_postcode: '179435 ',
                          address_country_code: null,
                          email: 'you@company.com',
                          national_id: 'AAE4123',
                          national_id_issue_country: 'SG',
                        },
                        beneficiary_record: {
                          display_name: 'Crypto Legal',
                          reference_id: '1234123',
                          name_legal: 'Crypto Legal',
                          name_business: 'Crypto Legal',
                          name_local_legal: null,
                          name_local_business: null,
                          registration_country_code: 'SG',
                          address_line: '111 North Bridge Road, #08-18 Peninsula Plaza',
                          address_street_no: '3124123',
                          address_street_name: 'Peninsula Plaza ',
                          address_building_name: null,
                          address_floor: null,
                          address_room: null,
                          address_department: null,
                          address_subdepartment: null,
                          address_postbox: null,
                          address_city: null,
                          address_city_location: null,
                          address_region: null,
                          address_district: null,
                          address_postcode: '179098 ',
                          address_country_code: 'SG',
                          email: 'Test@test.com',
                          // national_id: null,
                          // national_id_issue_country: null,
                        },
                        originator_vasp: {
                          vasp_id: '062e956f-1474-726f-8000-a56ebb70eaef',
                          name_legal: 'Kraken',
                          name_business: 'Kraken',
                          name_local_legal: null,
                          name_local_business: null,
                          registration_country_code: 'US',
                          address_type_code: null,
                          address_line: '1009 N 1300th W',
                          address_street_no: null,
                          address_street_name: '1009 N 1300th W',
                          address_building_name: null,
                          address_floor: null,
                          address_room: null,
                          address_department: null,
                          address_subdepartment: null,
                          address_postbox: null,
                          address_city: 'Pleasant Grove',
                          address_city_location: null,
                          address_region: 'Utah',
                          address_district: null,
                          address_postcode: '84062',
                          address_country_code: 'US',
                          address_country_desc: 'United States',
                          email: 'dummy@kraken.com',
                          website: 'https://www.kraken.com/',
                          // national_id: '984500147ACBF977BK14',
                          // national_id_registration_authority: 'Ubisecure Oy (Ubisecure RapidLEI)',
                        },
                        beneficiary_vasp: {
                          vasp_id: '062f2fd7-ce79-7bc5-8000-1761ee98a027',
                          name_legal: 'Amber Group',
                          name_business: 'Amber Group',
                          name_local_legal: null,
                          name_local_business: null,
                          registration_country_code: null,
                          address_type_code: null,
                          address_line: null,
                          address_street_no: null,
                          address_street_name: null,
                          address_building_name: null,
                          address_floor: null,
                          address_room: null,
                          address_department: null,
                          address_subdepartment: null,
                          address_postbox: null,
                          address_city: null,
                          address_city_location: null,
                          address_region: null,
                          address_district: null,
                          address_postcode: null,
                          address_country_code: 'HK',
                          address_country_desc: 'Hong Kong',
                          email: 'dummy@ambergroup.io',
                          website: 'www.ambergroup.io',
                          // national_id: '254900ZC79NMF1CH7V65',
                          // national_id_registration_authority: 'Bloomberg Finance L.P.',
                        },
                      },
                    },
                  },
                  message: {
                    title: 'Message',
                    type: 'string',
                    examples: null,
                  },
                },
              },
            },
          },
        },
      },
      422: {
        description: 'Validation Error',
        content: {
          'application/json': {
            schema: {
              $ref: {
                title: 'HTTPValidationError',
                type: 'object',
                properties: {
                  errors: {
                    title: 'Errors',
                    type: 'array',
                    items: {
                      $ref: {
                        title: 'ValidationError',
                        required: ['loc', 'msg', 'type'],
                        type: 'object',
                        properties: {
                          loc: {
                            title: 'Location',
                            type: 'array',
                            items: {
                              anyOf: [
                                {
                                  type: 'string',
                                },
                                {
                                  type: 'integer',
                                },
                              ],
                            },
                          },
                          msg: {
                            title: 'Message',
                            type: 'string',
                          },
                          type: {
                            title: 'Error Type',
                            type: 'string',
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
  {
    endpoint: '/api/v2/travelrule/transfers/{transfer_id}',
    method: 'patch',
    tags: ['v2'],
    summary: 'Update Transfer',
    description:
      'The "Update Transfer" endpoint allows users to update the details of an existing transfer transaction.',
    operationId: 'List_all_addresses_api_v2_analytics_chains__chain__addresses_get',
    security: [
      {
        HTTPBearer: [],
      },
    ],
    requestBody: {
      content: {
        'application/json': {
          schema: {
            $ref: {
              title: 'TransferStartInput',
              required: [
                'amount',
                'dti',
                'protocol',
                'beneficiary_vasp_id',
                'originator_address',
                'beneficiary_address',
                // 'originator_account_reference_id',
                // 'beneficiary_account_reference_id',
                // 'transaction_hash',
                // 'reference_id',
              ],
              type: 'object',
              properties: {
                // amount: {
                //   title: 'Amount',
                //   description: 'Quantity of currency/token transferred in the transaction.',
                //   type: 'number',
                //   examples: 1,
                // },
                // dti: {
                //   title: 'Asset Code',
                //   description: 'Unique identifier for the assets based on DTIF standards (see https://dtif.org)',
                //   type: 'string',
                //   examples: '4H95J0R2X',
                // },
                // protocol: {
                //   title: 'Protocol',
                //   description: 'Travel Rule protocol.',
                //   type: 'string',
                //   examples: ['trp', 'gtr', 'flow', 'trisa'],
                // },
                // beneficiary_vasp_id: {
                //   title: 'Beneficiary Vasp Id',
                //   description: 'ID of the receiving Virtual Asset Service Provider (VASP).',
                //   type: 'string',
                //   format: 'uuid',
                //   examples: '“VASP12345”',
                // },
                // originator_address: {
                //   title: 'Originator Hash Address',
                //   description: 'Blockchain address of the sender.',
                //   type: 'string',
                //   examples: '12higDjoCCNXSA95xZMWUdPvXNmkAduhWv',
                // },
                // beneficiary_address: {
                //   title: 'Beneficiary Hash Address',
                //   description: 'Blockchain address of the recipient.',
                //   type: 'string',
                //   examples: 'bc1qas2rvpejpvncd6z5hcscvw52n4wxw5th2de67v',
                // },
                // originator_account_reference_id: {
                //   title: 'Originator Account ID',
                //   description: "Sender's account ID within the originator VASP.",
                //   type: 'string',
                //   examples: 'account5678',
                // },
                // beneficiary_account_reference_id: {
                //   title: 'Beneficiary Account Id',
                //   description: "Recipient's account ID within the originator VASP.",
                //   type: 'string',
                //   examples: 'account1234',
                // },
                transaction_hash: {
                  title: 'Transaction Hash',
                  description: 'Unique ID of the blockchain transaction.',
                  type: 'string',
                  format: 'uuid',
                  examples: '80003fee6bcbb2726b4d6466d690fce6caaa03771aada747eb0651637514b893',
                },
                // reference_id: {
                //   title: 'Reference Id',
                //   description: 'Unique identifier for the transfer.',
                //   type: 'string',
                //   format: 'uuid',
                //   examples: 'transfer7890',
                // },
              },
              description: 'POST /travelrule/travelrule/start',
            },
          },
        },
      },
      required: true,
    },
    parameters: [
      {
        name: 'transfer_id',
        in: 'path',
        required: true,
        schema: {
          title: 'Chain',
          description: 'The ID of the transfer',
          type: 'string',
        },
        description: 'The ID of the transfer',
        examples: '0663cc41-ef29-7995-8000-2d9b414b3174',
      },
    ],
    responses: {
      200: {
        description: 'Successful Response',
        content: {
          'application/json': {
            schema: {
              $ref: {
                title: 'TR03ResponseCom',
                type: 'object',
                properties: {
                  status: {
                    title: 'Status',
                    type: 'string',
                    default: 'success',
                    examples: 'success',
                  },
                  data: {
                    $ref: {
                      title: 'AddressSummaryResponse',
                      type: 'object',
                      examples: {
                        transfer_id: '0663cc41-ef29-7995-8000-2d9b414b3174',
                        amount: 1,
                        amount_usd: 63421.62,
                        asset_code: '4H95J0R2X',
                        protocol: 'flow',
                        beneficiary_vasp_id: '062f2fd7-ce79-7bc5-8000-1761ee98a027',
                        originator_address: '14qViLJfdGaP4EeHnDyJbEGQysnCpwk3gd',
                        beneficiary_address: '1F1tAaz5x1HUXrCNLbtMDqcw6o5GNn4xqX',
                        originator_account_reference_id: 'account5678',
                        beneficiary_account_reference_id: 'account1234',
                        transaction_hash: '9cc9035c514dc18d364272691fbd7d2820b1de8de16a2c6680cd5b50c4905f4c',
                        transfer_status: 'initiated',
                      },
                    },
                  },
                  message: {
                    title: 'Message',
                    type: 'string',
                    examples: null,
                  },
                },
              },
            },
          },
        },
      },
      422: {
        description: 'Validation Error',
        content: {
          'application/json': {
            schema: {
              $ref: {
                title: 'HTTPValidationError',
                type: 'object',
                properties: {
                  errors: {
                    title: 'Errors',
                    type: 'array',
                    items: {
                      $ref: {
                        title: 'ValidationError',
                        required: ['loc', 'msg', 'type'],
                        type: 'object',
                        properties: {
                          loc: {
                            title: 'Location',
                            type: 'array',
                            items: {
                              anyOf: [
                                {
                                  type: 'string',
                                },
                                {
                                  type: 'integer',
                                },
                              ],
                            },
                          },
                          msg: {
                            title: 'Message',
                            type: 'string',
                          },
                          type: {
                            title: 'Error Type',
                            type: 'string',
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
  {
    endpoint: '/api/v2/travelrule/transfers/{transfer_id}/{action}',
    method: 'patch',
    tags: ['v2'],
    summary: 'Action Transfer',
    description: 'This endpoint is used to change the state of a transfer.',
    operationId: 'List_all_addresses_api_v2_analytics_chains__chain__addresses_get',
    security: [
      {
        HTTPBearer: [],
      },
    ],
    parameters: [
      {
        name: 'transfer_id',
        in: 'path',
        required: true,
        schema: {
          title: 'Chain',
          description: 'The ID of the transfer',
          type: 'string',
        },
        description: 'The ID of the transfer',
        examples: '0663cc41-ef29-7995-8000-2d9b414b3174',
      },
      {
        name: 'action',
        in: 'path',
        required: true,
        schema: {
          title: 'Chain',
          description: 'The action to be applied',
          type: 'string',
        },
        description: 'The action to be applied',
        examples: ['approve', 'reject', 'cancel'],
      },
    ],
    responses: {
      200: {
        description: 'Successful Response',
        content: {
          'application/json': {
            schema: {
              $ref: {
                title: 'TR03ResponseCom',
                type: 'object',
                properties: {
                  status: {
                    title: 'Status',
                    type: 'string',
                    default: 'success',
                    examples: 'success',
                  },
                  data: {
                    $ref: {
                      title: 'AddressSummaryResponse',
                      type: 'object',
                      examples: {
                        transfer_id: '0663cc41-ef29-7995-8000-2d9b414b3174',
                        amount: 1,
                        amount_usd: 63421.62,
                        asset_code: '4H95J0R2X',
                        protocol: 'flow',
                        beneficiary_vasp_id: '062f2fd7-ce79-7bc5-8000-1761ee98a027',
                        originator_address: '14qViLJfdGaP4EeHnDyJbEGQysnCpwk3gd',
                        beneficiary_address: '1F1tAaz5x1HUXrCNLbtMDqcw6o5GNn4xqX',
                        originator_account_reference_id: 'account5678',
                        beneficiary_account_reference_id: 'account1234',
                        transaction_hash: '80003fee6bcbb2726b4d6466d690fce6caaa03771aada747eb0651637514b893',
                        transfer_status: 'approved',
                      },
                    },
                  },
                  message: {
                    title: 'Message',
                    type: 'string',
                    examples: null,
                  },
                },
              },
            },
          },
        },
      },
      422: {
        description: 'Validation Error',
        content: {
          'application/json': {
            schema: {
              $ref: {
                title: 'HTTPValidationError',
                type: 'object',
                properties: {
                  errors: {
                    title: 'Errors',
                    type: 'array',
                    items: {
                      $ref: {
                        title: 'ValidationError',
                        required: ['loc', 'msg', 'type'],
                        type: 'object',
                        properties: {
                          loc: {
                            title: 'Location',
                            type: 'array',
                            items: {
                              anyOf: [
                                {
                                  type: 'string',
                                },
                                {
                                  type: 'integer',
                                },
                              ],
                            },
                          },
                          msg: {
                            title: 'Message',
                            type: 'string',
                          },
                          type: {
                            title: 'Error Type',
                            type: 'string',
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
]

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import ReactJson from 'react-json-view'

// Views
import { DocumentationSubHeader } from 'views/layouts'

// Styled Elements
import { Wrapper, Container } from './SampleBodyCard.elements'

function SampleBodyCard(props) {
  // Destructure
  const { data } = props
  // States
  const [headers, setHeaders] = useState()
  const [activeHeader, setActiveHeader] = useState('')
  const [activeSample, setActiveSample] = useState(0)

  // UseEffects
  useEffect(() => {
    if (data?.length > 0) {
      setHeaders(
        data.map((item, i) => ({
          name: `Sample ${i + 1}`,
          action: () => {
            setActiveHeader(`Sample ${i + 1}`)
            setActiveSample(i)
          },
        }))
      )
      setActiveHeader('Sample 1')
    }
  }, [])

  return (
    <Wrapper>
      <DocumentationSubHeader headers={headers} activeHeader={activeHeader} />
      <Container>
        {data?.length > 0 && (
          <ReactJson
            displayDataTypes={false}
            displayObjectSize={false}
            style={{ background: 'transparent', fontSize: 12, wordBreak: 'break-word' }}
            theme="monokai"
            name={false}
            indentWidth={2}
            src={data[activeSample]}
          />
        )}
      </Container>
    </Wrapper>
  )
}

// Default Props
SampleBodyCard.defaultProps = {
  data: '',
}

// Proptypes Validation
SampleBodyCard.propTypes = {
  data: PropTypes.arrayOf(),
}

export default SampleBodyCard

/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react'
import PropTypes from 'prop-types'

// Styled Elements
import { Wrapper, Container, RowWrapper, Text, GridContainer, Value } from './QueryTableItemDetails.elements'

function QueryTableItemDetails(props) {
  const { data } = props
  const { schema } = data
  return (
    <Wrapper>
      {(schema?.minimum || schema?.maximum || schema?.default) && (
        <GridContainer>
          {schema?.minimum && (
            <Container>
              <RowWrapper>
                <Text>Minimum: </Text>
                <Value>{schema?.minimum}</Value>
              </RowWrapper>
            </Container>
          )}
          {schema?.maximum && (
            <Container>
              <RowWrapper>
                <Text>Maximum: </Text>
                <Value>{schema?.maximum}</Value>
              </RowWrapper>
            </Container>
          )}
          {schema?.default && (
            <Container>
              <RowWrapper>
                <Text>Default: </Text>
                <Value>{schema?.default}</Value>
              </RowWrapper>
            </Container>
          )}
        </GridContainer>
      )}
      {schema?.$ref?.enum && (
        <Container>
          <RowWrapper>
            <Text>Accepted Values: </Text>
            <Value>{schema?.$ref?.enum?.join(', ')}</Value>
          </RowWrapper>
        </Container>
      )}
      {schema?.$ref?.description && (
        <Container>
          <Text>Description: </Text>
          <Value>{schema?.$ref?.description}</Value>
        </Container>
      )}
      {schema?.description && (
        <Container>
          <Text>Description: </Text>
          <Value>{schema?.description}</Value>
        </Container>
      )}
      {schema?.examples && (
        <>
          {Array.isArray(schema?.examples) ? (
            <Container>
              <Text>Examples: </Text>
              <Value>
                {schema?.examples?.map((item, i) => {
                  if (i === 0) return item
                  return `, ${item}`
                })}
              </Value>
            </Container>
          ) : (
            <Container>
              <Text>Example: </Text>
              <Value>{schema?.examples}</Value>
            </Container>
          )}
        </>
      )}
      {data?.examples && (
        <>
          {Array.isArray(data?.examples) ? (
            <Container>
              <Text>Examples: </Text>
              <Value>
                {data?.examples?.map((item, i) => {
                  if (i === 0) return item
                  return `, ${item}`
                })}
              </Value>
            </Container>
          ) : (
            <Container>
              <Text>Example: </Text>
              <Value>{data?.examples}</Value>
            </Container>
          )}
        </>
      )}
      {schema?.$ref?.examples && (
        <>
          {Array.isArray(schema?.$ref?.examples) ? (
            <Container>
              <Text>Examples: </Text>
              <Value>
                {schema?.$ref?.examples?.map((item, i) => {
                  if (i === 0) return item
                  return `, ${item}`
                })}
              </Value>
            </Container>
          ) : (
            <Container>
              <Text>Example: </Text>
              <Value>{schema?.$ref?.examples}</Value>
            </Container>
          )}
        </>
      )}
    </Wrapper>
  )
}

// Default Props
QueryTableItemDetails.defaultProps = {
  data: {},
}

// Proptypes Validation
QueryTableItemDetails.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    required: PropTypes.bool,
    description: PropTypes.string,
    examples: PropTypes.string,
    schema: PropTypes.shape({
      type: PropTypes.string,
      $ref: PropTypes.shape({
        type: PropTypes.string,
        description: PropTypes.string,
        enum: PropTypes.arrayOf(),
        examples: PropTypes.string,
      }),
      examples: PropTypes.string,
      minimum: PropTypes.number,
      maximum: PropTypes.number,
      default: PropTypes.string,
      description: PropTypes.string,
    }),
    $ref: PropTypes.shape({
      required: PropTypes.bool,
      description: PropTypes.string,
    }),
  }),
}

export default QueryTableItemDetails

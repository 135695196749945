/* eslint-disable max-len */
// !LIST ACCOUNT ENDPOINT NEEDS MANUALD DESCRIPTION INPUT FOR PARAMS
export const DirectoryData = [
  {
    endpoint: '/api/v2/directory/accounts',
    method: 'post',
    tags: ['v2'],
    summary: 'Create Account',
    description:
      "This endpoint is used to create a new account within the directory module. It can create 'individual' or 'entity' accounts.",
    operationId: 'Create_Account_api_v2_directory_accounts_post',
    security: [
      {
        HTTPBearer: [],
      },
    ],
    requestBody: {
      required: true,
      content: {
        'application/json': {
          schema: {
            $ref: {
              title: 'AccountDetailsCommercial',
              required: ['reference_id', 'account_type'],
              type: 'object',
              properties: {
                reference_id: {
                  title: 'Reference Id',
                  maxLength: 50,
                  type: 'string',
                  description: 'Unique identifier for the account.',
                  examples: 'johndoe',
                },
                account_type: {
                  allOf: [
                    {
                      $ref: {
                        title: 'AccountTypeString',
                        enum: ['individual', 'entity'],
                        type: 'string',
                        description: 'An enumeration.',
                      },
                    },
                  ],
                  description: "Type of account, either 'individual' or 'entity'.",
                  examples: ['individual', 'entity'],
                },
                email: {
                  title: 'Email',
                  maxLength: 50,
                  type: 'string',
                  description: 'Email address associated with the account.',
                  examples: 'you@company.com',
                },
                individual: {
                  title: 'Individual',
                  allOf: [
                    {
                      $ref: {
                        title: 'AccountIndividual',
                        required: ['individual_name'],
                        type: 'object',
                        properties: {
                          individual_name: {
                            title: 'Individual Name',
                            allOf: [
                              {
                                $ref: {
                                  title: 'IndividualName',
                                  required: ['name_type', 'name_last'],
                                  type: 'object',
                                  properties: {
                                    name_type: {
                                      allOf: [
                                        {
                                          $ref: {
                                            title: 'NameTypeString',
                                            enum: ['LEGL'],
                                            type: 'string',
                                            description: 'An enumeration.',
                                          },
                                        },
                                      ],
                                      description:
                                        'Type of the name. Required if entity_name or entity_localname or individual_name or individual_local_name is specified',
                                      requirement: 'Conditional',
                                      examples: 'LEGL',
                                    },
                                    name_first: {
                                      title: 'Name First',
                                      maxLength: 50,
                                      type: 'string',
                                      description: 'First name of the account holder.',
                                      examples: 'John',
                                    },
                                    name_middle: {
                                      title: 'Name Middle',
                                      maxLength: 50,
                                      type: 'string',
                                      description: 'Middle name of the account holder.',
                                      examples: 'Robert',
                                    },
                                    name_last: {
                                      title: 'Name Last',
                                      maxLength: 50,
                                      type: 'string',
                                      description:
                                        'Last name of the account holder. Required if account_type is individual.',
                                      examples: 'Doe',
                                    },
                                  },
                                },
                              },
                            ],
                            description: 'Object that contains the individual name fields',
                            requirement: 'Conditional',
                          },
                          individual_localname: {
                            title: 'Individual Localname',
                            allOf: [
                              {
                                $ref: {
                                  title: 'IndividualName',
                                  required: ['name_type', 'name_last'],
                                  type: 'object',
                                  properties: {
                                    name_type: {
                                      allOf: [
                                        {
                                          $ref: {
                                            title: 'NameTypeString',
                                            enum: ['LEGL'],
                                            type: 'string',
                                            description: 'An enumeration.',
                                          },
                                        },
                                      ],
                                      description:
                                        'Type of the name. Required if entity_name or entity_localname or individual_name or individual_local_name is specified',
                                      requirement: 'Conditional',
                                      examples: 'LEGL',
                                    },
                                    name_first: {
                                      title: 'Name First',
                                      maxLength: 50,
                                      type: 'string',
                                      description: 'First name of the account holder.',
                                      examples: 'John',
                                    },
                                    name_middle: {
                                      title: 'Name Middle',
                                      maxLength: 50,
                                      type: 'string',
                                      description: 'Middle name of the account holder.',
                                      examples: 'Robert',
                                    },
                                    name_last: {
                                      title: 'Name Last',
                                      maxLength: 50,
                                      type: 'string',
                                      description:
                                        'Last name of the account holder. Required if account_type is individual.',
                                      examples: 'Doe',
                                    },
                                  },
                                },
                              },
                            ],
                            description: 'Object that contains the individual local name fields',
                            requirement: 'Conditional',
                          },
                          date_of_birth: {
                            title: 'Date Of Birth',
                            type: 'string',
                            description: 'Date of birth of the account holder.',
                            format: 'date',
                            examples: '1990-01-01',
                          },
                          place_of_birth: {
                            title: 'Place Of Birth',
                            maxLength: 50,
                            type: 'string',
                            description: 'Place of birth of the account holder.',
                            examples: 'Sinagpore, Singapore',
                          },
                          nationality_country: {
                            title: 'Nationality Country',
                            maxLength: 2,
                            type: 'string',
                            description: 'Country code of the nationality of the account holder.',
                            examples: 'SG',
                          },
                          residence_country: {
                            title: 'Residence Country',
                            maxLength: 2,
                            type: 'string',
                            description: 'Country code of the place of residence of the account holder.',
                            examples: 'US',
                          },
                        },
                      },
                    },
                  ],
                  description: 'The object that contains the fields specific for an individual account',
                },
                entity: {
                  title: 'Entity',
                  allOf: [
                    {
                      $ref: {
                        title: 'AccountEntity',
                        required: ['entity_name'],
                        type: 'object',
                        properties: {
                          entity_name: {
                            title: 'Entity Name',
                            allOf: [
                              {
                                $ref: {
                                  title: 'EntityName',
                                  required: ['name_type', 'name_legal'],
                                  type: 'object',
                                  properties: {
                                    name_type: {
                                      allOf: [
                                        {
                                          $ref: {
                                            title: 'NameTypeString',
                                            enum: ['LEGL'],
                                            type: 'string',
                                            description: 'An enumeration.',
                                          },
                                        },
                                      ],
                                      description:
                                        'Type of the name. Required if entity_name or entity_localname or individual_name or individual_local_name is specified',
                                      requirement: 'Conditional',
                                      examples: 'LEGL',
                                    },
                                    name_legal: {
                                      title: 'Name Legal',
                                      maxLength: 100,
                                      type: 'string',
                                      description: 'Name of the business.  Required if account_type is entity.',
                                      requirement: 'Conditional',
                                      examples: 'Digital Capital',
                                    },
                                    name_business: {
                                      title: 'Name Business',
                                      maxLength: 100,
                                      type: 'string',
                                      description: 'Legal name of the business.',
                                      examples: 'Digital Capital Pte. Ltd.',
                                    },
                                  },
                                },
                              },
                            ],
                            description: 'The object that contains the entity name fields.',
                          },
                          entity_localname: {
                            title: 'Entity Localname',
                            allOf: [
                              {
                                $ref: {
                                  title: 'EntityName',
                                  required: ['name_type', 'name_legal'],
                                  type: 'object',
                                  properties: {
                                    name_type: {
                                      allOf: [
                                        {
                                          $ref: {
                                            title: 'NameTypeString',
                                            enum: ['LEGL'],
                                            type: 'string',
                                            description: 'An enumeration.',
                                          },
                                        },
                                      ],
                                      description:
                                        'Type of the name. Required if entity_name or entity_localname or individual_name or individual_local_name is specified',
                                      requirement: 'Conditional',
                                      examples: 'LEGL',
                                    },
                                    name_legal: {
                                      title: 'Name Legal',
                                      maxLength: 100,
                                      type: 'string',
                                      description: 'Name of the business.  Required if account_type is entity.',
                                      requirement: 'Conditional',
                                      examples: 'Digital Capital',
                                    },
                                    name_business: {
                                      title: 'Name Business',
                                      maxLength: 100,
                                      type: 'string',
                                      description: 'Legal name of the business.',
                                      examples: 'Digital Capital Pte. Ltd.',
                                    },
                                  },
                                },
                              },
                            ],
                            description: 'The object that contains the entity local name fields.',
                          },
                          registration_country: {
                            title: 'Registration Country',
                            maxLength: 2,
                            type: 'string',
                            description: 'Country code of incorporation for the business.',
                            examples: 'SG',
                          },
                        },
                      },
                    },
                  ],
                  description: 'The object that contains the fields specific for an entity account',
                },
                address: {
                  title: 'Address',
                  allOf: [
                    {
                      $ref: {
                        title: 'Address',
                        required: ['address_type'],
                        type: 'object',
                        properties: {
                          address_type: {
                            allOf: [
                              {
                                $ref: {
                                  title: 'AddressTypeString',
                                  enum: ['GEOG'],
                                  type: 'string',
                                  description: 'An enumeration.',
                                },
                              },
                            ],
                            description: 'Required if address object is specified. Type of the address.',
                            requirement: 'Conditional',
                            examples: 'GEOG',
                          },
                          address_line: {
                            title: 'Address Line',
                            maxLength: 256,
                            type: 'string',
                            description:
                              'Either providing an address_line or including a combination of address_street_no or address_building_name along with address_street_name is required.',
                            requirement: 'Conditional',
                            examples: 'Apt 123',
                          },
                          address_street_no: {
                            title: 'Address Street No',
                            maxLength: 10,
                            type: 'string',
                            description:
                              'Either providing an address_line or including a combination of address_street_no or address_building_name along with address_street_name is required',
                            requirement: 'Conditional',
                            examples: '456',
                          },
                          address_street_name: {
                            title: 'Address Street Name',
                            maxLength: 100,
                            type: 'string',
                            description:
                              'Either providing an address_line or including a combination of address_street_no or address_building_name along with address_street_name is required.',
                            requirement: 'Conditional',
                            examples: 'Elm Street',
                          },
                          address_building_name: {
                            title: 'Address Building Name',
                            maxLength: 20,
                            type: 'string',
                            description:
                              'Either providing an address_line or including a combination of address_street_no or address_building_name along with address_street is required.',
                            requirement: 'Conditional',
                            examples: 'Elmwood Apartments',
                          },
                          address_floor: {
                            title: 'Address Floor',
                            maxLength: 20,
                            type: 'string',
                            description: 'Floor number in the building.',
                            examples: '15',
                          },
                          address_room: {
                            title: 'Address Room',
                            maxLength: 20,
                            type: 'string',
                            description: 'Room number in the building.',
                            examples: '1503',
                          },
                          address_department: {
                            title: 'Address Department',
                            maxLength: 20,
                            type: 'string',
                            description: 'Department within the address.',
                          },
                          address_subdepartment: {
                            title: 'Address Subdepartment',
                            maxLength: 20,
                            type: 'string',
                            description: 'Sub-department within the address.',
                          },
                          address_postbox: {
                            title: 'Address Postbox',
                            maxLength: 20,
                            type: 'string',
                            description: 'Post office box number.',
                            examples: 'PO Box 789',
                          },
                          address_city_location: {
                            title: 'Address City Location',
                            maxLength: 50,
                            type: 'string',
                            description: 'Geographical location of the city.',
                            examples: '',
                          },
                          address_city: {
                            title: 'Address City',
                            maxLength: 50,
                            type: 'string',
                            description: "City of the account holder's address.",
                            examples: 'New York',
                          },
                          address_region: {
                            title: 'Address Region',
                            maxLength: 50,
                            type: 'string',
                            description: "Region or state of the account holder's address.",
                            examples: 'New York',
                          },
                          address_district: {
                            title: 'Address District',
                            maxLength: 50,
                            type: 'string',
                            description: "District of the account holder's address.",
                            examples: 'Manhattan',
                          },
                          address_postcode: {
                            title: 'Address Postcode',
                            maxLength: 25,
                            type: 'string',
                            description: "Postal code of the account holder's address.",
                            examples: '08012',
                          },
                          address_country_code: {
                            title: 'Address Country Code',
                            maxLength: 2,
                            type: 'string',
                            description: "Country code of the account holder's address.",
                            examples: 'US',
                          },
                        },
                      },
                    },
                  ],
                  description: 'The object that contains the fields specific for the address of the account',
                },
                national_id: {
                  title: 'National Id',
                  allOf: [
                    {
                      $ref: {
                        title: 'NationalId',
                        required: ['national_id_type', 'national_id_no'],
                        type: 'object',
                        properties: {
                          national_id_type: {
                            allOf: [
                              {
                                $ref: {
                                  title: 'NationalIdTypeString',
                                  enum: [
                                    'ARNU',
                                    'CCPT',
                                    'RAID',
                                    'DRLC',
                                    'FIIN',
                                    'TXID',
                                    'SOCS',
                                    'IDCD',
                                    'LEIX',
                                    'MISC',
                                  ],
                                  type: 'string',
                                  description: 'An enumeration.',
                                },
                              },
                            ],
                            description:
                              'Required when national_id object is specified. Type of national identification.',
                            requirement: 'Conditional',
                            examples: ['ARNU', 'CCPT', 'RAID', 'DRLC', 'FIIN', 'TXID', 'SOCS', 'IDCD', 'LEIX', 'MISC'],
                          },
                          national_id_no: {
                            title: 'National Id No',
                            maxLength: 35,
                            type: 'string',
                            description: 'National identification number.',
                            examples: 'ABC123456',
                          },
                          issue_country: {
                            title: 'Issue Country',
                            maxLength: 2,
                            type: 'string',
                            description: 'Country that issued the national identification.',
                            examples: 'US',
                          },
                        },
                      },
                    },
                  ],
                  description: 'The object that contains the fields specific for the national id of the account',
                },
                created: {
                  title: 'Created',
                  type: 'string',
                  format: 'date-time',
                  tag: 'hide',
                },
                last_modified: {
                  title: 'Last Modified',
                  type: 'string',
                  format: 'date-time',
                  tag: 'hide',
                },
              },
            },
          },
          examples: [
            {
              reference_id: 'johndoe',
              account_type: 'individual',
              email: 'you@company.com',
              individual: {
                date_of_birth: '1988-06-27',
                place_of_birth: 'Singapore, Singapore',
                individual_name: {
                  name_type: 'LEGL',
                  name_first: 'John',
                  name_middle: 'Mark',
                  name_last: 'Doe',
                },
                individual_localname: {
                  name_type: 'LEGL',
                  name_first: 'John',
                  name_middle: 'Mark',
                  name_last: 'Doe',
                },
              },
              address: {
                address_type: 'GEOG',
                address_line: 'Apt 123',
                address_street_no: '456',
                address_street_name: 'Elm Street',
                address_building_name: 'Elmwood Apartments',
                address_floor: '15',
                address_room: '1503',
                address_postbox: 'PO Box 789',
                address_city: 'New York',
                address_region: 'New York',
                address_district: 'Manhattan',
                address_postcode: '10001',
                address_country_code: 'US',
              },
            },
            {
              reference_id: 'dcLtd',
              account_type: 'entity',
              email: 'you@company.com',
              entity: {
                registration_country: 'SG',
                entity_name: {
                  name_type: 'LEGL',
                  name_legal: 'Digital Capital',
                  name_business: 'Digital Capital Pte. Ltd.',
                },
                entity_localname: {
                  name_type: 'LEGL',
                  name_legal: 'Digital Capital',
                  name_business: 'Digital Capital Pte. Ltd.',
                },
              },
              address: {
                address_type: 'GEOG',
                address_line: 'Apt 123',
                address_street_no: '456',
                address_street_name: 'Elm Street',
                address_building_name: 'Elmwood Apartments',
                address_floor: '15',
                address_room: '1503',
                address_postbox: 'PO Box 789',
                address_city: 'New York',
                address_region: 'New York',
                address_district: 'Manhattan',
                address_postcode: '10001',
                address_country_code: 'US',
              },
            },
          ],
        },
      },
    },
    responses: {
      200: {
        description: 'Successful Response',
        content: {
          'application/json': {
            schema: {
              $ref: {
                title: 'DM01ResponseCom',
                type: 'object',
                properties: {
                  status: {
                    title: 'Status',
                    type: 'string',
                    default: 'success',
                    examples: 'success',
                  },
                  data: {
                    title: 'Data',
                    allOf: [
                      {
                        $ref: {
                          title: 'AccountDetailsCommercial',
                          required: ['reference_id', 'account_type'],
                          type: 'object',
                          properties: {
                            reference_id: {
                              title: 'Reference Id',
                              maxLength: 50,
                              type: 'string',
                              description: 'Unique identifier for the account.',
                              examples: 'johndoe',
                            },
                            account_type: {
                              allOf: [
                                {
                                  $ref: {
                                    title: 'AccountTypeString',
                                    enum: ['individual', 'entity'],
                                    type: 'string',
                                    description: 'An enumeration.',
                                  },
                                },
                              ],
                              description: "Type of account, either 'individual' or 'entity'.",
                              examples: ['individual', 'entity'],
                            },
                            email: {
                              title: 'Email',
                              maxLength: 50,
                              type: 'string',
                              description: 'Email address associated with the account.',
                              examples: 'you@company.com',
                            },
                            individual: {
                              title: 'Individual',
                              allOf: [
                                {
                                  $ref: {
                                    title: 'AccountIndividual',
                                    required: ['individual_name'],
                                    type: 'object',
                                    properties: {
                                      individual_name: {
                                        title: 'Individual Name',
                                        allOf: [
                                          {
                                            $ref: {
                                              title: 'IndividualName',
                                              required: ['name_type', 'name_last'],
                                              type: 'object',
                                              properties: {
                                                name_type: {
                                                  allOf: [
                                                    {
                                                      $ref: {
                                                        title: 'NameTypeString',
                                                        enum: ['LEGL'],
                                                        type: 'string',
                                                        description: 'An enumeration.',
                                                      },
                                                    },
                                                  ],
                                                  description:
                                                    'Type of the name. Required if entity_name or entity_localname or individual_name or individual_local_name is specified',
                                                  requirement: 'Conditional',
                                                  examples: 'LEGL',
                                                },
                                                name_first: {
                                                  title: 'Name First',
                                                  maxLength: 50,
                                                  type: 'string',
                                                  description: 'First name of the account holder.',
                                                  examples: 'John',
                                                },
                                                name_middle: {
                                                  title: 'Name Middle',
                                                  maxLength: 50,
                                                  type: 'string',
                                                  description: 'Middle name of the account holder.',
                                                  examples: 'Robert',
                                                },
                                                name_last: {
                                                  title: 'Name Last',
                                                  maxLength: 50,
                                                  type: 'string',
                                                  description:
                                                    'Last name of the account holder. Required if account_type is individual.',
                                                  examples: 'Doe',
                                                },
                                              },
                                            },
                                          },
                                        ],
                                        description: 'Object that contains the individual name fields',
                                        requirement: 'Conditional',
                                      },
                                      individual_localname: {
                                        title: 'Individual Localname',
                                        allOf: [
                                          {
                                            $ref: {
                                              title: 'IndividualName',
                                              required: ['name_type', 'name_last'],
                                              type: 'object',
                                              properties: {
                                                name_type: {
                                                  allOf: [
                                                    {
                                                      $ref: {
                                                        title: 'NameTypeString',
                                                        enum: ['LEGL'],
                                                        type: 'string',
                                                        description: 'An enumeration.',
                                                      },
                                                    },
                                                  ],
                                                  description:
                                                    'Type of the name. Required if entity_name or entity_localname or individual_name or individual_local_name is specified',
                                                  requirement: 'Conditional',
                                                  examples: 'LEGL',
                                                },
                                                name_first: {
                                                  title: 'Name First',
                                                  maxLength: 50,
                                                  type: 'string',
                                                  description: 'First name of the account holder.',
                                                  examples: 'John',
                                                },
                                                name_middle: {
                                                  title: 'Name Middle',
                                                  maxLength: 50,
                                                  type: 'string',
                                                  description: 'Middle name of the account holder.',
                                                  examples: 'Robert',
                                                },
                                                name_last: {
                                                  title: 'Name Last',
                                                  maxLength: 50,
                                                  type: 'string',
                                                  description:
                                                    'Last name of the account holder. Required if account_type is individual.',
                                                  examples: 'Doe',
                                                },
                                              },
                                            },
                                          },
                                        ],
                                        description: 'Object that contains the individual local name fields',
                                        requirement: 'Conditional',
                                      },
                                      date_of_birth: {
                                        title: 'Date Of Birth',
                                        type: 'string',
                                        description: 'Date of birth of the account holder.',
                                        format: 'date',
                                        examples: '1990-01-01',
                                      },
                                      place_of_birth: {
                                        title: 'Place Of Birth',
                                        maxLength: 50,
                                        type: 'string',
                                        description: 'Place of birth of the account holder.',
                                        examples: 'Sinagpore, Singapore',
                                      },
                                      nationality_country: {
                                        title: 'Nationality Country',
                                        maxLength: 2,
                                        type: 'string',
                                        description: 'Country code of the nationality of the account holder.',
                                        examples: 'SG',
                                      },
                                      residence_country: {
                                        title: 'Residence Country',
                                        maxLength: 2,
                                        type: 'string',
                                        description: 'Country code of the place of residence of the account holder.',
                                        examples: 'US',
                                      },
                                    },
                                  },
                                },
                              ],
                              description: 'The object that contains the fields specific for an individual account',
                            },
                            entity: {
                              title: 'Entity',
                              allOf: [
                                {
                                  $ref: {
                                    title: 'AccountEntity',
                                    required: ['entity_name'],
                                    type: 'object',
                                    properties: {
                                      entity_name: {
                                        title: 'Entity Name',
                                        allOf: [
                                          {
                                            $ref: {
                                              title: 'EntityName',
                                              required: ['name_type', 'name_legal'],
                                              type: 'object',
                                              properties: {
                                                name_type: {
                                                  allOf: [
                                                    {
                                                      $ref: {
                                                        title: 'NameTypeString',
                                                        enum: ['LEGL'],
                                                        type: 'string',
                                                        description: 'An enumeration.',
                                                      },
                                                    },
                                                  ],
                                                  description:
                                                    'Type of the name. Required if entity_name or entity_localname or individual_name or individual_local_name is specified',
                                                  requirement: 'Conditional',
                                                  examples: 'LEGL',
                                                },
                                                name_legal: {
                                                  title: 'Name Legal',
                                                  maxLength: 100,
                                                  type: 'string',
                                                  description:
                                                    'Name of the business.  Required if account_type is entity.',
                                                  requirement: 'Conditional',
                                                  examples: 'Digital Capital',
                                                },
                                                name_business: {
                                                  title: 'Name Business',
                                                  maxLength: 100,
                                                  type: 'string',
                                                  description: 'Legal name of the business.',
                                                  examples: 'Digital Capital Pte. Ltd.',
                                                },
                                              },
                                            },
                                          },
                                        ],
                                        description: 'The object that contains the entity name fields.',
                                      },
                                      entity_localname: {
                                        title: 'Entity Localname',
                                        allOf: [
                                          {
                                            $ref: {
                                              title: 'EntityName',
                                              required: ['name_type', 'name_legal'],
                                              type: 'object',
                                              properties: {
                                                name_type: {
                                                  allOf: [
                                                    {
                                                      $ref: {
                                                        title: 'NameTypeString',
                                                        enum: ['LEGL'],
                                                        type: 'string',
                                                        description: 'An enumeration.',
                                                      },
                                                    },
                                                  ],
                                                  description:
                                                    'Type of the name. Required if entity_name or entity_localname or individual_name or individual_local_name is specified',
                                                  requirement: 'Conditional',
                                                  examples: 'LEGL',
                                                },
                                                name_legal: {
                                                  title: 'Name Legal',
                                                  maxLength: 100,
                                                  type: 'string',
                                                  description:
                                                    'Name of the business.  Required if account_type is entity.',
                                                  requirement: 'Conditional',
                                                  examples: 'Digital Capital',
                                                },
                                                name_business: {
                                                  title: 'Name Business',
                                                  maxLength: 100,
                                                  type: 'string',
                                                  description: 'Legal name of the business.',
                                                  examples: 'Digital Capital Pte. Ltd.',
                                                },
                                              },
                                            },
                                          },
                                        ],
                                        description: 'The object that contains the entity local name fields.',
                                      },
                                      registration_country: {
                                        title: 'Registration Country',
                                        maxLength: 2,
                                        type: 'string',
                                        description: 'Country code of incorporation for the business.',
                                        examples: 'SG',
                                      },
                                    },
                                  },
                                },
                              ],
                              description: 'The object that contains the fields specific for an entity account',
                            },
                            address: {
                              title: 'Address',
                              allOf: [
                                {
                                  $ref: {
                                    title: 'Address',
                                    required: ['address_type'],
                                    type: 'object',
                                    properties: {
                                      address_type: {
                                        allOf: [
                                          {
                                            $ref: {
                                              title: 'AddressTypeString',
                                              enum: ['GEOG'],
                                              type: 'string',
                                              description: 'An enumeration.',
                                            },
                                          },
                                        ],
                                        description: 'Required if address object is specified. Type of the address.',
                                        requirement: 'Conditional',
                                        examples: 'GEOG',
                                      },
                                      address_line: {
                                        title: 'Address Line',
                                        maxLength: 256,
                                        type: 'string',
                                        description:
                                          'Either providing an address_line or including a combination of address_street_no or address_building_name along with address_street_name is required.',
                                        requirement: 'Conditional',
                                        examples: 'Apt 123',
                                      },
                                      address_street_no: {
                                        title: 'Address Street No',
                                        maxLength: 10,
                                        type: 'string',
                                        description:
                                          'Either providing an address_line or including a combination of address_street_no or address_building_name along with address_street_name is required',
                                        requirement: 'Conditional',
                                        examples: '456',
                                      },
                                      address_street_name: {
                                        title: 'Address Street Name',
                                        maxLength: 100,
                                        type: 'string',
                                        description:
                                          'Either providing an address_line or including a combination of address_street_no or address_building_name along with address_street_name is required.',
                                        requirement: 'Conditional',
                                        examples: 'Elm Street',
                                      },
                                      address_building_name: {
                                        title: 'Address Building Name',
                                        maxLength: 20,
                                        type: 'string',
                                        description:
                                          'Either providing an address_line or including a combination of address_street_no or address_building_name along with address_street is required.',
                                        requirement: 'Conditional',
                                        examples: 'Elmwood Apartments',
                                      },
                                      address_floor: {
                                        title: 'Address Floor',
                                        maxLength: 20,
                                        type: 'string',
                                        description: 'Floor number in the building.',
                                        examples: '15',
                                      },
                                      address_room: {
                                        title: 'Address Room',
                                        maxLength: 20,
                                        type: 'string',
                                        description: 'Room number in the building.',
                                        examples: '1503',
                                      },
                                      address_department: {
                                        title: 'Address Department',
                                        maxLength: 20,
                                        type: 'string',
                                        description: 'Department within the address.',
                                      },
                                      address_subdepartment: {
                                        title: 'Address Subdepartment',
                                        maxLength: 20,
                                        type: 'string',
                                        description: 'Sub-department within the address.',
                                      },
                                      address_postbox: {
                                        title: 'Address Postbox',
                                        maxLength: 20,
                                        type: 'string',
                                        description: 'Post office box number.',
                                        examples: 'PO Box 789',
                                      },
                                      address_city_location: {
                                        title: 'Address City Location',
                                        maxLength: 50,
                                        type: 'string',
                                        description: 'Geographical location of the city.',
                                        examples: '',
                                      },
                                      address_city: {
                                        title: 'Address City',
                                        maxLength: 50,
                                        type: 'string',
                                        description: "City of the account holder's address.",
                                        examples: 'New York',
                                      },
                                      address_region: {
                                        title: 'Address Region',
                                        maxLength: 50,
                                        type: 'string',
                                        description: "Region or state of the account holder's address.",
                                        examples: 'New York',
                                      },
                                      address_district: {
                                        title: 'Address District',
                                        maxLength: 50,
                                        type: 'string',
                                        description: "District of the account holder's address.",
                                        examples: 'Manhattan',
                                      },
                                      address_postcode: {
                                        title: 'Address Postcode',
                                        maxLength: 25,
                                        type: 'string',
                                        description: "Postal code of the account holder's address.",
                                        examples: '08012',
                                      },
                                      address_country_code: {
                                        title: 'Address Country Code',
                                        maxLength: 2,
                                        type: 'string',
                                        description: "Country code of the account holder's address.",
                                        examples: 'US',
                                      },
                                    },
                                  },
                                },
                              ],
                              description:
                                'The object that contains the fields specific for the address of the account',
                            },
                            national_id: {
                              title: 'National Id',
                              allOf: [
                                {
                                  $ref: {
                                    title: 'NationalId',
                                    required: ['national_id_type', 'national_id_no'],
                                    type: 'object',
                                    properties: {
                                      national_id_type: {
                                        allOf: [
                                          {
                                            $ref: {
                                              title: 'NationalIdTypeString',
                                              enum: [
                                                'ARNU',
                                                'CCPT',
                                                'RAID',
                                                'DRLC',
                                                'FIIN',
                                                'TXID',
                                                'SOCS',
                                                'IDCD',
                                                'LEIX',
                                                'MISC',
                                              ],
                                              type: 'string',
                                              description: 'An enumeration.',
                                            },
                                          },
                                        ],
                                        description:
                                          'Required when national_id object is specified. Type of national identification.',
                                        requirement: 'Conditional',
                                        examples: [
                                          'ARNU',
                                          'CCPT',
                                          'RAID',
                                          'DRLC',
                                          'FIIN',
                                          'TXID',
                                          'SOCS',
                                          'IDCD',
                                          'LEIX',
                                          'MISC',
                                        ],
                                      },
                                      national_id_no: {
                                        title: 'National Id No',
                                        maxLength: 35,
                                        type: 'string',
                                        description: 'National identification number.',
                                        examples: 'ABC123456',
                                      },
                                      issue_country: {
                                        title: 'Issue Country',
                                        maxLength: 2,
                                        type: 'string',
                                        description: 'Country that issued the national identification.',
                                        examples: 'US',
                                      },
                                    },
                                  },
                                },
                              ],
                              description:
                                'The object that contains the fields specific for the national id of the account',
                            },
                            created: {
                              title: 'Created',
                              type: 'string',
                              format: 'date-time',
                              tag: 'hide',
                            },
                            last_modified: {
                              title: 'Last Modified',
                              type: 'string',
                              format: 'date-time',
                              tag: 'hide',
                            },
                          },
                        },
                      },
                    ],
                    examples: {
                      reference_id: 'johndoe',
                      account_type: 'individual',
                      email: 'you@company.com',
                      individual: {
                        date_of_birth: '1988-06-27',
                        place_of_birth: 'Singapore, Singapore',
                        individual_name: {
                          name_type: 'LEGL',
                          name_first: 'John',
                          name_middle: 'Mark',
                          name_last: 'Doe',
                        },
                        individual_localname: {
                          name_type: 'LEGL',
                          name_first: 'John',
                          name_middle: 'Mark',
                          name_last: 'Doe',
                        },
                      },
                      address: {
                        address_type: 'GEOG',
                        address_line: 'Apt 123',
                        address_street_no: '456',
                        address_street_name: 'Elm Street',
                        address_building_name: 'Elmwood Apartments',
                        address_floor: '15',
                        address_room: '1503',
                        address_postbox: 'PO Box 789',
                        address_city: 'New York',
                        address_region: 'New York',
                        address_district: 'Manhattan',
                        address_postcode: '10001',
                        address_country_code: 'US',
                      },
                      created: '2024-02-26 21:57:18.000 +0800',
                      last_modified: '2024-02-27 10:37:20.000 +0800',
                    },
                  },
                  message: {
                    title: 'Message',
                    type: 'string',
                    examples: 'Null',
                  },
                },
              },
            },
          },
        },
      },
      404: {
        description: 'Not found',
      },
      422: {
        description: 'Validation Error',
        content: {
          'application/json': {
            schema: {
              $ref: {
                title: 'HTTPValidationError',
                type: 'object',
                properties: {
                  detail: {
                    title: 'Detail',
                    type: 'array',
                    items: {
                      $ref: {
                        title: 'ValidationError',
                        required: ['loc', 'msg', 'type'],
                        type: 'object',
                        properties: {
                          loc: {
                            title: 'Location',
                            type: 'array',
                            items: {
                              anyOf: [
                                {
                                  type: 'string',
                                },
                                {
                                  type: 'integer',
                                },
                              ],
                            },
                          },
                          msg: {
                            title: 'Message',
                            type: 'string',
                          },
                          type: {
                            title: 'Error Type',
                            type: 'string',
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
  {
    endpoint: '/api/v2/directory/accounts',
    method: 'get',
    tags: ['v2'],
    summary: 'List Account',
    description: 'This endpoint retrieves a list of all accounts within the directory.',
    operationId: 'List_Account_api_v2_directory_accounts_get',
    security: [
      {
        HTTPBearer: [],
      },
    ],
    parameters: [
      {
        name: 'start_range',
        in: 'query',
        required: false,
        schema: {
          title: 'Start Range',
          type: 'string',
          format: 'date',
          description: 'Starting point for specified data range.',
        },
      },
      {
        name: 'end_range',
        in: 'query',
        required: false,
        schema: {
          title: 'End Range',
          type: 'string',
          format: 'date',
          description: 'Ending point for specified data range.',
        },
      },
      {
        name: 'page',
        in: 'query',
        required: false,
        schema: {
          title: 'Page',
          default: 1,
          minimum: 1,
          type: 'integer',
          description: 'The page number',
        },
      },
      {
        name: 'size',
        in: 'query',
        required: false,
        schema: {
          title: 'Size',
          default: 50,
          minimum: 1,
          maximum: 100,
          type: 'integer',
          description: 'The page size',
        },
      },
      {
        name: 'sort',
        in: 'query',
        required: false,
        schema: {
          title: 'Sort',
          description: 'Criteria for result sorting.',
          default: '-created',
          type: 'string',
        },
        description: 'Criteria for result sorting.',
        examples: '-last_modified',
      },
    ],
    responses: {
      200: {
        description: 'Successful Response',
        content: {
          'application/json': {
            schema: {
              $ref: {
                title: 'DM02ResponseCom',
                type: 'object',
                properties: {
                  status: {
                    title: 'Status',
                    type: 'string',
                    default: 'success',
                    examples: 'success',
                  },
                  data: {
                    title: 'Data',
                    allOf: [
                      {
                        $ref: {
                          title: 'AccountSummaryResponse',
                          required: ['items', 'pagination'],
                          type: 'object',
                          properties: {
                            items: {
                              title: 'Items',
                              type: 'array',
                              items: {
                                $ref: {
                                  title: 'AccountsInSummary',
                                  required: ['reference_id', 'account_type'],
                                  type: 'object',
                                  properties: {
                                    reference_id: {
                                      title: 'Reference Id',
                                      type: 'string',
                                    },
                                    account_type: {
                                      title: 'Account Type',
                                      type: 'string',
                                    },
                                    created: {
                                      title: 'Created',
                                      type: 'string',
                                      format: 'date-time',
                                    },
                                    last_modified: {
                                      title: 'Last Modified',
                                      type: 'string',
                                      format: 'date-time',
                                    },
                                  },
                                },
                              },
                              description: 'Contains the fields specific for the address of the account',
                              examples: [
                                {
                                  reference_id: 'johndoe',
                                  account_type: 'entity',
                                  created: '2024-02-26T21:57:18',
                                  last_modified: '2024-02-27T10:37:20',
                                },
                                {
                                  reference_id: 'marybeth',
                                  account_type: 'individual',
                                  created: '2024-02-23T17:15:51',
                                  last_modified: '2024-02-23T17:15:51',
                                },
                                {
                                  reference_id: 'schmitt',
                                  account_type: 'individual',
                                  created: '2024-02-22T17:58:20',
                                  last_modified: '2024-02-22T17:58:20',
                                },
                                {
                                  reference_id: 'eliza',
                                  account_type: 'entity',
                                  created: '2024-02-22T17:57:13',
                                  last_modified: '2024-02-22T17:57:13',
                                },
                              ],
                            },
                            pagination: {
                              $ref: {
                                title: 'Pagination',
                                required: ['total', 'size', 'page'],
                                type: 'object',
                                properties: {
                                  total: {
                                    title: 'Total',
                                    type: 'integer',
                                    description: 'The total number of records.',
                                    examples: 4,
                                  },
                                  size: {
                                    title: 'Size',
                                    type: 'integer',
                                    description: 'The size or number of records per page.',
                                    examples: 10,
                                  },
                                  page: {
                                    title: 'Page',
                                    type: 'integer',
                                    description: 'The current page number in pagination.',
                                    examples: 1,
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                    ],
                    examples: {
                      items: [
                        {
                          reference_id: 'ASUQ4DFTyn',
                          account_type: 'Individual',
                          created: '2024-03-28T12:19:01+00:00',
                          last_modified: '2024-03-28T12:19:01+00:00',
                        },
                        {
                          reference_id: 'OFRyTDkDAT',
                          account_type: 'Individual',
                          created: '2024-03-28T12:18:26+00:00',
                          last_modified: '2024-03-28T12:18:26+00:00',
                        },
                        {
                          reference_id: 'HDXB23556',
                          account_type: 'Individual',
                          created: '2024-03-28T12:09:25+00:00',
                          last_modified: '2024-03-28T12:09:25+00:00',
                        },
                        {
                          reference_id: '7hMIyBlQVP',
                          account_type: 'Entity',
                          created: '2024-03-28T10:14:11+00:00',
                          last_modified: '2024-03-28T10:14:11+00:00',
                        },
                        {
                          reference_id: 'hn7iHhDMUl',
                          account_type: 'Entity',
                          created: '2024-03-28T10:09:02+00:00',
                          last_modified: '2024-03-28T10:09:02+00:00',
                        },
                        {
                          reference_id: 'lc9NFTpX0F',
                          account_type: 'Entity',
                          created: '2024-03-28T10:06:20+00:00',
                          last_modified: '2024-03-28T10:06:20+00:00',
                        },
                        {
                          reference_id: 'LBuMWHb6pE',
                          account_type: 'Entity',
                          created: '2024-03-28T10:03:15+00:00',
                          last_modified: '2024-03-28T10:03:15+00:00',
                        },
                        {
                          reference_id: 'H855rzxneX',
                          account_type: 'Entity',
                          created: '2024-03-28T09:55:09+00:00',
                          last_modified: '2024-03-28T09:55:09+00:00',
                        },
                        {
                          reference_id: '72x23kvm3U',
                          account_type: 'Entity',
                          created: '2024-03-28T07:28:04+00:00',
                          last_modified: '2024-03-28T07:28:04+00:00',
                        },
                        {
                          reference_id: 'AZDtb9CdBd',
                          account_type: 'Entity',
                          created: '2024-03-28T07:27:29+00:00',
                          last_modified: '2024-03-28T07:27:29+00:00',
                        },
                      ],
                      pagination: {
                        total: 40,
                        size: 10,
                        page: 1,
                      },
                    },
                  },
                  message: {
                    title: 'Message',
                    type: 'string',
                    examples: 'Null',
                  },
                },
              },
            },
          },
        },
      },
      404: {
        description: 'Not found',
      },
      422: {
        description: 'Validation Error',
        content: {
          'application/json': {
            schema: {
              $ref: {
                title: 'HTTPValidationError',
                type: 'object',
                properties: {
                  detail: {
                    title: 'Detail',
                    type: 'array',
                    items: {
                      $ref: {
                        title: 'ValidationError',
                        required: ['loc', 'msg', 'type'],
                        type: 'object',
                        properties: {
                          loc: {
                            title: 'Location',
                            type: 'array',
                            items: {
                              anyOf: [
                                {
                                  type: 'string',
                                },
                                {
                                  type: 'integer',
                                },
                              ],
                            },
                          },
                          msg: {
                            title: 'Message',
                            type: 'string',
                          },
                          type: {
                            title: 'Error Type',
                            type: 'string',
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
  {
    endpoint: '/api/v2/directory/accounts/{reference_id}',
    method: 'get',
    tags: ['v2'],
    summary: 'Account Details',
    description: 'This endpoint retrieves the details of a specific account identified by {reference_id}.',
    operationId: 'Account_Details_api_v2_directory_accounts__reference_id__get',
    security: [
      {
        HTTPBearer: [],
      },
    ],
    parameters: [
      {
        name: 'reference_id',
        in: 'path',
        required: true,
        schema: {
          title: 'Reference Id',
          description: 'Unique identifier for the account, previously external_id.',
          type: 'string',
        },
        description: 'Unique identifier for the account, previously external_id.',
      },
    ],
    responses: {
      200: {
        description: 'Successful Response',
        content: {
          'application/json': {
            schema: {
              $ref: {
                title: 'DM03ResponseCom',
                type: 'object',
                properties: {
                  status: {
                    title: 'Status',
                    type: 'string',
                    default: 'success',
                    examples: 'success',
                  },
                  data: {
                    title: 'Data',
                    allOf: [
                      {
                        $ref: {
                          title: 'AccountDetailsCommercial',
                          required: ['reference_id', 'account_type'],
                          type: 'object',
                          properties: {
                            reference_id: {
                              title: 'Reference Id',
                              maxLength: 50,
                              type: 'string',
                              description: 'Unique identifier for the account.',
                              examples: 'johndoe',
                            },
                            account_type: {
                              allOf: [
                                {
                                  $ref: {
                                    title: 'AccountTypeString',
                                    enum: ['individual', 'entity'],
                                    type: 'string',
                                    description: 'An enumeration.',
                                  },
                                },
                              ],
                              description: "Type of account, either 'individual' or 'entity'.",
                              examples: ['individual', 'entity'],
                            },
                            email: {
                              title: 'Email',
                              maxLength: 50,
                              type: 'string',
                              description: 'Email address associated with the account.',
                              examples: 'you@company.com',
                            },
                            individual: {
                              title: 'Individual',
                              allOf: [
                                {
                                  $ref: {
                                    title: 'AccountIndividual',
                                    required: ['individual_name'],
                                    type: 'object',
                                    properties: {
                                      individual_name: {
                                        title: 'Individual Name',
                                        allOf: [
                                          {
                                            $ref: {
                                              title: 'IndividualName',
                                              required: ['name_type', 'name_last'],
                                              type: 'object',
                                              properties: {
                                                name_type: {
                                                  allOf: [
                                                    {
                                                      $ref: {
                                                        title: 'NameTypeString',
                                                        enum: ['LEGL'],
                                                        type: 'string',
                                                        description: 'An enumeration.',
                                                      },
                                                    },
                                                  ],
                                                  description:
                                                    'Type of the name. Required if entity_name or entity_localname or individual_name or individual_local_name is specified',
                                                  requirement: 'Conditional',
                                                  examples: 'LEGL',
                                                },
                                                name_first: {
                                                  title: 'Name First',
                                                  maxLength: 50,
                                                  type: 'string',
                                                  description: 'First name of the account holder.',
                                                  examples: 'John',
                                                },
                                                name_middle: {
                                                  title: 'Name Middle',
                                                  maxLength: 50,
                                                  type: 'string',
                                                  description: 'Middle name of the account holder.',
                                                  examples: 'Robert',
                                                },
                                                name_last: {
                                                  title: 'Name Last',
                                                  maxLength: 50,
                                                  type: 'string',
                                                  description:
                                                    'Last name of the account holder. Required if account_type is individual.',
                                                  examples: 'Doe',
                                                },
                                              },
                                            },
                                          },
                                        ],
                                        description: 'Object that contains the individual name fields',
                                        requirement: 'Conditional',
                                      },
                                      individual_localname: {
                                        title: 'Individual Localname',
                                        allOf: [
                                          {
                                            $ref: {
                                              title: 'IndividualName',
                                              required: ['name_type', 'name_last'],
                                              type: 'object',
                                              properties: {
                                                name_type: {
                                                  allOf: [
                                                    {
                                                      $ref: {
                                                        title: 'NameTypeString',
                                                        enum: ['LEGL'],
                                                        type: 'string',
                                                        description: 'An enumeration.',
                                                      },
                                                    },
                                                  ],
                                                  description:
                                                    'Type of the name. Required if entity_name or entity_localname or individual_name or individual_local_name is specified',
                                                  requirement: 'Conditional',
                                                  examples: 'LEGL',
                                                },
                                                name_first: {
                                                  title: 'Name First',
                                                  maxLength: 50,
                                                  type: 'string',
                                                  description: 'First name of the account holder.',
                                                  examples: 'John',
                                                },
                                                name_middle: {
                                                  title: 'Name Middle',
                                                  maxLength: 50,
                                                  type: 'string',
                                                  description: 'Middle name of the account holder.',
                                                  examples: 'Robert',
                                                },
                                                name_last: {
                                                  title: 'Name Last',
                                                  maxLength: 50,
                                                  type: 'string',
                                                  description:
                                                    'Last name of the account holder. Required if account_type is individual.',
                                                  examples: 'Doe',
                                                },
                                              },
                                            },
                                          },
                                        ],
                                        description: 'Object that contains the individual local name fields',
                                        requirement: 'Conditional',
                                      },
                                      date_of_birth: {
                                        title: 'Date Of Birth',
                                        type: 'string',
                                        description: 'Date of birth of the account holder.',
                                        format: 'date',
                                        examples: '1990-01-01',
                                      },
                                      place_of_birth: {
                                        title: 'Place Of Birth',
                                        maxLength: 50,
                                        type: 'string',
                                        description: 'Place of birth of the account holder.',
                                        examples: 'Sinagpore, Singapore',
                                      },
                                      nationality_country: {
                                        title: 'Nationality Country',
                                        maxLength: 2,
                                        type: 'string',
                                        description: 'Country code of the nationality of the account holder.',
                                        examples: 'SG',
                                      },
                                      residence_country: {
                                        title: 'Residence Country',
                                        maxLength: 2,
                                        type: 'string',
                                        description: 'Country code of the place of residence of the account holder.',
                                        examples: 'US',
                                      },
                                    },
                                  },
                                },
                              ],
                              description: 'The object that contains the fields specific for an individual account',
                            },
                            entity: {
                              title: 'Entity',
                              allOf: [
                                {
                                  $ref: {
                                    title: 'AccountEntity',
                                    required: ['entity_name'],
                                    type: 'object',
                                    properties: {
                                      entity_name: {
                                        title: 'Entity Name',
                                        allOf: [
                                          {
                                            $ref: {
                                              title: 'EntityName',
                                              required: ['name_type', 'name_legal'],
                                              type: 'object',
                                              properties: {
                                                name_type: {
                                                  allOf: [
                                                    {
                                                      $ref: {
                                                        title: 'NameTypeString',
                                                        enum: ['LEGL'],
                                                        type: 'string',
                                                        description: 'An enumeration.',
                                                      },
                                                    },
                                                  ],
                                                  description:
                                                    'Type of the name. Required if entity_name or entity_localname or individual_name or individual_local_name is specified',
                                                  requirement: 'Conditional',
                                                  examples: 'LEGL',
                                                },
                                                name_legal: {
                                                  title: 'Name Legal',
                                                  maxLength: 100,
                                                  type: 'string',
                                                  description:
                                                    'Name of the business.  Required if account_type is entity.',
                                                  requirement: 'Conditional',
                                                  examples: 'Digital Capital',
                                                },
                                                name_business: {
                                                  title: 'Name Business',
                                                  maxLength: 100,
                                                  type: 'string',
                                                  description: 'Legal name of the business.',
                                                  examples: 'Digital Capital Pte. Ltd.',
                                                },
                                              },
                                            },
                                          },
                                        ],
                                        description: 'The object that contains the entity name fields.',
                                      },
                                      entity_localname: {
                                        title: 'Entity Localname',
                                        allOf: [
                                          {
                                            $ref: {
                                              title: 'EntityName',
                                              required: ['name_type', 'name_legal'],
                                              type: 'object',
                                              properties: {
                                                name_type: {
                                                  allOf: [
                                                    {
                                                      $ref: {
                                                        title: 'NameTypeString',
                                                        enum: ['LEGL'],
                                                        type: 'string',
                                                        description: 'An enumeration.',
                                                      },
                                                    },
                                                  ],
                                                  description:
                                                    'Type of the name. Required if entity_name or entity_localname or individual_name or individual_local_name is specified',
                                                  requirement: 'Conditional',
                                                  examples: 'LEGL',
                                                },
                                                name_legal: {
                                                  title: 'Name Legal',
                                                  maxLength: 100,
                                                  type: 'string',
                                                  description:
                                                    'Name of the business.  Required if account_type is entity.',
                                                  requirement: 'Conditional',
                                                  examples: 'Digital Capital',
                                                },
                                                name_business: {
                                                  title: 'Name Business',
                                                  maxLength: 100,
                                                  type: 'string',
                                                  description: 'Legal name of the business.',
                                                  examples: 'Digital Capital Pte. Ltd.',
                                                },
                                              },
                                            },
                                          },
                                        ],
                                        description: 'The object that contains the entity local name fields.',
                                      },
                                      registration_country: {
                                        title: 'Registration Country',
                                        maxLength: 2,
                                        type: 'string',
                                        description: 'Country code of incorporation for the business.',
                                        examples: 'SG',
                                      },
                                    },
                                  },
                                },
                              ],
                              description: 'The object that contains the fields specific for an entity account',
                            },
                            address: {
                              title: 'Address',
                              allOf: [
                                {
                                  $ref: {
                                    title: 'Address',
                                    required: ['address_type'],
                                    type: 'object',
                                    properties: {
                                      address_type: {
                                        allOf: [
                                          {
                                            $ref: {
                                              title: 'AddressTypeString',
                                              enum: ['GEOG'],
                                              type: 'string',
                                              description: 'An enumeration.',
                                            },
                                          },
                                        ],
                                        description: 'Required if address object is specified. Type of the address.',
                                        requirement: 'Conditional',
                                        examples: 'GEOG',
                                      },
                                      address_line: {
                                        title: 'Address Line',
                                        maxLength: 256,
                                        type: 'string',
                                        description:
                                          'Either providing an address_line or including a combination of address_street_no or address_building_name along with address_street_name is required.',
                                        requirement: 'Conditional',
                                        examples: 'Apt 123',
                                      },
                                      address_street_no: {
                                        title: 'Address Street No',
                                        maxLength: 10,
                                        type: 'string',
                                        description:
                                          'Either providing an address_line or including a combination of address_street_no or address_building_name along with address_street_name is required',
                                        requirement: 'Conditional',
                                        examples: '456',
                                      },
                                      address_street_name: {
                                        title: 'Address Street Name',
                                        maxLength: 100,
                                        type: 'string',
                                        description:
                                          'Either providing an address_line or including a combination of address_street_no or address_building_name along with address_street_name is required.',
                                        requirement: 'Conditional',
                                        examples: 'Elm Street',
                                      },
                                      address_building_name: {
                                        title: 'Address Building Name',
                                        maxLength: 20,
                                        type: 'string',
                                        description:
                                          'Either providing an address_line or including a combination of address_street_no or address_building_name along with address_street is required.',
                                        requirement: 'Conditional',
                                        examples: 'Elmwood Apartments',
                                      },
                                      address_floor: {
                                        title: 'Address Floor',
                                        maxLength: 20,
                                        type: 'string',
                                        description: 'Floor number in the building.',
                                        examples: '15',
                                      },
                                      address_room: {
                                        title: 'Address Room',
                                        maxLength: 20,
                                        type: 'string',
                                        description: 'Room number in the building.',
                                        examples: '1503',
                                      },
                                      address_department: {
                                        title: 'Address Department',
                                        maxLength: 20,
                                        type: 'string',
                                        description: 'Department within the address.',
                                      },
                                      address_subdepartment: {
                                        title: 'Address Subdepartment',
                                        maxLength: 20,
                                        type: 'string',
                                        description: 'Sub-department within the address.',
                                      },
                                      address_postbox: {
                                        title: 'Address Postbox',
                                        maxLength: 20,
                                        type: 'string',
                                        description: 'Post office box number.',
                                        examples: 'PO Box 789',
                                      },
                                      address_city_location: {
                                        title: 'Address City Location',
                                        maxLength: 50,
                                        type: 'string',
                                        description: 'Geographical location of the city.',
                                        examples: '',
                                      },
                                      address_city: {
                                        title: 'Address City',
                                        maxLength: 50,
                                        type: 'string',
                                        description: "City of the account holder's address.",
                                        examples: 'New York',
                                      },
                                      address_region: {
                                        title: 'Address Region',
                                        maxLength: 50,
                                        type: 'string',
                                        description: "Region or state of the account holder's address.",
                                        examples: 'New York',
                                      },
                                      address_district: {
                                        title: 'Address District',
                                        maxLength: 50,
                                        type: 'string',
                                        description: "District of the account holder's address.",
                                        examples: 'Manhattan',
                                      },
                                      address_postcode: {
                                        title: 'Address Postcode',
                                        maxLength: 25,
                                        type: 'string',
                                        description: "Postal code of the account holder's address.",
                                        examples: '08012',
                                      },
                                      address_country_code: {
                                        title: 'Address Country Code',
                                        maxLength: 2,
                                        type: 'string',
                                        description: "Country code of the account holder's address.",
                                        examples: 'US',
                                      },
                                    },
                                  },
                                },
                              ],
                              description:
                                'The object that contains the fields specific for the address of the account',
                            },
                            national_id: {
                              title: 'National Id',
                              allOf: [
                                {
                                  $ref: {
                                    title: 'NationalId',
                                    required: ['national_id_type', 'national_id_no'],
                                    type: 'object',
                                    properties: {
                                      national_id_type: {
                                        allOf: [
                                          {
                                            $ref: {
                                              title: 'NationalIdTypeString',
                                              enum: [
                                                'ARNU',
                                                'CCPT',
                                                'RAID',
                                                'DRLC',
                                                'FIIN',
                                                'TXID',
                                                'SOCS',
                                                'IDCD',
                                                'LEIX',
                                                'MISC',
                                              ],
                                              type: 'string',
                                              description: 'An enumeration.',
                                            },
                                          },
                                        ],
                                        description:
                                          'Required when national_id object is specified. Type of national identification.',
                                        requirement: 'Conditional',
                                        examples: [
                                          'ARNU',
                                          'CCPT',
                                          'RAID',
                                          'DRLC',
                                          'FIIN',
                                          'TXID',
                                          'SOCS',
                                          'IDCD',
                                          'LEIX',
                                          'MISC',
                                        ],
                                      },
                                      national_id_no: {
                                        title: 'National Id No',
                                        maxLength: 35,
                                        type: 'string',
                                        description: 'National identification number.',
                                        examples: 'ABC123456',
                                      },
                                      issue_country: {
                                        title: 'Issue Country',
                                        maxLength: 2,
                                        type: 'string',
                                        description: 'Country that issued the national identification.',
                                        examples: 'US',
                                      },
                                    },
                                  },
                                },
                              ],
                              description:
                                'The object that contains the fields specific for the national id of the account',
                            },
                            created: {
                              title: 'Created',
                              type: 'string',
                              format: 'date-time',
                              tag: 'hide',
                            },
                            last_modified: {
                              title: 'Last Modified',
                              type: 'string',
                              format: 'date-time',
                              tag: 'hide',
                            },
                          },
                        },
                      },
                    ],
                    examples: {
                      reference_id: 'johndoe',
                      account_type: 'individual',
                      email: 'you@company.com',
                      individual: {
                        date_of_birth: '1988-06-27',
                        place_of_birth: 'Singapore, Singapore',
                        individual_name: {
                          name_type: 'LEGL',
                          name_first: 'John',
                          name_middle: 'Mark',
                          name_last: 'Doe',
                        },
                        individual_localname: {
                          name_type: 'LEGL',
                          name_first: 'John',
                          name_middle: 'Mark',
                          name_last: 'Doe',
                        },
                      },
                      address: {
                        address_type: 'GEOG',
                        address_line: 'Apt 123',
                        address_street_no: '456',
                        address_street_name: 'Elm Street',
                        address_building_name: 'Elmwood Apartments',
                        address_floor: '15',
                        address_room: '1503',
                        address_postbox: 'PO Box 789',
                        address_city: 'New York',
                        address_region: 'New York',
                        address_district: 'Manhattan',
                        address_postcode: '10001',
                        address_country_code: 'US',
                      },
                      created: '2024-02-26 21:57:18.000 +0800',
                      last_modified: '2024-02-27 10:37:20.000 +0800',
                    },
                  },
                  message: {
                    title: 'Message',
                    type: 'string',
                    examples: 'Null',
                  },
                },
              },
            },
          },
        },
      },
      404: {
        description: 'Not found',
      },
      422: {
        description: 'Validation Error',
        content: {
          'application/json': {
            schema: {
              $ref: {
                title: 'HTTPValidationError',
                type: 'object',
                properties: {
                  detail: {
                    title: 'Detail',
                    type: 'array',
                    items: {
                      $ref: {
                        title: 'ValidationError',
                        required: ['loc', 'msg', 'type'],
                        type: 'object',
                        properties: {
                          loc: {
                            title: 'Location',
                            type: 'array',
                            items: {
                              anyOf: [
                                {
                                  type: 'string',
                                },
                                {
                                  type: 'integer',
                                },
                              ],
                            },
                          },
                          msg: {
                            title: 'Message',
                            type: 'string',
                          },
                          type: {
                            title: 'Error Type',
                            type: 'string',
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
  {
    endpoint: '/api/v2/directory/accounts/{reference_id}',
    method: 'patch',
    tags: ['v2'],
    summary: 'Modify Account',
    description:
      'This endpoint is utilized to modify/update the details of a specific account identified by {reference_id}. All body inputs are optional. Only provided inputs will be updated in the account record, while all others will maintain their current values.',
    operationId: 'Modify_Account_api_v2_directory_accounts__reference_id__patch',
    security: [
      {
        HTTPBearer: [],
      },
    ],
    parameters: [
      {
        name: 'reference_id',
        in: 'path',
        required: true,
        schema: {
          title: 'Reference Id',
          description: 'Unique identifier for the account, previously external_id.',
          type: 'string',
        },
        description: 'Unique identifier for the account, previously external_id.',
      },
    ],
    requestBody: {
      required: true,
      content: {
        'application/json': {
          schema: {
            $ref: {
              title: 'DM04RequestBody',
              type: 'object',
              properties: {
                fields: {
                  title: 'Fields',
                  type: 'array',
                  items: {
                    $ref: {
                      title: 'AccountFieldUpdate',
                      required: ['field'],
                      type: 'object',
                      properties: {
                        field: {
                          title: 'Field',
                          minItems: 1,
                          type: 'array',
                          items: {
                            type: 'string',
                          },
                          description: 'The name of the field to be updated.',
                          examples: ["['individual', 'individual_name', 'name_first']"],
                        },
                        value: {
                          title: 'Value',
                          anyOf: [
                            {
                              type: 'string',
                            },
                            {
                              type: 'integer',
                            },
                            {
                              type: 'string',
                              format: 'date',
                            },
                          ],
                          description: 'The value of the field to be updated to.',
                          examples: 'Mark',
                        },
                      },
                    },
                  },
                  description: 'An array of the fields to be updated.',
                },
              },
            },
          },
          examples: [
            {
              fields: [
                {
                  field: ['individual', 'individual_name', 'name_first'],
                  value: 'John',
                },
                {
                  field: ['individual', 'individual_name', 'name_last'],
                  value: 'Doe',
                },
                {
                  field: ['email'],
                  value: 'you@company.com',
                },
              ],
            },
          ],
        },
      },
    },
    responses: {
      200: {
        description: 'Successful Response',
        content: {
          'application/json': {
            schema: {
              $ref: {
                title: 'DM04ResponseCom',
                type: 'object',
                properties: {
                  status: {
                    title: 'Status',
                    type: 'string',
                    default: 'success',
                    examples: 'success',
                  },
                  data: {
                    title: 'Data',
                    allOf: [
                      {
                        $ref: {
                          title: 'AccountDetailsCommercial',
                          required: ['reference_id', 'account_type'],
                          type: 'object',
                          properties: {
                            reference_id: {
                              title: 'Reference Id',
                              maxLength: 50,
                              type: 'string',
                              description: 'Unique identifier for the account.',
                              examples: 'johndoe',
                            },
                            account_type: {
                              allOf: [
                                {
                                  $ref: {
                                    title: 'AccountTypeString',
                                    enum: ['individual', 'entity'],
                                    type: 'string',
                                    description: 'An enumeration.',
                                  },
                                },
                              ],
                              description: "Type of account, either 'individual' or 'entity'.",
                              examples: ['individual', 'entity'],
                            },
                            email: {
                              title: 'Email',
                              maxLength: 50,
                              type: 'string',
                              description: 'Email address associated with the account.',
                              examples: 'you@company.com',
                            },
                            individual: {
                              title: 'Individual',
                              allOf: [
                                {
                                  $ref: {
                                    title: 'AccountIndividual',
                                    required: ['individual_name'],
                                    type: 'object',
                                    properties: {
                                      individual_name: {
                                        title: 'Individual Name',
                                        allOf: [
                                          {
                                            $ref: {
                                              title: 'IndividualName',
                                              required: ['name_type', 'name_last'],
                                              type: 'object',
                                              properties: {
                                                name_type: {
                                                  allOf: [
                                                    {
                                                      $ref: {
                                                        title: 'NameTypeString',
                                                        enum: ['LEGL'],
                                                        type: 'string',
                                                        description: 'An enumeration.',
                                                      },
                                                    },
                                                  ],
                                                  description:
                                                    'Type of the name. Required if entity_name or entity_localname or individual_name or individual_local_name is specified',
                                                  requirement: 'Conditional',
                                                  examples: 'LEGL',
                                                },
                                                name_first: {
                                                  title: 'Name First',
                                                  maxLength: 50,
                                                  type: 'string',
                                                  description: 'First name of the account holder.',
                                                  examples: 'John',
                                                },
                                                name_middle: {
                                                  title: 'Name Middle',
                                                  maxLength: 50,
                                                  type: 'string',
                                                  description: 'Middle name of the account holder.',
                                                  examples: 'Robert',
                                                },
                                                name_last: {
                                                  title: 'Name Last',
                                                  maxLength: 50,
                                                  type: 'string',
                                                  description:
                                                    'Last name of the account holder. Required if account_type is individual.',
                                                  examples: 'Doe',
                                                },
                                              },
                                            },
                                          },
                                        ],
                                        description: 'Object that contains the individual name fields',
                                        requirement: 'Conditional',
                                      },
                                      individual_localname: {
                                        title: 'Individual Localname',
                                        allOf: [
                                          {
                                            $ref: {
                                              title: 'IndividualName',
                                              required: ['name_type', 'name_last'],
                                              type: 'object',
                                              properties: {
                                                name_type: {
                                                  allOf: [
                                                    {
                                                      $ref: {
                                                        title: 'NameTypeString',
                                                        enum: ['LEGL'],
                                                        type: 'string',
                                                        description: 'An enumeration.',
                                                      },
                                                    },
                                                  ],
                                                  description:
                                                    'Type of the name. Required if entity_name or entity_localname or individual_name or individual_local_name is specified',
                                                  requirement: 'Conditional',
                                                  examples: 'LEGL',
                                                },
                                                name_first: {
                                                  title: 'Name First',
                                                  maxLength: 50,
                                                  type: 'string',
                                                  description: 'First name of the account holder.',
                                                  examples: 'John',
                                                },
                                                name_middle: {
                                                  title: 'Name Middle',
                                                  maxLength: 50,
                                                  type: 'string',
                                                  description: 'Middle name of the account holder.',
                                                  examples: 'Robert',
                                                },
                                                name_last: {
                                                  title: 'Name Last',
                                                  maxLength: 50,
                                                  type: 'string',
                                                  description:
                                                    'Last name of the account holder. Required if account_type is individual.',
                                                  examples: 'Doe',
                                                },
                                              },
                                            },
                                          },
                                        ],
                                        description: 'Object that contains the individual local name fields',
                                        requirement: 'Conditional',
                                      },
                                      date_of_birth: {
                                        title: 'Date Of Birth',
                                        type: 'string',
                                        description: 'Date of birth of the account holder.',
                                        format: 'date',
                                        examples: '1990-01-01',
                                      },
                                      place_of_birth: {
                                        title: 'Place Of Birth',
                                        maxLength: 50,
                                        type: 'string',
                                        description: 'Place of birth of the account holder.',
                                        examples: 'Sinagpore, Singapore',
                                      },
                                      nationality_country: {
                                        title: 'Nationality Country',
                                        maxLength: 2,
                                        type: 'string',
                                        description: 'Country code of the nationality of the account holder.',
                                        examples: 'SG',
                                      },
                                      residence_country: {
                                        title: 'Residence Country',
                                        maxLength: 2,
                                        type: 'string',
                                        description: 'Country code of the place of residence of the account holder.',
                                        examples: 'US',
                                      },
                                    },
                                  },
                                },
                              ],
                              description: 'The object that contains the fields specific for an individual account',
                            },
                            entity: {
                              title: 'Entity',
                              allOf: [
                                {
                                  $ref: {
                                    title: 'AccountEntity',
                                    required: ['entity_name'],
                                    type: 'object',
                                    properties: {
                                      entity_name: {
                                        title: 'Entity Name',
                                        allOf: [
                                          {
                                            $ref: {
                                              title: 'EntityName',
                                              required: ['name_type', 'name_legal'],
                                              type: 'object',
                                              properties: {
                                                name_type: {
                                                  allOf: [
                                                    {
                                                      $ref: {
                                                        title: 'NameTypeString',
                                                        enum: ['LEGL'],
                                                        type: 'string',
                                                        description: 'An enumeration.',
                                                      },
                                                    },
                                                  ],
                                                  description:
                                                    'Type of the name. Required if entity_name or entity_localname or individual_name or individual_local_name is specified',
                                                  requirement: 'Conditional',
                                                  examples: 'LEGL',
                                                },
                                                name_legal: {
                                                  title: 'Name Legal',
                                                  maxLength: 100,
                                                  type: 'string',
                                                  description:
                                                    'Name of the business.  Required if account_type is entity.',
                                                  requirement: 'Conditional',
                                                  examples: 'Digital Capital',
                                                },
                                                name_business: {
                                                  title: 'Name Business',
                                                  maxLength: 100,
                                                  type: 'string',
                                                  description: 'Legal name of the business.',
                                                  examples: 'Digital Capital Pte. Ltd.',
                                                },
                                              },
                                            },
                                          },
                                        ],
                                        description: 'The object that contains the entity name fields.',
                                      },
                                      entity_localname: {
                                        title: 'Entity Localname',
                                        allOf: [
                                          {
                                            $ref: {
                                              title: 'EntityName',
                                              required: ['name_type', 'name_legal'],
                                              type: 'object',
                                              properties: {
                                                name_type: {
                                                  allOf: [
                                                    {
                                                      $ref: {
                                                        title: 'NameTypeString',
                                                        enum: ['LEGL'],
                                                        type: 'string',
                                                        description: 'An enumeration.',
                                                      },
                                                    },
                                                  ],
                                                  description:
                                                    'Type of the name. Required if entity_name or entity_localname or individual_name or individual_local_name is specified',
                                                  requirement: 'Conditional',
                                                  examples: 'LEGL',
                                                },
                                                name_legal: {
                                                  title: 'Name Legal',
                                                  maxLength: 100,
                                                  type: 'string',
                                                  description:
                                                    'Name of the business.  Required if account_type is entity.',
                                                  requirement: 'Conditional',
                                                  examples: 'Digital Capital',
                                                },
                                                name_business: {
                                                  title: 'Name Business',
                                                  maxLength: 100,
                                                  type: 'string',
                                                  description: 'Legal name of the business.',
                                                  examples: 'Digital Capital Pte. Ltd.',
                                                },
                                              },
                                            },
                                          },
                                        ],
                                        description: 'The object that contains the entity local name fields.',
                                      },
                                      registration_country: {
                                        title: 'Registration Country',
                                        maxLength: 2,
                                        type: 'string',
                                        description: 'Country code of incorporation for the business.',
                                        examples: 'SG',
                                      },
                                    },
                                  },
                                },
                              ],
                              description: 'The object that contains the fields specific for an entity account',
                            },
                            address: {
                              title: 'Address',
                              allOf: [
                                {
                                  $ref: {
                                    title: 'Address',
                                    required: ['address_type'],
                                    type: 'object',
                                    properties: {
                                      address_type: {
                                        allOf: [
                                          {
                                            $ref: {
                                              title: 'AddressTypeString',
                                              enum: ['GEOG'],
                                              type: 'string',
                                              description: 'An enumeration.',
                                            },
                                          },
                                        ],
                                        description: 'Required if address object is specified. Type of the address.',
                                        requirement: 'Conditional',
                                        examples: 'GEOG',
                                      },
                                      address_line: {
                                        title: 'Address Line',
                                        maxLength: 256,
                                        type: 'string',
                                        description:
                                          'Either providing an address_line or including a combination of address_street_no or address_building_name along with address_street_name is required.',
                                        requirement: 'Conditional',
                                        examples: 'Apt 123',
                                      },
                                      address_street_no: {
                                        title: 'Address Street No',
                                        maxLength: 10,
                                        type: 'string',
                                        description:
                                          'Either providing an address_line or including a combination of address_street_no or address_building_name along with address_street_name is required',
                                        requirement: 'Conditional',
                                        examples: '456',
                                      },
                                      address_street_name: {
                                        title: 'Address Street Name',
                                        maxLength: 100,
                                        type: 'string',
                                        description:
                                          'Either providing an address_line or including a combination of address_street_no or address_building_name along with address_street_name is required.',
                                        requirement: 'Conditional',
                                        examples: 'Elm Street',
                                      },
                                      address_building_name: {
                                        title: 'Address Building Name',
                                        maxLength: 20,
                                        type: 'string',
                                        description:
                                          'Either providing an address_line or including a combination of address_street_no or address_building_name along with address_street is required.',
                                        requirement: 'Conditional',
                                        examples: 'Elmwood Apartments',
                                      },
                                      address_floor: {
                                        title: 'Address Floor',
                                        maxLength: 20,
                                        type: 'string',
                                        description: 'Floor number in the building.',
                                        examples: '15',
                                      },
                                      address_room: {
                                        title: 'Address Room',
                                        maxLength: 20,
                                        type: 'string',
                                        description: 'Room number in the building.',
                                        examples: '1503',
                                      },
                                      address_department: {
                                        title: 'Address Department',
                                        maxLength: 20,
                                        type: 'string',
                                        description: 'Department within the address.',
                                      },
                                      address_subdepartment: {
                                        title: 'Address Subdepartment',
                                        maxLength: 20,
                                        type: 'string',
                                        description: 'Sub-department within the address.',
                                      },
                                      address_postbox: {
                                        title: 'Address Postbox',
                                        maxLength: 20,
                                        type: 'string',
                                        description: 'Post office box number.',
                                        examples: 'PO Box 789',
                                      },
                                      address_city_location: {
                                        title: 'Address City Location',
                                        maxLength: 50,
                                        type: 'string',
                                        description: 'Geographical location of the city.',
                                        examples: '',
                                      },
                                      address_city: {
                                        title: 'Address City',
                                        maxLength: 50,
                                        type: 'string',
                                        description: "City of the account holder's address.",
                                        examples: 'New York',
                                      },
                                      address_region: {
                                        title: 'Address Region',
                                        maxLength: 50,
                                        type: 'string',
                                        description: "Region or state of the account holder's address.",
                                        examples: 'New York',
                                      },
                                      address_district: {
                                        title: 'Address District',
                                        maxLength: 50,
                                        type: 'string',
                                        description: "District of the account holder's address.",
                                        examples: 'Manhattan',
                                      },
                                      address_postcode: {
                                        title: 'Address Postcode',
                                        maxLength: 25,
                                        type: 'string',
                                        description: "Postal code of the account holder's address.",
                                        examples: '08012',
                                      },
                                      address_country_code: {
                                        title: 'Address Country Code',
                                        maxLength: 2,
                                        type: 'string',
                                        description: "Country code of the account holder's address.",
                                        examples: 'US',
                                      },
                                    },
                                  },
                                },
                              ],
                              description:
                                'The object that contains the fields specific for the address of the account',
                            },
                            national_id: {
                              title: 'National Id',
                              allOf: [
                                {
                                  $ref: {
                                    title: 'NationalId',
                                    required: ['national_id_type', 'national_id_no'],
                                    type: 'object',
                                    properties: {
                                      national_id_type: {
                                        allOf: [
                                          {
                                            $ref: {
                                              title: 'NationalIdTypeString',
                                              enum: [
                                                'ARNU',
                                                'CCPT',
                                                'RAID',
                                                'DRLC',
                                                'FIIN',
                                                'TXID',
                                                'SOCS',
                                                'IDCD',
                                                'LEIX',
                                                'MISC',
                                              ],
                                              type: 'string',
                                              description: 'An enumeration.',
                                            },
                                          },
                                        ],
                                        description:
                                          'Required when national_id object is specified. Type of national identification.',
                                        requirement: 'Conditional',
                                        examples: [
                                          'ARNU',
                                          'CCPT',
                                          'RAID',
                                          'DRLC',
                                          'FIIN',
                                          'TXID',
                                          'SOCS',
                                          'IDCD',
                                          'LEIX',
                                          'MISC',
                                        ],
                                      },
                                      national_id_no: {
                                        title: 'National Id No',
                                        maxLength: 35,
                                        type: 'string',
                                        description: 'National identification number.',
                                        examples: 'ABC123456',
                                      },
                                      issue_country: {
                                        title: 'Issue Country',
                                        maxLength: 2,
                                        type: 'string',
                                        description: 'Country that issued the national identification.',
                                        examples: 'US',
                                      },
                                    },
                                  },
                                },
                              ],
                              description:
                                'The object that contains the fields specific for the national id of the account',
                            },
                            created: {
                              title: 'Created',
                              type: 'string',
                              format: 'date-time',
                              tag: 'hide',
                            },
                            last_modified: {
                              title: 'Last Modified',
                              type: 'string',
                              format: 'date-time',
                              tag: 'hide',
                            },
                          },
                        },
                      },
                    ],
                    examples: {
                      reference_id: 'johndoe',
                      account_type: 'individual',
                      email: 'you@company.com',
                      individual: {
                        date_of_birth: '1988-06-27',
                        place_of_birth: 'Singapore, Singapore',
                        individual_name: {
                          name_type: 'LEGL',
                          name_first: 'John',
                          name_middle: 'Mark',
                          name_last: 'Doe',
                        },
                        individual_localname: {
                          name_type: 'LEGL',
                          name_first: 'John',
                          name_middle: 'Mark',
                          name_last: 'Doe',
                        },
                      },
                      address: {
                        address_type: 'GEOG',
                        address_line: 'Apt 123',
                        address_street_no: '456',
                        address_street_name: 'Elm Street',
                        address_building_name: 'Elmwood Apartments',
                        address_floor: '15',
                        address_room: '1503',
                        address_postbox: 'PO Box 789',
                        address_city: 'New York',
                        address_region: 'New York',
                        address_district: 'Manhattan',
                        address_postcode: '10001',
                        address_country_code: 'US',
                      },
                      created: '2024-02-26 21:57:18.000 +0800',
                      last_modified: '2024-02-27 10:37:20.000 +0800',
                    },
                  },
                  message: {
                    title: 'Message',
                    type: 'string',
                    examples: 'Null',
                  },
                },
              },
            },
          },
        },
      },
      404: {
        description: 'Not found',
      },
      422: {
        description: 'Validation Error',
        content: {
          'application/json': {
            schema: {
              $ref: {
                title: 'HTTPValidationError',
                type: 'object',
                properties: {
                  detail: {
                    title: 'Detail',
                    type: 'array',
                    items: {
                      $ref: {
                        title: 'ValidationError',
                        required: ['loc', 'msg', 'type'],
                        type: 'object',
                        properties: {
                          loc: {
                            title: 'Location',
                            type: 'array',
                            items: {
                              anyOf: [
                                {
                                  type: 'string',
                                },
                                {
                                  type: 'integer',
                                },
                              ],
                            },
                          },
                          msg: {
                            title: 'Message',
                            type: 'string',
                          },
                          type: {
                            title: 'Error Type',
                            type: 'string',
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
  {
    endpoint: '/api/v2/directory/accounts/{reference_id}',
    method: 'delete',
    tags: ['v2'],
    summary: 'Remove Account',
    description:
      'This endpoint is used to remove/delete a specific account identified by {reference_id} from the directory.',
    operationId: 'Remove_Account_api_v2_directory_accounts__reference_id__delete',
    security: [
      {
        HTTPBearer: [],
      },
    ],
    parameters: [
      {
        name: 'reference_id',
        in: 'path',
        required: true,
        schema: {
          title: 'Reference Id',
          description: 'Unique identifier for the account, previously external_id.',
          type: 'string',
        },
        description: 'Unique identifier for the account, previously external_id.',
      },
    ],
    responses: {
      200: {
        description: 'Successful Response',
        content: {
          'application/json': {
            schema: {
              $ref: {
                title: 'DM05ResponseCom',
                type: 'object',
                properties: {
                  status: {
                    title: 'Status',
                    type: 'string',
                    default: 'success',
                    examples: 'success',
                  },
                  data: {
                    title: 'Data',
                    type: 'string',
                    examples: 'Successfully deleted account with reference id of: johndoe',
                  },
                  message: {
                    title: 'Message',
                    type: 'string',
                    examples: 'Null',
                  },
                },
              },
            },
          },
        },
      },
      404: {
        description: 'Not found',
      },
      422: {
        description: 'Validation Error',
        content: {
          'application/json': {
            schema: {
              $ref: {
                title: 'HTTPValidationError',
                type: 'object',
                properties: {
                  detail: {
                    title: 'Detail',
                    type: 'array',
                    items: {
                      $ref: {
                        title: 'ValidationError',
                        required: ['loc', 'msg', 'type'],
                        type: 'object',
                        properties: {
                          loc: {
                            title: 'Location',
                            type: 'array',
                            items: {
                              anyOf: [
                                {
                                  type: 'string',
                                },
                                {
                                  type: 'integer',
                                },
                              ],
                            },
                          },
                          msg: {
                            title: 'Message',
                            type: 'string',
                          },
                          type: {
                            title: 'Error Type',
                            type: 'string',
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
  {
    endpoint: '/api/v2/directory/vasps/search',
    method: 'get',
    tags: ['v2'],
    summary: 'Lookup Vasp',
    description:
      'This endpoint facilitates the retrieval of a VASP record using various lookup values, such as business and legal name representations. If the provided lookup value does not match any VASP record, the endpoint will return no results.',
    operationId: 'Lookup_VASP_api_v2_directory_vasps_search_get',
    security: [
      {
        HTTPBearer: [],
      },
    ],
    parameters: [
      {
        name: 'search_term',
        in: 'query',
        required: true,
        schema: {
          title: 'Search Term',
          description:
            "The parameter 'search_term' specifies the VASP (Virtual Asset Service Provider) name that the user wishes to search for in the lookup API endpoint.",
          minLength: 1,
          type: 'string',
        },
        description:
          "The parameter 'search_term' specifies the VASP (Virtual Asset Service Provider) name that the user wishes to search for in the lookup API endpoint.",
        examples: 'ARCHAX',
      },
    ],
    responses: {
      200: {
        description: 'Successful Response',
        content: {
          'application/json': {
            schema: {
              $ref: {
                title: 'DM06ResponseCom',
                type: 'object',
                properties: {
                  status: {
                    title: 'Status',
                    type: 'string',
                    default: 'success',
                    examples: 'success',
                  },
                  data: {
                    title: 'Data',
                    type: 'array',
                    items: {
                      $ref: {
                        title: 'VASPInSummary',
                        required: ['vasp_id'],
                        type: 'object',
                        properties: {
                          vasp_id: {
                            title: 'Vasp Id',
                            type: 'string',
                            format: 'uuid',
                          },
                          name_legal: {
                            title: 'Name Legal',
                            type: 'string',
                          },
                          name_business: {
                            title: 'Name Business',
                            type: 'string',
                          },
                          address_country_code: {
                            title: 'Address Country Code',
                            type: 'string',
                          },
                          address_street_no: {
                            title: 'Address Street No',
                            type: 'string',
                          },
                          address_street_name: {
                            title: 'Address Street Name',
                            type: 'string',
                          },
                          address_city: {
                            title: 'Address City',
                            type: 'string',
                          },
                          address_region: {
                            title: 'Address Region',
                            type: 'string',
                          },
                          address_postcode: {
                            title: 'Address Postcode',
                            type: 'string',
                          },
                          address_line: {
                            title: 'Address Line',
                            type: 'string',
                          },
                          website: {
                            title: 'Website',
                            type: 'string',
                          },
                          summary: {
                            title: 'Summary',
                            type: 'string',
                          },
                        },
                      },
                    },
                    examples: [
                      {
                        vasp_id: '64fe6949-ce5d-dd4d-f20c-8c824186b7c2',
                        name_legal: 'Sample Ltd',
                        name_business: 'Sample Ltdp',
                        address_country_code: 'UK',
                        address_street_no: '32',
                        address_street_name: 'Old Jewry',
                        address_city: 'London',
                        address_region: 'South-east England',
                        address_postcode: 'EC2R 2DN',
                        website: 'www.sample.io',
                        summary:
                          'This firm is registered for certain cryptoasset activities. For more information, check out [ ]',
                      },
                    ],
                  },
                  message: {
                    title: 'Message',
                    type: 'string',
                    examples: 'Null',
                  },
                },
              },
            },
          },
        },
      },
      404: {
        description: 'Not found',
      },
      422: {
        description: 'Validation Error',
        content: {
          'application/json': {
            schema: {
              $ref: {
                title: 'HTTPValidationError',
                type: 'object',
                properties: {
                  detail: {
                    title: 'Detail',
                    type: 'array',
                    items: {
                      $ref: {
                        title: 'ValidationError',
                        required: ['loc', 'msg', 'type'],
                        type: 'object',
                        properties: {
                          loc: {
                            title: 'Location',
                            type: 'array',
                            items: {
                              anyOf: [
                                {
                                  type: 'string',
                                },
                                {
                                  type: 'integer',
                                },
                              ],
                            },
                          },
                          msg: {
                            title: 'Message',
                            type: 'string',
                          },
                          type: {
                            title: 'Error Type',
                            type: 'string',
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
]

/* eslint-disable quotes */
/* eslint-disable react/jsx-curly-brace-presence */
import React from 'react'
// import PropTypes from 'prop-types'

// Styled Elements
import {
  Wrapper,
  Container,
  SubHeaderWrapper,
  SubHeaderNavListItem,
  SubHeaderNavListLink,
  SubHeaderNavList,
  RowContainer,
  Body,
} from './ErrorsCard.elements'

function ErrorsCard() {
  return (
    <Wrapper>
      <SubHeaderWrapper>
        <SubHeaderNavList>
          <SubHeaderNavListItem>
            <SubHeaderNavListLink> HTTP STATUS CODE SUMMARY</SubHeaderNavListLink>
          </SubHeaderNavListItem>
        </SubHeaderNavList>
      </SubHeaderWrapper>
      <Container>
        <RowContainer>
          <Body>200</Body>
          <Body>Ok</Body>
          <Body>Everything worked as expected.</Body>
        </RowContainer>

        <RowContainer>
          <Body>400</Body>
          <Body>Bad Request</Body>
          <Body>The request was unacceptable, often due to missing a required parameter.</Body>
        </RowContainer>

        <RowContainer>
          <Body>401</Body>
          <Body>Unauthorized</Body>
          <Body>No valid bearer token provided.</Body>
        </RowContainer>

        <RowContainer>
          <Body>500</Body>
          <Body>Server Errors</Body>
          <Body>{`Something went wrong on Ospree's end. (These are rare.)`}</Body>
        </RowContainer>
      </Container>
    </Wrapper>
  )
}

// Default Props
ErrorsCard.defaultProps = {}

// Proptypes Validation
ErrorsCard.propTypes = {}

export default ErrorsCard

import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.darkGray};
  padding: 20px 0 0 0;
  width: 100%;
`
export const Container = styled.div`
  display: flex;
  justify-content: center;
  border-bottom: 2px solid ${({ theme }) => theme.colors.white};
  padding: 80px 40px;
  min-height: 400px;
`
export const RowContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`
export const GridRowContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  gap: 8px;
`
export const ColContainer = styled.div`
  display: flex;
  flex-direction: column;
`
export const SmallSpacer = styled.div`
  height: 8px;
  width: 100%;
`
export const Spacer = styled.div`
  height: 16px;
  width: 100%;
`
export const LargeSpacer = styled.div`
  height: 40px;
  width: 100%;
`
export const LineBreak = styled.div`
  height: 1px;
  width: 100%;
  width: 100%;
  padding: 0 0 16px 0;
  border-bottom: 2px solid ${({ theme }) => theme.colors.white};
`
export const Title = styled.div`
  font-size: 20px;
  font-weight: 700;
`
export const SubTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
`
export const Body = styled.div`
  font-size: 14px;
  font-weight: 400 !important;
  & a {
    color: ${({ theme }) => theme.colors.blue} !important;
    font-weight: 600;
  }
`
export const Paragraph = styled.div`
  padding-bottom: 10px;
`
export const Caption = styled.div`
  font-size: 10px;
  font-weight: 400 !important;
`
export const BoxedBody = styled.div`
  font-size: 14px;
  padding: 2px 8px;
  border: 1px solid ${({ theme }) => theme.colors.gray};
  border-radius: 4px;
  text-transform: uppercase;
  font-weight: 600;
`

// ------ Docs
export const DocWrapper = styled.div`
  display: flex;
  max-width: 1280px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 80px;
`
export const DocContainer = styled.div`
  display: flex;
  flex-direction: column;
`
export const ParamWrapper = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr;
  align-items: center;
  min-height: 36px;
  margin-top: 8px;
`
export const BoldText = styled.b`
  font-weight: 800 !important;
`
export const BoxedText = styled.span`
  padding: 1px 5px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.darkGray}20;
`

// ------ Code
export const CodeWrapper = styled.div`
  margin-top: 20px;
  width: 100%;
  padding: 15px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  gap: 15px;
`

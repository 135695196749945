import styled from 'styled-components'

export const AppWrapper = styled.div`
  width: 100%;
  height: 100vh;

  padding: 50px 0 0 250px;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.colors.lightGray};
  line-height: 1.7;
`

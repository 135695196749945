import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  & * {
    word-break: break-word !important;
  }
`
export const Container = styled.div`
  padding: 10px 20px;
  border: 2px solid ${({ theme }) => theme.colors.lightGray};
  border-radius: 8px;
`
export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 10px;
`
export const RowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`
export const Text = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.gray};
`
export const Value = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.darkGray};
`

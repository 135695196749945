import styled from 'styled-components'

export const Wrapper = styled.div`
  max-width: 400px;
  min-width: 260px;
  position: relative;
  width: 100%;
`
export const BarBackdrop = styled.div`
  background-color: ${({ theme }) => theme.colors.lightGray};
  height: 20px;
  width: 100%;
  border-radius: 5px;
`
export const BarLine = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  background-color: ${({ theme }) => `${theme.colors.yellowGreen}20`};
  border-right: 3px solid ${({ theme }) => theme.colors.yellowGreen};
  border-radius: 5px 2px 2px 5px;
`

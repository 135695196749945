import React from 'react'
// import { ReactQueryDevtools } from 'react-query-devtools'

// App Providers
import AppReactQueryProvider from 'core/providers/AppReactQueryProvider'
import AppThemeProvider from 'core/providers/AppThemeProvider'

// App Routes
import AppRoutes from 'core/routing'

// App Global Styles
import GlobalStyle from 'common/config/theme/global'

function App() {
  return (
    <>
      <AppReactQueryProvider>
        <AppThemeProvider>
          <AppRoutes />
        </AppThemeProvider>
      </AppReactQueryProvider>
      <GlobalStyle />
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </>
  )
}

export default App

import styled from 'styled-components'

export const TableItemWrapper = styled.div`
  border-radius: 10px;
  width: 100%;
  height: 100%;
  max-width: 450px;
  transition: 0.3s;
  cursor: pointer;

  /* display: flex;
  justify-content: center;
  align-items: center; */

  // Themed Properties
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.lightGray};
  color: ${({ theme }) => theme.colors.darkGray};
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.07);

  &:hover {
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  }
`

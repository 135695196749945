/* eslint-disable react/no-array-index-key */
import React from 'react'
import PropTypes from 'prop-types'

// Styled Elements
import { Wrapper } from './QueryTable.elements'

// Views
import { AccordionTable } from 'views/components'
import { QueryTableItem, QueryTableHeader } from 'views/layouts'

function QueryTable(props) {
  // Destructure
  const { data } = props

  if (!data) return

  return (
    <Wrapper>
      <AccordionTable minWidth={300}>
        <QueryTableHeader />
        {data.parameters.map((param, i) => (
          <QueryTableItem key={i} data={param} />
        ))}
      </AccordionTable>
    </Wrapper>
  )
}

// Default Props
QueryTable.defaultProps = {
  data: [],
}

// Proptypes Validation
QueryTable.propTypes = {
  data: PropTypes.arrayOf({}),
}

export default QueryTable

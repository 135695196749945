/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-array-index-key */
import React from 'react'
import PropTypes from 'prop-types'
import ReactJson from 'react-json-view'

// Views
// import { CodeCard } from 'views/components'
import { QueryTable, ReqbodyTable, ResponseCard, ErrorsCard, EndpointsCard, SampleBodyCard } from 'views/layouts'

// Styled Elements
import {
  Wrapper,
  Container,
  Title,
  SubTitle,
  LineBreak,
  Body,
  Paragraph,
  RowContainer,
  BoxedBody,
  Spacer,
  LargeSpacer,
  DocContainer,
  DocWrapper,
  BoldText,
  BoxedText,
  CodeWrapper,
} from './DocumentationSection.elements'

function DocumentationSection(props) {
  // Destructure
  const { data } = props

  return (
    <Wrapper>
      <Container key="introduction" id="introduction">
        <DocWrapper>
          <DocContainer>
            <Title>Introduction</Title>
            <Spacer />
            <Spacer />
            <Body>
              {`Welcome to Ospree! Here you'll find comprehensive information for integrating with our API endpoints.
              We've tried to make this documentation user-friendly and example-filled, but if you have any questions,
              please head to our `}
              <a href="https://ospree.io/help">Help Desk</a>
              {`. If you're planning to use our API in
              Production, take a look at our Privacy Policy. This API reference is organized by resource type. Each
              resource type has one or more data representations and one or more methods.`}
            </Body>
            <Spacer />
            <Body>
              BASE URL PROTOTYPE: <a>https://prototype.ospree.io</a>
              <br />
              BASE URL SANdBOX: <a>https://sandbox.ospree.io</a>
              <br />
              BASE URL PRODUCTION: <a>https://api.ospree.io</a>
            </Body>
            <Spacer />
            <Body>
              You can find out more about Ospree at <a href="https://ospree.io">ospree.io</a>. If you have questions we
              encourage you to contact the development team at
              <a href="mailto:support@ospree.io"> support@ospree.io</a>
            </Body>
            <LargeSpacer />
          </DocContainer>
          <DocContainer />
        </DocWrapper>
      </Container>
      <Container key="apiKeys" id="apiKeys">
        <DocWrapper>
          <DocContainer>
            <Title>API Keys and Access</Title>
            <Spacer />
            <Spacer />
            <Body>
              {`Ospree offers bearer tokens as an authentication method for accessing endpoints. To gain access to the
              Ospree API, please contact our Sales Team. Once you've completed the onboarding process and acknowledged
              our terms, we'll provide you with a live access token.`}
            </Body>
            <Spacer />
            <Body>
              Our bearer tokens carry many privileges, so be sure to keep them secure! Do not share your tokens in
              publicly accessible areas such as GitHub, client-side code, and so forth. API requests without
              authentication will fail.
            </Body>
            <LargeSpacer />
          </DocContainer>
          <DocContainer />
        </DocWrapper>
      </Container>
      <Container key="apiProtocols" id="apiProtocols">
        <DocWrapper>
          <DocContainer>
            <Title>API Protocols</Title>
            <Spacer />
            <Spacer />
            <Body>
              The Ospree API uses GET, POST, PATCH, PUT and DELETE requests to communicate and HTTP response codes to
              indicate status and errors. Ospree API is served over HTTPS TLS v1.1+ to ensure data privacy. All
              responses come in standard JSON. All requests must include a Content-Type of application/json and the body
              must be valid JSON.
            </Body>
            <LargeSpacer />
          </DocContainer>
          <DocContainer />
        </DocWrapper>
      </Container>
      <Container key="breakingChanges" id="breakingChanges">
        <DocWrapper>
          <DocContainer>
            <Title>Breaking Changes</Title>
            <Spacer />
            <Spacer />
            <Body>
              We strive to avoid making any breaking changes to our API, but we do make changes over time that may
              result in changes to the data that you pull from Ospree API. We consider the following changes to be
              backward compatible:
              <br />
              <br />
              <li>Adding new API endpoints</li>
              <li>Adding new options parameters to existing endpoints</li>
              <li>Adding new data elements to existing response schemas</li>
              <li>Adding new error_types and error_codes</li>
            </Body>
            <LargeSpacer />
          </DocContainer>
          <DocContainer />
        </DocWrapper>
      </Container>
      <Container key="errors" id="errors">
        <DocWrapper>
          <DocContainer>
            <Title>Errors</Title>
            <Spacer />
            <Spacer />
            <Body>
              Ospree utilizes standard HTTP response codes to convey the outcome of an API request. In summary: Codes in
              the 2xx range denote success. Codes in the 4xx range signify an error due to inadequate information
              provided (e.g., missing required parameters, payment failure, etc.). Codes in the 5xx range denote server
              errors, which are uncommon.
            </Body>
            <LargeSpacer />
          </DocContainer>
          <DocContainer>
            <ErrorsCard />
          </DocContainer>
        </DocWrapper>
      </Container>
      {data?.address && (
        <Container key="address" id="address">
          <DocWrapper>
            <DocContainer>
              <Title>BLOCKCHAIN ANALYTICS • ADDRESSES</Title>
              <Spacer />
              <Spacer />
              <Body>
                The Blockchain Analytics Module provides screening results for addresses, encompassing financial and
                risk information. Financial details include balances and asset specifics, while risk information
                comprises risk scores and attributes available for address assessment. Users can employ it alongside
                other modules to analyze transaction flows or to link and reference customers, facilitating the viewing
                of activity history for a specific client or retrieving activity for their business. Typically, the
                screening endpoint responds in less than 30 seconds under normal operating conditions. Your screening
                capabilities may vary depending on your activation plan.
                <br />
                <br />
                In order to call these endpoints, you need to specify the address and chain (blockchains). This API
                version supports the following blockchains <BoxedText>bitcoin</BoxedText>,{' '}
                <BoxedText>ethereum</BoxedText>, <BoxedText>litecoin</BoxedText>, <BoxedText>bitcoin-cash</BoxedText>,{' '}
                <BoxedText>doge</BoxedText>, <BoxedText>solana</BoxedText>, <BoxedText>xdc</BoxedText>,{' '}
                <BoxedText>ripple</BoxedText>, <BoxedText>stellar</BoxedText>, <BoxedText>algorand</BoxedText>,{' '}
                <BoxedText>polygon</BoxedText>, <BoxedText>polkadot</BoxedText>, <BoxedText>tezos</BoxedText>,{' '}
                <BoxedText>near</BoxedText>, <BoxedText>avalanche</BoxedText>, <BoxedText>optimism</BoxedText>.
                <br />
                <br />
                Attributes:
                <li>status: Current status or condition of the entity or process.</li>
                <li>address: Unique public identifier for a wallet address.</li>
                <li>chain: Blockchain network name</li>
                <li>symbol: Currency or token symbol</li>
                <li>balance: Virtual asset quantity held by the address</li>
                <li>balance_usd: Virtual asset quantity held in USD by the address</li>
                <li>risk_ratio: Ratio of potential risk</li>
                <li>risk_descriptors: Descriptions indicating potential risk</li>
                <li>vasp_name_legal: Legal name of VASP</li>
                <li>vasp_name_business: Business name of VASP</li>
                <li>vasp_website: Website of VASP</li>
                <li>vasp_category: Category of Virtual Asset Service Provider</li>
                <li>modified_by_name: Name of modifying user</li>
                <li>created: Timestamp indicating when the request was made.</li>
                <li>address: Unique blockchain identifier.</li>
                <li>total: The total number of records.</li>
                <li>size: The size or number of records per page.</li>
                <li>page: The current page number in pagination.</li>
              </Body>
              <LargeSpacer />
            </DocContainer>
            <DocContainer>
              <EndpointsCard data={data?.address} />
            </DocContainer>
          </DocWrapper>
        </Container>
      )}
      {data?.address &&
        data?.address.map((doc, i) => {
          const requestBodyRef = doc?.requestBody?.content?.['application/json']?.schema?.$ref
          let reqBodyrequired
          if (requestBodyRef) {
            if (requestBodyRef?.required?.length > 0) {
              requestBodyRef?.required?.map((item) => {
                reqBodyrequired = { ...reqBodyrequired, [item]: true }
              })
            }
            Object.keys(requestBodyRef?.properties)?.forEach((item) => {
              if (requestBodyRef?.properties[item]?.$ref?.type === 'object') {
                requestBodyRef?.properties[item]?.$ref?.required?.map((item) => {
                  reqBodyrequired = { ...reqBodyrequired, [item]: true }
                })
              }
              if (requestBodyRef?.properties[item]?.items?.$ref?.type === 'object') {
                requestBodyRef?.properties[item]?.items?.$ref?.required?.map((item) => {
                  reqBodyrequired = { ...reqBodyrequired, [item]: true }
                })
              }
            })
          }
          const rawReqBody = {}
          if (doc?.requestBody?.content?.['application/json']?.schema?.$ref?.properties) {
            const properties = doc?.requestBody?.content?.['application/json']?.schema?.$ref?.properties
            const keys = Object.keys(properties)
            keys.forEach((bodykey) => {
              if (properties[bodykey]?.allOf?.length > 0) {
                properties[bodykey]?.allOf?.forEach((item) => {
                  if (item?.$ref?.properties) {
                    const innerProperty = item?.$ref?.properties
                    rawReqBody[bodykey] = {
                      ...properties[bodykey],
                      name: bodykey,
                      ...item?.$ref,
                      properties: null,
                    }
                    if (innerProperty) {
                      Object.keys(innerProperty).forEach((itemKey) => {
                        if (innerProperty[itemKey]?.$ref?.type === 'object') {
                          const childProperty = innerProperty[itemKey]?.$ref?.properties
                          rawReqBody[itemKey] = {
                            ...innerProperty[itemKey],
                            name: itemKey,
                            ...innerProperty[itemKey]?.$ref,
                            properties: null,
                          }
                          Object.keys(childProperty).forEach((childkey) => {
                            if (childProperty[childkey]?.allOf?.length > 0) {
                              rawReqBody[childkey] = { ...childProperty[childkey]?.allOf[0]?.$ref, name: childkey }
                            } else {
                              rawReqBody[childkey] = { ...childProperty[childkey], name: childkey }
                            }
                          })
                        } else if (innerProperty[itemKey]?.$ref) {
                          rawReqBody[itemKey] = { ...innerProperty[itemKey]?.$ref, name: itemKey }
                        } else if (innerProperty[itemKey]?.allOf?.length > 0) {
                          const childProperty = innerProperty[itemKey]?.allOf[0]?.$ref?.properties
                          if (childProperty) {
                            Object.keys(childProperty).forEach((childkey) => {
                              rawReqBody[childkey] = { ...childProperty[childkey], name: childkey }
                            })
                          }
                          rawReqBody[itemKey] = {
                            ...innerProperty[itemKey],
                            type: innerProperty[itemKey]?.allOf[0]?.$ref?.type,
                            name: itemKey,
                          }
                        } else {
                          rawReqBody[itemKey] = { ...innerProperty[itemKey], name: itemKey }
                        }
                      })
                    }
                  } else if (item?.$ref) {
                    const innerProperty = item?.$ref
                    rawReqBody[bodykey] = { ...innerProperty, name: bodykey }
                  }
                })
              }
              if (properties[bodykey]?.items?.$ref?.type === 'object') {
                const innerProperty = properties[bodykey]?.items?.$ref?.properties
                Object.keys(innerProperty).forEach((itemKey) => {
                  rawReqBody[itemKey] = { ...innerProperty[itemKey], name: itemKey }
                })
              }
              if (properties[bodykey]?.$ref?.type === 'object') {
                const innerProperty = properties[bodykey]?.$ref?.properties
                Object.keys(innerProperty).forEach((itemKey) => {
                  if (innerProperty[itemKey]?.$ref) {
                    rawReqBody[itemKey] = { ...innerProperty[itemKey]?.$ref, name: itemKey }
                  } else {
                    rawReqBody[itemKey] = { ...innerProperty[itemKey], name: itemKey }
                  }
                })
              }
              if (properties[bodykey]?.type === 'array') {
                rawReqBody[bodykey] = { ...properties[bodykey], name: bodykey }
              }
              if (properties[bodykey]?.type === 'string') {
                rawReqBody[bodykey] = { ...properties[bodykey], name: bodykey }
              }
            })
          }
          const reqBodyParams = []
          Object.keys(rawReqBody).forEach((key) => {
            if (rawReqBody[key]?.tag === 'hide') {
              return
            }
            reqBodyParams.push({ ...rawReqBody[key] })
          })
          const responses = []
          Object.keys(doc?.responses).map((key) => {
            if (doc?.responses?.[key]?.content?.['application/json']?.schema?.anyOf) {
              responses.push({
                code: key,
                content: doc?.responses?.[key]?.content?.['application/json']?.schema?.anyOf,
              })
              return
            }
            if (doc?.responses?.[key]?.content?.['application/json']?.schema) {
              responses.push({ code: key, content: [doc?.responses?.[key]?.content?.['application/json']?.schema] })
              return
            }
            if (doc?.responses?.[key]?.content?.['application/json']?.schema?.$ref) {
              responses.push({
                code: key,
                content: [doc?.responses?.[key]?.content?.['application/json']?.schema?.$ref],
              })
            }
          })

          const pathParams = []
          const queryParams = []
          if (doc?.parameters?.length > 0) {
            doc.parameters.forEach((item) => {
              if (item?.in === 'path') {
                pathParams.push(item)
              }
              if (item?.in === 'query') {
                queryParams.push(item)
              }
            })
          }
          return (
            <Container key={i} id={doc.summary}>
              <DocWrapper>
                <DocContainer>
                  <Title>{doc?.summary?.split(':').join(' | ')}</Title>
                  <Spacer />
                  <Body>
                    {doc?.description?.split('<br>')?.length > 0 &&
                      doc?.description?.split('<br>').map((word) => (
                        <div key={word}>
                          {word?.split(':')?.length > 1 ? (
                            <>
                              <BoldText>• {word?.split(':')[0]}: </BoldText> {word?.split(':')[1]}
                            </>
                          ) : (
                            <Paragraph>
                              <Body>{word}</Body>
                            </Paragraph>
                          )}
                        </div>
                      ))}
                  </Body>
                  <LargeSpacer />
                  {doc?.security?.length > 0 && (
                    <>
                      <SubTitle>Authorization</SubTitle>
                      <LineBreak />
                      <Spacer />
                      <Body>{Object.keys(doc?.security[0])}</Body>
                      <LargeSpacer />
                    </>
                  )}
                  {pathParams.length > 0 && (
                    <>
                      <SubTitle>Path Parameters</SubTitle>
                      <LineBreak />
                      <QueryTable data={{ parameters: pathParams }} />
                    </>
                  )}
                  {queryParams.length > 0 && (
                    <>
                      {pathParams.length > 0 && <LargeSpacer />}
                      <SubTitle>Query Parameters</SubTitle>
                      <LineBreak />
                      <QueryTable data={{ parameters: queryParams }} />
                    </>
                  )}
                  {reqBodyParams && reqBodyParams?.length > 0 && (
                    <>
                      {doc?.parameters?.length > 0 && <LargeSpacer />}
                      <SubTitle>Request Body</SubTitle>
                      <LineBreak />
                      <ReqbodyTable data={reqBodyParams} requiredList={reqBodyrequired} />
                    </>
                  )}
                </DocContainer>
                <DocContainer>
                  <SubTitle>Response</SubTitle>
                  <Spacer />
                  <RowContainer>
                    <BoxedBody>{doc?.method}</BoxedBody>
                    <Body>{doc?.endpoint}</Body>
                  </RowContainer>
                  <Spacer />
                  <ResponseCard data={responses} />
                  {doc?.requestBody && doc?.requestBody?.content?.['application/json']?.examples && (
                    <>
                      <LargeSpacer />
                      <SubTitle>Sample Request Body</SubTitle>
                      <LineBreak />
                      <SampleBodyCard data={doc?.requestBody?.content?.['application/json']?.examples} />
                    </>
                  )}
                </DocContainer>
              </DocWrapper>
            </Container>
          )
        })}

      {data?.transaction && (
        <Container key="transaction" id="transaction">
          <DocWrapper>
            <DocContainer>
              <Title>BLOCKCHAIN ANALYTICS • TRANSACTIONS</Title>
              <Spacer />
              <Spacer />
              <Body>
                The Ospree API seamlessly integrates with your existing systems, empowering you to automate and expand
                your business operations. Additionally, the Blockchain Analytics module provides resources for
                facilitating compliance implementation. Users can input the transaction hash or referral ID to swiftly
                obtain corresponding transaction information, with screening results including real-time snapshots of
                financial and risk data, generated at the time of the API query. Typically, our screening endpoint
                responds within 5 to 30 seconds under normal operating conditions.
                <br />
                <br />
                Attributes:
                <li>status: Current status or condition of the entity or process.</li>
                <li>block_id: Identifier of the block in which the transaction occurred. </li>
                <li>hash: Unique identifier for the transaction</li>
                <li>chain: Blockchain network name</li>
                <li>symbol: Currency or token symbol</li>
                <li>created: Timestamp indicating when the transaction was created</li>
                <li>in_count: Number of inputs in the transaction.</li>
                <li>in_native: Value of input in native currency/token</li>
                <li>in_usd: Value of input in USD</li>
                <li>out_count: Number of outputs in the transaction</li>
                <li>out_native: Value of output in native currency/token</li>
                <li>out_usd: Value of output in USD</li>
                <li>risk_descriptors: Descriptions indicating potential risk</li>
                <li>risk_ratio: Ratio of potential risk</li>
                <li>in_risk_ratio: Ratio of potential risk for inputs</li>
                <li>out_risk_ratio: Ratio of potential risk for outputs</li>
                <li>type: Type of transaction</li>
                <li>destination: Address where the transaction was sent</li>
                <li>source: Address from where the transaction originated</li>
                <li>amount: Quantity of currency/token transferred in the transaction</li>
                <li>amount_usd: Value of currency/token transferred in USD</li>
                <li>decimals: Number of decimals for the currency/token</li>
                <li>denomination: Denomination of the currency/token</li>
                <li>contract: Address of the smart contract (if applicable)</li>
                <li>contract_source: Source code of the smart contract (if applicable)</li>
                <li>token_type: Type of token (e.g., ERC20, BEP-20)</li>
                <li>last_modified: Timestamp indicating when the transaction was last modified</li>
                <li>fee_amount_usd: Fee amount of the transaction in USD</li>
                <li>asset_count: Number of assets involved in the transaction</li>
                {/* <li>transaction_time: Timestamp indicating the time of the transaction</li> */}
              </Body>
              <LargeSpacer />
            </DocContainer>
            <DocContainer>
              <EndpointsCard data={data?.transaction} />
            </DocContainer>
          </DocWrapper>
        </Container>
      )}
      {data?.transaction &&
        data?.transaction.map((doc, i) => {
          const requestBodyRef = doc?.requestBody?.content?.['application/json']?.schema?.$ref
          let reqBodyrequired
          if (requestBodyRef) {
            if (requestBodyRef?.required?.length > 0) {
              requestBodyRef?.required?.map((item) => {
                reqBodyrequired = { ...reqBodyrequired, [item]: true }
              })
            }
          }
          let requestBodyProperties
          if (doc?.requestBody?.content?.['application/json']?.schema?.$ref?.properties) {
            const properties = doc?.requestBody?.content?.['application/json']?.schema?.$ref?.properties
            requestBodyProperties = Object.keys(
              doc?.requestBody?.content?.['application/json']?.schema?.$ref?.properties
            ).map((bodykey) => ({ ...properties[bodykey], name: bodykey }))
          }
          const responses = []
          Object.keys(doc?.responses).map((key) => {
            if (doc?.responses?.[key]?.content?.['application/json']?.schema?.anyOf) {
              responses.push({
                code: key,
                content: doc?.responses?.[key]?.content?.['application/json']?.schema?.anyOf,
              })
              return
            }
            if (doc?.responses?.[key]?.content?.['application/json']?.schema) {
              responses.push({ code: key, content: [doc?.responses?.[key]?.content?.['application/json']?.schema] })
              return
            }
            if (doc?.responses?.[key]?.content?.['application/json']?.schema?.$ref) {
              responses.push({
                code: key,
                content: [doc?.responses?.[key]?.content?.['application/json']?.schema?.$ref],
              })
            }
          })
          const pathParams = []
          const queryParams = []
          if (doc?.parameters?.length > 0) {
            doc.parameters.forEach((item) => {
              if (item?.in === 'path') {
                pathParams.push(item)
              }
              if (item?.in === 'query') {
                queryParams.push(item)
              }
            })
          }
          return (
            <Container key={i} id={doc.summary}>
              <DocWrapper>
                <DocContainer>
                  <Title>{doc?.summary?.split(':').join(' | ')}</Title>
                  <Spacer />
                  <Body>
                    {doc?.description?.split('<br>')?.length > 0 &&
                      doc?.description?.split('<br>').map((word) => (
                        <div key={word}>
                          {word?.split(':')?.length > 1 ? (
                            <>
                              <BoldText>• {word?.split(':')[0]}: </BoldText> {word?.split(':')[1]}
                            </>
                          ) : (
                            <Paragraph>
                              <Body>
                                {word?.split('~')?.length > 1 ? (
                                  <>
                                    {word?.split('~')[0]}
                                    {word
                                      ?.split('~')[1]
                                      ?.split(',')
                                      .map((item, i) => (
                                        <span key={i}>
                                          <BoxedText>{item?.trim()}</BoxedText>
                                          {i + 1 < word?.split('~')[1]?.split(',')?.length && ', '}
                                        </span>
                                      ))}
                                  </>
                                ) : (
                                  <Paragraph>
                                    <Body>{word}</Body>
                                  </Paragraph>
                                )}
                              </Body>
                            </Paragraph>
                          )}
                        </div>
                      ))}
                  </Body>
                  <LargeSpacer />
                  {doc?.security?.length > 0 && (
                    <>
                      <SubTitle>Authorization</SubTitle>
                      <LineBreak />
                      <Spacer />
                      <Body>{Object.keys(doc?.security[0])}</Body>
                      <LargeSpacer />
                    </>
                  )}
                  {pathParams.length > 0 && (
                    <>
                      <SubTitle>Path Parameters</SubTitle>
                      <LineBreak />
                      <QueryTable data={{ parameters: pathParams }} />
                    </>
                  )}
                  {queryParams.length > 0 && (
                    <>
                      {pathParams.length > 0 && <LargeSpacer />}
                      <SubTitle>Query Parameters</SubTitle>
                      <LineBreak />
                      <QueryTable data={{ parameters: queryParams }} />
                    </>
                  )}
                  {requestBodyProperties && requestBodyProperties?.length > 0 && (
                    <>
                      {doc?.parameters?.length > 0 && <LargeSpacer />}
                      <SubTitle>Request Body</SubTitle>
                      <LineBreak />
                      <ReqbodyTable data={requestBodyProperties} requiredList={reqBodyrequired} />
                    </>
                  )}
                </DocContainer>
                <DocContainer>
                  <SubTitle>Response</SubTitle>
                  <Spacer />
                  <RowContainer>
                    <BoxedBody>{doc?.method}</BoxedBody>
                    <Body>{doc?.endpoint}</Body>
                  </RowContainer>
                  <Spacer />
                  <ResponseCard data={responses} />
                </DocContainer>
              </DocWrapper>
            </Container>
          )
        })}

      {data?.directory && (
        <Container key="directory" id="directory">
          <DocWrapper>
            <DocContainer>
              <Title>DIRECTORY MODULE</Title>
              <Spacer />
              <Spacer />
              <Body>
                The Directory module enhances operational efficiency by simplifying account and VASP data management
                processes. It facilitates account creation, deletion, and updates, ensuring that account information
                remains current. It also enables VASP data queries. This module allows for preemptive account creation
                before utilizing other modules. Additionally, it enables the creation or modification of associations
                with accounts, which is especially beneficial when integrated with other modules for analytics, travel
                rule transfers, and reporting purposes. Data in this module is IVMS101 compatible, serving as the
                universal common language for communication of required originator and beneficiary information between
                VASPs, used by all leading Travel Rule solution providers.
              </Body>
              <LargeSpacer />
            </DocContainer>
            <DocContainer>
              <EndpointsCard data={data?.directory} />
            </DocContainer>
          </DocWrapper>
        </Container>
      )}
      {data?.directory &&
        data?.directory.map((doc, i) => {
          const requestBodyRef = doc?.requestBody?.content?.['application/json']?.schema?.$ref
          let reqBodyrequired
          if (requestBodyRef) {
            if (requestBodyRef?.required?.length > 0) {
              requestBodyRef?.required?.map((item) => {
                reqBodyrequired = { ...reqBodyrequired, [item]: true }
              })
            }
            Object.keys(requestBodyRef?.properties)?.forEach((item) => {
              if (requestBodyRef?.properties[item]?.$ref?.type === 'object') {
                requestBodyRef?.properties[item]?.$ref?.required?.map((item) => {
                  reqBodyrequired = { ...reqBodyrequired, [item]: true }
                })
              }
              if (requestBodyRef?.properties[item]?.items?.$ref?.type === 'object') {
                requestBodyRef?.properties[item]?.items?.$ref?.required?.map((item) => {
                  reqBodyrequired = { ...reqBodyrequired, [item]: true }
                })
              }
            })
          }
          const rawReqBody = {}
          if (doc?.requestBody?.content?.['application/json']?.schema?.$ref?.properties) {
            const properties = doc?.requestBody?.content?.['application/json']?.schema?.$ref?.properties
            const keys = Object.keys(properties)
            keys.forEach((bodykey) => {
              if (properties[bodykey]?.allOf?.length > 0) {
                properties[bodykey]?.allOf?.forEach((item) => {
                  if (item?.$ref?.properties) {
                    const innerProperty = item?.$ref?.properties
                    rawReqBody[bodykey] = {
                      ...properties[bodykey],
                      name: bodykey,
                      ...item?.$ref,
                      properties: null,
                    }
                    if (innerProperty) {
                      Object.keys(innerProperty).forEach((itemKey) => {
                        if (innerProperty[itemKey]?.$ref?.type === 'object') {
                          const childProperty = innerProperty[itemKey]?.$ref?.properties
                          rawReqBody[itemKey] = {
                            ...innerProperty[itemKey],
                            name: itemKey,
                            ...innerProperty[itemKey]?.$ref,
                            properties: null,
                          }
                          Object.keys(childProperty).forEach((childkey) => {
                            if (childProperty[childkey]?.allOf?.length > 0) {
                              rawReqBody[childkey] = { ...childProperty[childkey]?.allOf[0]?.$ref, name: childkey }
                            } else {
                              rawReqBody[childkey] = { ...childProperty[childkey], name: childkey }
                            }
                          })
                        } else if (innerProperty[itemKey]?.$ref) {
                          rawReqBody[itemKey] = { ...innerProperty[itemKey]?.$ref, name: itemKey }
                        } else if (innerProperty[itemKey]?.allOf?.length > 0) {
                          const childProperty = innerProperty[itemKey]?.allOf[0]?.$ref?.properties
                          if (childProperty) {
                            Object.keys(childProperty).forEach((childkey) => {
                              rawReqBody[childkey] = { ...childProperty[childkey], name: childkey }
                            })
                          }
                          rawReqBody[itemKey] = {
                            ...innerProperty[itemKey],
                            type: innerProperty[itemKey]?.allOf[0]?.$ref?.type,
                            name: itemKey,
                          }
                        } else {
                          rawReqBody[itemKey] = { ...innerProperty[itemKey], name: itemKey }
                        }
                      })
                    }
                  } else if (item?.$ref) {
                    const innerProperty = item?.$ref
                    rawReqBody[bodykey] = { ...innerProperty, name: bodykey }
                  }
                })
              }
              if (properties[bodykey]?.items?.$ref?.type === 'object') {
                const innerProperty = properties[bodykey]?.items?.$ref?.properties
                Object.keys(innerProperty).forEach((itemKey) => {
                  rawReqBody[itemKey] = { ...innerProperty[itemKey], name: itemKey }
                })
              }
              if (properties[bodykey]?.$ref?.type === 'object') {
                const innerProperty = properties[bodykey]?.$ref?.properties
                Object.keys(innerProperty).forEach((itemKey) => {
                  if (innerProperty[itemKey]?.$ref) {
                    rawReqBody[itemKey] = { ...innerProperty[itemKey]?.$ref, name: itemKey }
                  } else {
                    rawReqBody[itemKey] = { ...innerProperty[itemKey], name: itemKey }
                  }
                })
              }
              if (properties[bodykey]?.type === 'array') {
                rawReqBody[bodykey] = { ...properties[bodykey], name: bodykey }
              }
              if (properties[bodykey]?.type === 'string') {
                rawReqBody[bodykey] = { ...properties[bodykey], name: bodykey }
              }
            })
          }
          const reqBodyParams = []
          Object.keys(rawReqBody).forEach((key) => {
            if (rawReqBody[key]?.tag === 'hide') {
              return
            }
            reqBodyParams.push({ ...rawReqBody[key] })
          })
          const responses = []
          Object.keys(doc?.responses).map((key) => {
            if (doc?.responses?.[key]?.content?.['application/json']?.schema?.anyOf) {
              responses.push({
                code: key,
                content: doc?.responses?.[key]?.content?.['application/json']?.schema?.anyOf,
              })
              return
            }
            if (doc?.responses?.[key]?.content?.['application/json']?.schema) {
              responses.push({ code: key, content: [doc?.responses?.[key]?.content?.['application/json']?.schema] })
              return
            }
            if (doc?.responses?.[key]?.content?.['application/json']?.schema?.$ref) {
              responses.push({
                code: key,
                content: [doc?.responses?.[key]?.content?.['application/json']?.schema?.$ref],
              })
            }
          })
          const pathParams = []
          const queryParams = []
          if (doc?.parameters?.length > 0) {
            doc.parameters.forEach((item) => {
              if (item?.in === 'path') {
                pathParams.push(item)
              }
              if (item?.in === 'query') {
                queryParams.push(item)
              }
            })
          }
          return (
            <Container key={i} id={doc.summary}>
              <DocWrapper>
                <DocContainer>
                  <Title>{doc?.summary?.split(':').join(' | ')}</Title>
                  <Spacer />
                  <Body>
                    {doc?.description?.split('<br>')?.length > 0 &&
                      doc?.description?.split('<br>').map((word) => (
                        <div key={word}>
                          {word?.split(':')?.length > 1 ? (
                            <>
                              <BoldText>• {word?.split(':')[0]}: </BoldText> {word?.split(':')[1]}
                            </>
                          ) : (
                            <Paragraph>
                              <Body>
                                {word?.split('~')?.length > 1 ? (
                                  <>
                                    {word?.split('~')[0]}
                                    {word
                                      ?.split('~')[1]
                                      ?.split(',')
                                      .map((item, i) => (
                                        <span key={i}>
                                          <BoxedText>{item?.trim()}</BoxedText>
                                          {i + 1 < word?.split('~')[1]?.split(',')?.length && ', '}
                                        </span>
                                      ))}
                                  </>
                                ) : (
                                  <Paragraph>
                                    <Body>{word}</Body>
                                  </Paragraph>
                                )}
                              </Body>
                            </Paragraph>
                          )}
                        </div>
                      ))}
                  </Body>
                  <LargeSpacer />
                  {doc?.security?.length > 0 && (
                    <>
                      <SubTitle>Authorization</SubTitle>
                      <LineBreak />
                      <Spacer />
                      <Body>{Object.keys(doc?.security[0])}</Body>
                      <LargeSpacer />
                    </>
                  )}
                  {pathParams.length > 0 && (
                    <>
                      <SubTitle>Path Parameters</SubTitle>
                      <LineBreak />
                      <QueryTable data={{ parameters: pathParams }} />
                    </>
                  )}
                  {queryParams.length > 0 && (
                    <>
                      {pathParams.length > 0 && <LargeSpacer />}
                      <SubTitle>Query Parameters</SubTitle>
                      <LineBreak />
                      <QueryTable data={{ parameters: queryParams }} />
                    </>
                  )}
                  {reqBodyParams && reqBodyParams?.length > 0 && (
                    <>
                      {doc?.parameters?.length > 0 && <LargeSpacer />}
                      <SubTitle>Request Body</SubTitle>
                      <LineBreak />
                      <ReqbodyTable data={reqBodyParams} requiredList={reqBodyrequired} />
                    </>
                  )}
                </DocContainer>
                <DocContainer>
                  <SubTitle>Response</SubTitle>
                  <Spacer />
                  <RowContainer>
                    <BoxedBody>{doc?.method}</BoxedBody>
                    <Body>{doc?.endpoint}</Body>
                  </RowContainer>
                  <Spacer />
                  <ResponseCard data={responses} />
                  {doc?.requestBody && doc?.requestBody?.content?.['application/json']?.examples && (
                    <>
                      <LargeSpacer />
                      <SubTitle>Sample Request Body</SubTitle>
                      <LineBreak />
                      <SampleBodyCard data={doc?.requestBody?.content?.['application/json']?.examples} />
                    </>
                  )}
                </DocContainer>
              </DocWrapper>
            </Container>
          )
        })}
      {data?.directory && (
        <Container key="transfer" id="transfer">
          <DocWrapper>
            <DocContainer>
              <Title>TRAVEL RULE MODULE</Title>
              <Spacer />
              <Spacer />
              <Body>
                The Travel Rule Module offers secure endpoints for transmitting and verifying required information,
                enabling VASPs to initiate, monitor, and generate reports to ensure adherence to regulatory compliance
                within the cryptocurrency ecosystem. Data in this module is IVMS101 compatible, serving as the universal
                common language for communication of required originator and beneficiary information between VASPs.
              </Body>
              <LargeSpacer />
            </DocContainer>
            <DocContainer>
              <EndpointsCard data={data?.transfer} />
            </DocContainer>
          </DocWrapper>
        </Container>
      )}
      {data?.transfer &&
        data?.transfer.map((doc, i) => {
          const requestBodyRef = doc?.requestBody?.content?.['application/json']?.schema?.$ref
          let reqBodyrequired
          if (requestBodyRef) {
            if (requestBodyRef?.required?.length > 0) {
              requestBodyRef?.required?.map((item) => {
                reqBodyrequired = { ...reqBodyrequired, [item]: true }
              })
            }
            Object.keys(requestBodyRef?.properties)?.forEach((item) => {
              if (requestBodyRef?.properties[item]?.$ref?.type === 'object') {
                requestBodyRef?.properties[item]?.$ref?.required?.map((item) => {
                  reqBodyrequired = { ...reqBodyrequired, [item]: true }
                })
              }
              if (requestBodyRef?.properties[item]?.items?.$ref?.type === 'object') {
                requestBodyRef?.properties[item]?.items?.$ref?.required?.map((item) => {
                  reqBodyrequired = { ...reqBodyrequired, [item]: true }
                })
              }
            })
          }
          const rawReqBody = {}
          if (doc?.requestBody?.content?.['application/json']?.schema?.$ref?.properties) {
            const properties = doc?.requestBody?.content?.['application/json']?.schema?.$ref?.properties
            const keys = Object.keys(properties)
            keys.forEach((bodykey) => {
              if (properties[bodykey]?.allOf?.length > 0) {
                properties[bodykey]?.allOf?.forEach((item) => {
                  if (item?.$ref?.properties) {
                    const innerProperty = item?.$ref?.properties
                    rawReqBody[bodykey] = {
                      ...properties[bodykey],
                      name: bodykey,
                      ...item?.$ref,
                      properties: null,
                    }
                    if (innerProperty) {
                      Object.keys(innerProperty).forEach((itemKey) => {
                        if (innerProperty[itemKey]?.$ref?.type === 'object') {
                          const childProperty = innerProperty[itemKey]?.$ref?.properties
                          rawReqBody[itemKey] = {
                            ...innerProperty[itemKey],
                            name: itemKey,
                            ...innerProperty[itemKey]?.$ref,
                            properties: null,
                          }
                          Object.keys(childProperty).forEach((childkey) => {
                            if (childProperty[childkey]?.allOf?.length > 0) {
                              rawReqBody[childkey] = { ...childProperty[childkey]?.allOf[0]?.$ref, name: childkey }
                            } else {
                              rawReqBody[childkey] = { ...childProperty[childkey], name: childkey }
                            }
                          })
                        } else if (innerProperty[itemKey]?.$ref) {
                          rawReqBody[itemKey] = { ...innerProperty[itemKey]?.$ref, name: itemKey }
                        } else if (innerProperty[itemKey]?.allOf?.length > 0) {
                          const childProperty = innerProperty[itemKey]?.allOf[0]?.$ref?.properties
                          if (childProperty) {
                            Object.keys(childProperty).forEach((childkey) => {
                              rawReqBody[childkey] = { ...childProperty[childkey], name: childkey }
                            })
                          }
                          rawReqBody[itemKey] = {
                            ...innerProperty[itemKey],
                            type: innerProperty[itemKey]?.allOf[0]?.$ref?.type,
                            name: itemKey,
                          }
                        } else {
                          rawReqBody[itemKey] = { ...innerProperty[itemKey], name: itemKey }
                        }
                      })
                    }
                  } else if (item?.$ref) {
                    const innerProperty = item?.$ref
                    rawReqBody[bodykey] = { ...innerProperty, name: bodykey }
                  }
                })
              }
              if (properties[bodykey]?.items?.$ref?.type === 'object') {
                const innerProperty = properties[bodykey]?.items?.$ref?.properties
                Object.keys(innerProperty).forEach((itemKey) => {
                  rawReqBody[itemKey] = { ...innerProperty[itemKey], name: itemKey }
                })
              }
              if (properties[bodykey]?.$ref?.type === 'object') {
                const innerProperty = properties[bodykey]?.$ref?.properties
                Object.keys(innerProperty).forEach((itemKey) => {
                  if (innerProperty[itemKey]?.$ref) {
                    rawReqBody[itemKey] = { ...innerProperty[itemKey]?.$ref, name: itemKey }
                  } else {
                    rawReqBody[itemKey] = { ...innerProperty[itemKey], name: itemKey }
                  }
                })
              }
              if (properties[bodykey]?.type === 'array') {
                rawReqBody[bodykey] = { ...properties[bodykey], name: bodykey }
              }
              if (properties[bodykey]?.type === 'string') {
                rawReqBody[bodykey] = { ...properties[bodykey], name: bodykey }
              }
            })
          }
          const reqBodyParams = []
          Object.keys(rawReqBody).forEach((key) => {
            if (rawReqBody[key]?.tag === 'hide') {
              return
            }
            reqBodyParams.push({ ...rawReqBody[key] })
          })
          const responses = []
          Object.keys(doc?.responses).map((key) => {
            if (doc?.responses?.[key]?.content?.['application/json']?.schema?.anyOf) {
              responses.push({
                code: key,
                content: doc?.responses?.[key]?.content?.['application/json']?.schema?.anyOf,
              })
              return
            }
            if (doc?.responses?.[key]?.content?.['application/json']?.schema) {
              responses.push({ code: key, content: [doc?.responses?.[key]?.content?.['application/json']?.schema] })
              return
            }
            if (doc?.responses?.[key]?.content?.['application/json']?.schema?.$ref) {
              responses.push({
                code: key,
                content: [doc?.responses?.[key]?.content?.['application/json']?.schema?.$ref],
              })
            }
          })
          const pathParams = []
          const queryParams = []
          if (doc?.parameters?.length > 0) {
            doc.parameters.forEach((item) => {
              if (item?.in === 'path') {
                pathParams.push(item)
              }
              if (item?.in === 'query') {
                queryParams.push(item)
              }
            })
          }
          return (
            <Container key={i} id={doc.summary}>
              <DocWrapper>
                <DocContainer>
                  <Title>{doc?.summary?.split(':').join(' | ')}</Title>
                  <Spacer />
                  <Body>
                    {doc?.description?.split('<br>')?.length > 0 &&
                      doc?.description?.split('<br>').map((word) => (
                        <div key={word}>
                          {word?.split(':')?.length > 1 ? (
                            <>
                              <BoldText>• {word?.split(':')[0]}: </BoldText> {word?.split(':')[1]}
                            </>
                          ) : (
                            <Paragraph>
                              <Body>
                                {word?.split('~')?.length > 1 ? (
                                  <>
                                    {word?.split('~')[0]}
                                    {word
                                      ?.split('~')[1]
                                      ?.split(',')
                                      .map((item, i) => (
                                        <span key={i}>
                                          <BoxedText>{item?.trim()}</BoxedText>
                                          {i + 1 < word?.split('~')[1]?.split(',')?.length && ', '}
                                        </span>
                                      ))}
                                  </>
                                ) : (
                                  <Paragraph>
                                    <Body>{word}</Body>
                                  </Paragraph>
                                )}
                              </Body>
                            </Paragraph>
                          )}
                        </div>
                      ))}
                  </Body>
                  <LargeSpacer />
                  {doc?.security?.length > 0 && (
                    <>
                      <SubTitle>Authorization</SubTitle>
                      <LineBreak />
                      <Spacer />
                      <Body>{Object.keys(doc?.security[0])}</Body>
                      <LargeSpacer />
                    </>
                  )}
                  {pathParams.length > 0 && (
                    <>
                      <SubTitle>Path Parameters</SubTitle>
                      <LineBreak />
                      <QueryTable data={{ parameters: pathParams }} />
                    </>
                  )}
                  {queryParams.length > 0 && (
                    <>
                      {pathParams.length > 0 && <LargeSpacer />}
                      <SubTitle>Query Parameters</SubTitle>
                      <LineBreak />
                      <QueryTable data={{ parameters: queryParams }} />
                    </>
                  )}
                  {reqBodyParams && reqBodyParams?.length > 0 && (
                    <>
                      {doc?.parameters?.length > 0 && <LargeSpacer />}
                      <SubTitle>Request Body</SubTitle>
                      <LineBreak />
                      <ReqbodyTable data={reqBodyParams} requiredList={reqBodyrequired} />
                    </>
                  )}
                </DocContainer>
                <DocContainer>
                  <SubTitle>Response</SubTitle>
                  <Spacer />
                  <RowContainer>
                    <BoxedBody>{doc?.method}</BoxedBody>
                    <Body>{doc?.endpoint}</Body>
                  </RowContainer>
                  <Spacer />
                  <ResponseCard data={responses} />
                  {doc?.requestBody && doc?.requestBody?.content?.['application/json']?.examples && (
                    <>
                      <LargeSpacer />
                      <SubTitle>Sample Request Body</SubTitle>
                      <LineBreak />
                      <SampleBodyCard data={doc?.requestBody?.content?.['application/json']?.examples} />
                    </>
                  )}
                </DocContainer>
              </DocWrapper>
            </Container>
          )
        })}
    </Wrapper>
  )
}

// Default Props
DocumentationSection.defaultProps = {}

// Proptypes Validation
DocumentationSection.propTypes = {
  data: PropTypes.shape({
    address: PropTypes.arrayOf(),
    transaction: PropTypes.arrayOf(),
    directory: PropTypes.arrayOf(),
    transfer: PropTypes.arrayOf(),
  }),
}

export default DocumentationSection

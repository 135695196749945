/* eslint-disable max-len */
export const AddressData = [
  {
    endpoint: '/api/v2/analytics/chains/{chain}/addresses/{address}',
    method: 'post',
    tags: ['v2'],
    summary: 'Screen An Address',
    description:
      'Screen address hashes to identify potential irregularities or suspicious activities within the blockchain ecosystem, gaining detailed insights into associated entities and flagged risk indicators.',
    operationId: 'Screen_an_address_api_v2_analytics_chains__chain__addresses__address__post',
    security: [
      {
        HTTPBearer: [],
      },
    ],
    parameters: [
      {
        name: 'chain',
        in: 'path',
        required: true,
        schema: {
          title: 'Chain',
          description: 'Blockchain network name.',
          type: 'string',
        },
        description: 'Blockchain network name.',
        examples: 'bitcoin',
      },
      {
        name: 'address',
        in: 'path',
        required: true,
        schema: {
          title: 'Address',
          description: 'Unique blockchain identifier.',
          type: 'string',
        },
        description: 'Unique blockchain identifier.',
        examples: '1JjYpRXfPntVEJqAjxsYmvFjjJBdJyaJ2k',
      },
    ],
    requestBody: {
      content: {
        'application/json': {
          schema: {
            $ref: {
              title: 'AddressParamsInRequest',
              required: ['provider_name'],
              type: 'object',
              properties: {
                provider_name: {
                  title: 'Provider Name',
                  type: 'string',
                  description: 'The name of the risk provider',
                  examples: ['RDBIG', 'RDCFI', 'RDCRY'],
                },
              },
            },
          },
          examples: [
            {
              provider_name: 'RDBIG',
            },
          ],
        },
      },
    },
    responses: {
      200: {
        description: 'Successful Response',
        content: {
          'application/json': {
            schema: {
              $ref: {
                title: 'ADD01ResponseCom',
                type: 'object',
                properties: {
                  status: {
                    title: 'Status',
                    type: 'string',
                    default: 'success',
                    examples: 'success',
                  },
                  data: {
                    $ref: {
                      title: 'AddressDataInPostResponse',
                      required: ['address', 'chain', 'created'],
                      type: 'object',
                      properties: {
                        address: {
                          title: 'Address',
                          type: 'string',
                          description: 'Unique blockchain identifier.',
                          examples: '0xfec8a60023265364d066a1212fde3930f6ae8da7',
                        },
                        chain: {
                          title: 'Chain',
                          type: 'string',
                          description: 'Blockchain network name.',
                          examples: 'ethereum',
                        },
                        symbol: {
                          title: 'Symbol',
                          type: 'string',
                          description: 'Currency or token symbol.',
                          examples: 'ETH',
                        },
                        financial_data: {
                          $ref: {
                            title: 'AddressFinancialData',
                            type: 'object',
                            properties: {
                              balance: {
                                title: 'Balance',
                                type: 'number',
                                description: 'Virtual asset quantity held by the address at the moment of the check.',
                                examples: 325836859470708000,
                              },
                              balance_usd: {
                                title: 'Balance Usd',
                                type: 'number',
                                description:
                                  'Virtual asset quantity held in USD by the address at the moment of the check.',
                                examples: 422.017383557692,
                              },
                            },
                          },
                        },
                        risk_data: {
                          title: 'Risk Data',
                          type: 'array',
                          items: {
                            $ref: {
                              title: 'AddressRiskData',
                              type: 'object',
                              properties: {
                                provider_name: {
                                  title: 'Provider Name',
                                  type: 'string',
                                  description: 'The name of the risk provider.',
                                  examples: 'RDBIG',
                                },
                                risk_ratio: {
                                  title: 'Risk Ratio',
                                  type: 'integer',
                                  description: 'Ratio of potential risk.',
                                  examples: 100,
                                },
                                risk_descriptors: {
                                  title: 'Risk Descriptors',
                                  type: 'array',
                                  items: {
                                    type: 'string',
                                  },
                                  description: 'Descriptions indicating potential risk.',
                                  examples: [
                                    'terrorism',
                                    'exchange_client',
                                    'binance_client_deposit',
                                    'binance_client_withdrawal',
                                    'binance_deposit_address',
                                    'Sanctioned OFAC',
                                    'sent_to_binance',
                                  ],
                                },
                                vasp_name_legal: {
                                  title: 'Vasp Name Legal',
                                  type: 'string',
                                  description: 'Legal name of VASP.',
                                  examples: 'Binance,Suex.Io',
                                },
                                vasp_name_business: {
                                  title: 'Vasp Name Business',
                                  type: 'string',
                                  description: 'Business name of VASP.',
                                  examples: 'binance,suex.io',
                                },
                                vasp_website: {
                                  title: 'Vasp Website',
                                  type: 'string',
                                  description: 'Website of VASP.',
                                  examples: 'https://www.binance.com,https://suex.io/',
                                },
                                vasp_category: {
                                  title: 'Vasp Category',
                                  type: 'string',
                                  description: 'Category of Virtual Asset Service Provider.',
                                  examples: 'exchange,exchange',
                                },
                                created: {
                                  title: 'Created',
                                  type: 'string',
                                  description: 'Request or create timestamp.',
                                  format: 'date-time',
                                  examples: '2024-01-02T21:46:03.773244+00:00',
                                },
                              },
                            },
                          },
                        },
                        created: {
                          title: 'Created',
                          type: 'string',
                          description: 'Request or create timestamp.',
                          format: 'date-time',
                          examples: '2024-01-12T04:56:42.896740',
                        },
                      },
                    },
                  },
                  message: {
                    title: 'Message',
                    type: 'string',
                    examples: 'Null',
                  },
                },
              },
            },
          },
        },
      },
      422: {
        description: 'Validation Error',
        content: {
          'application/json': {
            schema: {
              $ref: {
                title: 'HTTPValidationError',
                type: 'object',
                properties: {
                  errors: {
                    title: 'Errors',
                    type: 'array',
                    items: {
                      $ref: {
                        title: 'ValidationError',
                        required: ['loc', 'msg', 'type'],
                        type: 'object',
                        properties: {
                          loc: {
                            title: 'Location',
                            type: 'array',
                            items: {
                              anyOf: [
                                {
                                  type: 'string',
                                },
                                {
                                  type: 'integer',
                                },
                              ],
                            },
                          },
                          msg: {
                            title: 'Message',
                            type: 'string',
                          },
                          type: {
                            title: 'Error Type',
                            type: 'string',
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
  {
    endpoint: '/api/v2/analytics/chains/{chain}/addresses/{address}',
    method: 'get',
    tags: ['v2'],
    summary: 'Retrieve An Address',
    description: 'Returns an screening result for the requesting address hash or referral id.',
    operationId: 'Retrieve_an_address_api_v2_analytics_chains__chain__addresses__address__get',
    security: [
      {
        HTTPBearer: [],
      },
    ],
    parameters: [
      {
        name: 'chain',
        in: 'path',
        required: true,
        schema: {
          title: 'Chain',
          description: 'Blockchain network name.',
          type: 'string',
        },
        description: 'Blockchain network name.',
        examples: 'bitcoin',
      },
      {
        name: 'address',
        in: 'path',
        required: true,
        schema: {
          title: 'Address',
          description: 'Unique blockchain identifier.',
          type: 'string',
        },
        description: 'Unique blockchain identifier.',
        examples: '1JjYpRXfPntVEJqAjxsYmvFjjJBdJyaJ2k',
      },
    ],
    responses: {
      200: {
        description: 'Successful Response',
        content: {
          'application/json': {
            schema: {
              $ref: {
                title: 'ADD06ResponseCom',
                type: 'object',
                properties: {
                  status: {
                    title: 'Status',
                    type: 'string',
                    default: 'success',
                    examples: 'success',
                  },
                  data: {
                    $ref: {
                      title: 'AddressDataInPostResponse',
                      required: ['address', 'chain', 'created'],
                      type: 'object',
                      properties: {
                        address: {
                          title: 'Address',
                          type: 'string',
                          description: 'Unique blockchain identifier.',
                          examples: '0xfec8a60023265364d066a1212fde3930f6ae8da7',
                        },
                        chain: {
                          title: 'Chain',
                          type: 'string',
                          description: 'Blockchain network name.',
                          examples: 'ethereum',
                        },
                        symbol: {
                          title: 'Symbol',
                          type: 'string',
                          description: 'Currency or token symbol.',
                          examples: 'ETH',
                        },
                        financial_data: {
                          $ref: {
                            title: 'AddressFinancialData',
                            type: 'object',
                            properties: {
                              balance: {
                                title: 'Balance',
                                type: 'number',
                                description: 'Virtual asset quantity held by the address at the moment of the check.',
                                examples: 325836859470708000,
                              },
                              balance_usd: {
                                title: 'Balance Usd',
                                type: 'number',
                                description:
                                  'Virtual asset quantity held in USD by the address at the moment of the check.',
                                examples: 422.017383557692,
                              },
                            },
                          },
                        },
                        risk_data: {
                          title: 'Risk Data',
                          type: 'array',
                          items: {
                            $ref: {
                              title: 'AddressRiskData',
                              type: 'object',
                              properties: {
                                provider_name: {
                                  title: 'Provider Name',
                                  type: 'string',
                                  description: 'The name of the risk provider.',
                                  examples: 'RDBIG',
                                },
                                risk_ratio: {
                                  title: 'Risk Ratio',
                                  type: 'integer',
                                  description: 'Ratio of potential risk.',
                                  examples: 100,
                                },
                                risk_descriptors: {
                                  title: 'Risk Descriptors',
                                  type: 'array',
                                  items: {
                                    type: 'string',
                                  },
                                  description: 'Descriptions indicating potential risk.',
                                  examples: [
                                    'terrorism',
                                    'exchange_client',
                                    'binance_client_deposit',
                                    'binance_client_withdrawal',
                                    'binance_deposit_address',
                                    'Sanctioned OFAC',
                                    'sent_to_binance',
                                  ],
                                },
                                vasp_name_legal: {
                                  title: 'Vasp Name Legal',
                                  type: 'string',
                                  description: 'Legal name of VASP.',
                                  examples: 'Binance,Suex.Io',
                                },
                                vasp_name_business: {
                                  title: 'Vasp Name Business',
                                  type: 'string',
                                  description: 'Business name of VASP.',
                                  examples: 'binance,suex.io',
                                },
                                vasp_website: {
                                  title: 'Vasp Website',
                                  type: 'string',
                                  description: 'Website of VASP.',
                                  examples: 'https://www.binance.com,https://suex.io/',
                                },
                                vasp_category: {
                                  title: 'Vasp Category',
                                  type: 'string',
                                  description: 'Category of Virtual Asset Service Provider.',
                                  examples: 'exchange,exchange',
                                },
                                created: {
                                  title: 'Created',
                                  type: 'string',
                                  description: 'Request or create timestamp.',
                                  format: 'date-time',
                                  examples: '2024-01-02T21:46:03.773244+00:00',
                                },
                              },
                            },
                          },
                        },
                        created: {
                          title: 'Created',
                          type: 'string',
                          description: 'Request or create timestamp.',
                          format: 'date-time',
                          examples: '2024-01-12T04:56:42.896740',
                        },
                      },
                    },
                  },
                  message: {
                    title: 'Message',
                    type: 'string',
                    examples: 'Null',
                  },
                },
              },
            },
          },
        },
      },
      422: {
        description: 'Validation Error',
        content: {
          'application/json': {
            schema: {
              $ref: {
                title: 'HTTPValidationError',
                type: 'object',
                properties: {
                  errors: {
                    title: 'Errors',
                    type: 'array',
                    items: {
                      $ref: {
                        title: 'ValidationError',
                        required: ['loc', 'msg', 'type'],
                        type: 'object',
                        properties: {
                          loc: {
                            title: 'Location',
                            type: 'array',
                            items: {
                              anyOf: [
                                {
                                  type: 'string',
                                },
                                {
                                  type: 'integer',
                                },
                              ],
                            },
                          },
                          msg: {
                            title: 'Message',
                            type: 'string',
                          },
                          type: {
                            title: 'Error Type',
                            type: 'string',
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
  {
    endpoint: '/api/v2/analytics/chains/{chain}/addresses',
    method: 'get',
    tags: ['v2'],
    summary: 'List All Addresses',
    description: 'Retrieve the paginated list of address screening results based on the requesting business identity',
    operationId: 'List_all_addresses_api_v2_analytics_chains__chain__addresses_get',
    security: [
      {
        HTTPBearer: [],
      },
    ],
    parameters: [
      {
        name: 'chain',
        in: 'path',
        required: true,
        schema: {
          title: 'Chain',
          description: 'Blockchain network name.',
          type: 'string',
        },
        description: 'Blockchain network name.',
        examples: ['all', 'bitcoin'],
      },
      {
        name: 'start_range',
        in: 'query',
        required: false,
        schema: {
          title: 'Start Range',
          description: 'Starting point for specified data range.',
          type: 'string',
          format: 'date',
        },
        description: 'Starting point for specified data range.',
        examples: '2020-11-08',
      },
      {
        name: 'end_range',
        in: 'query',
        required: false,
        schema: {
          title: 'End Range',
          description: 'Ending point for specified data range.',
          type: 'string',
          format: 'date',
        },
        description: 'Ending point for specified data range.',
        examples: '2024-12-09',
      },
      {
        name: 'page',
        in: 'query',
        required: false,
        schema: {
          title: 'Page',
          default: 1,
          minimum: 1,
          type: 'integer',
        },
      },
      {
        name: 'size',
        in: 'query',
        required: false,
        schema: {
          title: 'Size',
          default: 10,
          minimum: 1,
          maximum: 50,
          type: 'integer',
        },
      },
      {
        name: 'sort',
        in: 'query',
        required: false,
        schema: {
          title: 'Sort',
          description: 'Criteria for result sorting.',
          type: 'string',
        },
        description: 'Criteria for result sorting.',
        examples: '-last_modified',
      },
    ],
    responses: {
      200: {
        description: 'Successful Response',
        content: {
          'application/json': {
            schema: {
              $ref: {
                title: 'ADD04ResponseCom',
                type: 'object',
                properties: {
                  status: {
                    title: 'Status',
                    type: 'string',
                    default: 'success',
                    examples: 'success',
                  },
                  data: {
                    $ref: {
                      title: 'AddressSummaryResponse',
                      required: ['items', 'pagination'],
                      type: 'object',
                      properties: {
                        items: {
                          title: 'Items',
                          type: 'array',
                          items: {
                            $ref: {
                              title: 'AddressInSummary',
                              required: ['id', 'address', 'chain', 'last_modified', 'created'],
                              type: 'object',
                              properties: {
                                id: {
                                  title: 'Id',
                                  type: 'string',
                                  description: 'Identification code for addresses.',
                                  format: 'uuid',
                                  examples: 'b7029c9d-1f8d-4bd0-81fc-ec1fa74c0caf',
                                },
                                address: {
                                  title: 'Address',
                                  type: 'string',
                                  description: 'Unique blockchain identifier.',
                                  examples: '0x88c6c46ebf353a52bdbab708c23d0c81daa8134a',
                                },
                                chain: {
                                  title: 'Chain',
                                  type: 'string',
                                  description: 'Blockchain network name.',
                                  examples: 'ethereum',
                                },
                                risk_ratio: {
                                  title: 'Risk Ratio',
                                  type: 'integer',
                                  description: 'Ratio of potential risk.',
                                  examples: 39,
                                },
                                balance_usd: {
                                  title: 'Balance Usd',
                                  type: 'number',
                                  description:
                                    'Virtual asset quantity held in USD by the address at the moment of the check.',
                                  examples: 41.241,
                                },
                                balance: {
                                  title: 'Balance',
                                  type: 'number',
                                  description: 'Virtual asset quantity held by the address at the moment of the check.',
                                  examples: 1.24830533458673,
                                },
                                symbol: {
                                  title: 'Symbol',
                                  type: 'string',
                                  description: 'Currency or token symbol.',
                                  examples: 'ETH',
                                },
                                last_modified: {
                                  title: 'Last Modified',
                                  type: 'string',
                                  description: 'Modification timestamp.',
                                  format: 'date-time',
                                  examples: '2023-12-27T10:14:47.278398+00:00',
                                },
                                created: {
                                  title: 'Created',
                                  type: 'string',
                                  description: 'Request or create timestamp.',
                                  format: 'date-time',
                                  examples: '2023-12-27T10:14:19.671922+00:00',
                                },
                              },
                            },
                          },
                          examples: [
                            {
                              id: '3c14c67e-6bef-49f6-9bae-337aa9ad60c6',
                              address: '15e15hWo6CShMgbAfo8c2Ykj4C6BLq6Not',
                              chain: 'bitcoin',
                              risk_ratio: 90,
                              balance_usd: 2.0332500096,
                              balance: 0.00004612,
                              symbol: 'BTC',
                              last_modified: '2024-02-07T22:32:31.503643+00:00',
                              created: '2023-03-03T08:17:40.945108+00:00',
                            },
                            {
                              id: 'c8970ff2-ea52-4e01-acdb-d9740a70196b',
                              address: '0x2eea44e40930b1984f42078e836c659a12301e40',
                              chain: 'ethereum',
                              risk_ratio: 39,
                              balance_usd: 13003.1376102025,
                              balance: 5.34292812627736,
                              symbol: 'ETH',
                              last_modified: '2024-02-07T21:16:09.293473+00:00',
                              created: '2024-02-07T21:16:09.293464+00:00',
                            },
                            {
                              id: '915ec633-b0f5-4432-9294-5d755ab0b704',
                              address: 'LQ9qb6CNtLaHkSS25egbZENFq8gjdvmFdC',
                              chain: 'litecoin',
                              risk_ratio: 50,
                              balance_usd: 0.6845,
                              balance: 0.01,
                              symbol: 'LTC',
                              last_modified: '2024-02-07T20:45:58.191371+00:00',
                              created: '2024-02-07T20:45:58.191371+00:00',
                            },
                            {
                              id: '41d9c7c4-e661-449c-84ff-325ea6b12ee1',
                              address: 'rsoLoDTcxn9wCEHHBR7enMhzQMThkB2w28',
                              chain: 'ripple',
                              risk_ratio: 50,
                              balance_usd: 163.2907064614,
                              balance: 319.114142,
                              symbol: 'XRP',
                              last_modified: '2024-02-07T20:24:14.295474+00:00',
                              created: '2023-03-05T12:23:03.194917+00:00',
                            },
                            {
                              id: 'c23ff5db-eab8-4c60-add6-e87d251a0dd4',
                              address: '2dmQ591AfyeZ4EN9RSPZXjyrxzsZY9uPYjunKr1WodfE',
                              chain: 'solana',
                              risk_ratio: 33,
                              balance_usd: 0,
                              balance: 0,
                              symbol: 'SOL',
                              last_modified: '2024-02-07T20:22:23.757462+00:00',
                              created: '2023-03-05T12:21:13.225110+00:00',
                            },
                            {
                              id: '4348b234-d69c-4f1c-a80e-b059b9bc4977',
                              address: '35PBEaofpUeH8VnnNSorM1QZsadrZoQp4N',
                              chain: 'bitcoin-cash',
                              risk_ratio: 90,
                              balance_usd: 0,
                              balance: 0,
                              symbol: 'BCH',
                              last_modified: '2024-02-07T20:16:40.490812+00:00',
                              created: '2023-03-27T05:11:23.040336+00:00',
                            },
                          ],
                        },
                        pagination: {
                          $ref: {
                            title: 'Pagination',
                            required: ['total', 'size', 'page'],
                            type: 'object',
                            properties: {
                              total: {
                                title: 'Total',
                                type: 'integer',
                                description: 'The total number of records.',
                                examples: 1442,
                              },
                              size: {
                                title: 'Size',
                                type: 'integer',
                                description: 'The size or number of records per page.',
                                examples: 6,
                              },
                              page: {
                                title: 'Page',
                                type: 'integer',
                                description: 'The current page number in pagination.',
                                examples: 1,
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                  message: {
                    title: 'Message',
                    type: 'string',
                    examples: 'Null',
                  },
                },
              },
            },
          },
        },
      },
      422: {
        description: 'Validation Error',
        content: {
          'application/json': {
            schema: {
              $ref: {
                title: 'HTTPValidationError',
                type: 'object',
                properties: {
                  errors: {
                    title: 'Errors',
                    type: 'array',
                    items: {
                      $ref: {
                        title: 'ValidationError',
                        required: ['loc', 'msg', 'type'],
                        type: 'object',
                        properties: {
                          loc: {
                            title: 'Location',
                            type: 'array',
                            items: {
                              anyOf: [
                                {
                                  type: 'string',
                                },
                                {
                                  type: 'integer',
                                },
                              ],
                            },
                          },
                          msg: {
                            title: 'Message',
                            type: 'string',
                          },
                          type: {
                            title: 'Error Type',
                            type: 'string',
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
]

import styled from 'styled-components'

export const SidebarWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;

  width: 100%;
  max-width: 250px;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.white};
  padding-bottom: 20px;
`
export const SidebarMenuNav = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  height: 100%;
  width: 100%;
  overflow-y: auto;
`

export const SidebarMenuNavList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;

  height: 100%;
  width: 100%;
  &:first-child {
    justify-content: flex-start;
  }
  &:last-child {
    padding: 0 0 20px 0;
  }
`

export const SidebarBrand = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  padding: 15px 0 15px 0;
  text-align: left;
`

export const SidebarBrandLogo = styled.div`
  padding: 6px 0 0 0;
  width: 70px;
  & svg > path {
    fill: ${({ theme }) => theme.colors.darkGray};
  }
`

export const SidebarMenuNavItem = styled.div`
  padding: 0px;
  align-items: center;
  width: 100%;
  transition: 0.1s;
`

export const SidebarMenuNavLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 5px;

  text-align: start;
  line-height: 1.2;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.darkGray};
  text-decoration: none;
  white-space: pre-wrap;

  padding: 10px 20px !important;
  transition: 0.3s;
  cursor: pointer;

  & > img {
    width: 25px;
    height: 25px;
  }
  & > svg {
    width: 20px;
    height: 20px;
    & path {
      stroke: ${({ theme }) => theme.colors.darkGray};
    }
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.ash};
    color: ${({ theme }) => theme.colors.darkGray};
  }

  &.active {
    font-weight: 400;
    border-left: 6px solid ${({ theme }) => `${theme.colors.blue}`};
    background-color: ${({ theme }) => theme.colors.ash};
  }
`

export const SidebarMenuNavText = styled.div``

export const SidebarMenuNavContainer = styled.div`
  padding-left: 15px;
  & * {
    font-size: 14px !important;
  }
`

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import ReactJson from 'react-json-view'

// Views
import { Select, CodeCard } from 'views/components'
import { DocumentationSubHeader } from 'views/layouts'

// Styled Elements
import { Wrapper, Container } from './ResponseCard.elements'

function ResponseCard(props) {
  // Destructure
  const { data } = props
  // States
  const [responses, setResponses] = useState()
  const [selectedResponse, setSelectedResponse] = useState()
  const [selectedResponseIndex, setSelectedResponseIndex] = useState(0)
  const [headers, setHeaders] = useState()
  const [activeHeader, setActiveHeader] = useState()

  // Functions
  function createObjectFromJSONStructure(jsonStructure) {
    if (!jsonStructure?.type && jsonStructure?.$ref) {
      return createObjectFromJSONStructure(jsonStructure?.$ref)
    }
    if (jsonStructure === undefined) {
      return
    }
    if (jsonStructure.type === 'object') {
      const result = {}
      if (jsonStructure?.examples) {
        return jsonStructure?.examples
      }
      Object.keys(jsonStructure.properties).map((key) => {
        const property = jsonStructure.properties[key]
        result[key] = createObjectFromJSONStructure(property)
      })
      return result
    }
    if (jsonStructure.type === 'array') {
      if (jsonStructure?.examples) return jsonStructure?.examples
      if (jsonStructure.items?.$ref) {
        return [createObjectFromJSONStructure(jsonStructure.items.$ref)]
      }
      return [createObjectFromJSONStructure(jsonStructure.items)]
    }
    if (jsonStructure?.examples) {
      return jsonStructure?.examples
    }
    switch (jsonStructure.type) {
      case 'string':
        if (jsonStructure?.examples === null) return null
        return 'string'
      case 'integer':
        return 0
      case 'number':
        return 0
      case 'boolean':
        return true
      default:
        return null
    }
  }
  function handleInitialLoad() {
    if (data?.length > 0) {
      const responsesLocal = {}
      const headers = []
      data.forEach((item, i) => {
        if (i === 0) setActiveHeader(item.code)
        headers.push({ name: item.code, action: () => setActiveHeader(item.code) })
        if (item?.content?.length === 1) {
          const sampleObject = createObjectFromJSONStructure(item?.content[0])
          if (sampleObject) {
            let title
            if (item?.content[0]?.$ref?.title) {
              title = item?.content[0]?.$ref?.title
            }
            if (item?.content[0]?.title) {
              title = item?.content[0]?.title
            }
            responsesLocal[item.code] = [{ title, data: sampleObject }]
          }
        } else if (item?.content?.length > 1) {
          const final = []
          item?.content.map((itemData) => {
            let title
            if (itemData?.$ref?.title) {
              title = itemData?.$ref?.title
            }
            if (itemData?.title) {
              title = itemData?.title
            }
            const sampleObject = createObjectFromJSONStructure(itemData)
            final.push({ title, data: sampleObject })
          })
          responsesLocal[item.code] = final
        }
      })
      setHeaders(headers)
      setResponses(responsesLocal)
      // console.log(responsesLocal)
    }
  }

  // UseEffects
  useEffect(() => handleInitialLoad(), [])
  useEffect(() => {
    if (!activeHeader) return
    if (responses[activeHeader]) {
      setSelectedResponse(responses[activeHeader])
    }
  }, [activeHeader])

  // TODO SHOW OTHER POSSIBLE RESPONSES OF STATUS CODE 200
  return (
    <Wrapper>
      <DocumentationSubHeader headers={headers} activeHeader={activeHeader} />
      <Container>
        {selectedResponse && selectedResponse[selectedResponseIndex]?.data && (
          <>
            {selectedResponse?.length > 1 && (
              <Select
                onChange={setSelectedResponseIndex}
                options={selectedResponse.map((item, i) => ({ value: i, label: item?.title }))}
              />
            )}
            <ReactJson
              displayDataTypes={false}
              displayObjectSize={false}
              style={{ background: 'transparent', fontSize: 12, wordBreak: 'break-word' }}
              theme="monokai"
              name={false}
              indentWidth={2}
              src={selectedResponse[selectedResponseIndex].data}
            />
          </>
        )}
      </Container>
    </Wrapper>
  )
}

// Default Props
ResponseCard.defaultProps = {
  data: '',
}

// Proptypes Validation
ResponseCard.propTypes = {
  data: PropTypes.arrayOf(),
}

export default ResponseCard

import React, { Children } from 'react'
import PropTypes from 'prop-types'

// Styled Elements
import {
  Wrapper,
  BodyOverflowWrapper,
  BodyChildrenWrapper,
  HeaderOptionsWrapper,
  HeaderFilterButton,
  BodyWrapper,
} from './AccordionTable.elements'

function AccordionTable(props) {
  // Destructure
  const { headerOptions, children, minWidth, filterComponents } = props

  // Variables

  // Functions

  return (
    <Wrapper allowZeroExpanded>
      <HeaderFilterButton>
        {headerOptions && <HeaderOptionsWrapper>{headerOptions}</HeaderOptionsWrapper>}
      </HeaderFilterButton>

      {filterComponents && filterComponents}

      <BodyOverflowWrapper>
        <BodyWrapper minWidth={minWidth}>
          <BodyChildrenWrapper length={Children.count(children)}>{children}</BodyChildrenWrapper>
        </BodyWrapper>
      </BodyOverflowWrapper>
    </Wrapper>
  )
}

// Default Props
AccordionTable.defaultProps = {
  headerOptions: '',
  children: '',
  minWidth: 0,
  filterComponents: '',
  tableFilters: {},
}

// Proptypes Validation
AccordionTable.propTypes = {
  headerOptions: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  minWidth: PropTypes.number,
  filterComponents: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  tableFilters: PropTypes.shape({
    sortBy: PropTypes.string,
    sortDirection: PropTypes.string,
    page: PropTypes.number,
    size: PropTypes.number,
  }),
}

export default AccordionTable

import React from 'react'
import PropTypes from 'prop-types'
import ReactJson from 'react-json-view'

function CodeCard(props) {
  return (
    <ReactJson
      displayDataTypes={false}
      displayObjectSize={false}
      style={{ background: 'transparent', fontSize: 12, wordBreak: 'break-word' }}
      theme="monokai"
      name={false}
      indentWidth={2}
      {...props}
    />
  )
}

// Default Props
CodeCard.defaultProps = {
  data: '',
}

// Proptypes Validation
CodeCard.propTypes = {
  data: PropTypes.arrayOf(),
}

export default CodeCard

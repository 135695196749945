/* eslint-disable react/no-unstable-nested-components */
import React from 'react'

// Styled Elements
import { TableButtonWrapper, TableRowWrapper, TableRowContainer, TableText } from './ReqbodyTableHeader.elements'

function ReqbodyTableHeader() {
  return (
    <TableButtonWrapper>
      <TableRowWrapper>
        <TableRowContainer style={{ minWidth: '180px' }}>
          <TableText>Name</TableText>
        </TableRowContainer>
        <TableRowContainer>
          <TableText>Required</TableText>
        </TableRowContainer>
      </TableRowWrapper>

      <TableRowWrapper>
        <TableRowContainer>
          <TableText>Type</TableText>
        </TableRowContainer>
      </TableRowWrapper>
    </TableButtonWrapper>
  )
}

// Default Props
ReqbodyTableHeader.defaultProps = {}

// Proptypes Validation
ReqbodyTableHeader.propTypes = {}
export default ReqbodyTableHeader
